import { MenuItem, Select, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { retailMenuDrinkItems } from 'lib/constants';
import { useMemo } from 'react';
import { PickUpStatus } from '../../types';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between'
  }
}));

interface OrderTableRowProps {
  order: any;
  loadingItemIds: string[];
  isFetching: boolean;
  isLoading: boolean;
  onChangeStatus: (pickUpId: string, status: PickUpStatus) => void;
}

const formatDate = format('dd MMM yyyy');
const formatTime = format('hh:mm a');
const getDateFromString = (dateString: string) => new Date(dateString);

const formatDateTime = (dateString: string) => formatDate(getDateFromString(dateString));
const formatTimeOnly = (dateString: string) => formatTime(getDateFromString(dateString));

const activeSelectStyle = {
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: caloTheme.palette.primary500
  },
  '& .MuiSelect-icon': {
    color: caloTheme.palette.primary500
  }
};

const OrderTableRow = ({ order, loadingItemIds, isFetching, isLoading, onChangeStatus }: OrderTableRowProps) => {
  const isDisabled = useMemo(() => {
    return isFetching || isLoading || order.pickUp.status === PickUpStatus.PICKED_UP;
  }, [isFetching, isLoading, order.pickUp.status]);

  return (
    <TableRow
      sx={{
        opacity: loadingItemIds.includes(order.pickUp.id) ? 0.5 : 1,
        pointerEvents: loadingItemIds.includes(order.pickUp.id) ? 'none' : 'auto',
        borderBottom: '1px solid var(--divider, rgba(0, 0, 0, 0.12))'
      }}
    >
      <StyledTableCell>{order.orderId}</StyledTableCell>
      <StyledTableCell>
        {order.user.name}
        <br />
        {order.user.phoneNumber}
      </StyledTableCell>
      <StyledTableCell>
        {formatDateTime(order.createdAt)}
        <br />
        {formatTimeOnly(order.createdAt)}
      </StyledTableCell>
      <StyledTableCell>{formatTimeOnly(order.pickUp.pickUpAt)}</StyledTableCell>
      <StyledTableCell>
        <Select
          value={order.pickUp.status}
          onChange={(e) => onChangeStatus(order.pickUp.id, e.target.value as PickUpStatus)}
          disabled={isDisabled}
          variant="outlined"
          sx={{
            color: caloTheme.palette.primary500,
            fontSize: '0.875rem',
            borderRadius: '100px',
            height: '35px',
            fontWeight: 500,
            ...(!isDisabled && activeSelectStyle)
          }}
        >
          {Object.values(PickUpStatus).map((status) => (
            <MenuItem
              key={status}
              value={status}
              disabled={order.pickUp.status === PickUpStatus.CREATED && status === PickUpStatus.PICKED_UP}
            >
              {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </StyledTableCell>
      <StyledTableCell>
        {order.items.map((item: any) => {
          const isDrinkCategory = item.category ? retailMenuDrinkItems[item.category] : true;
          const itemSize = isDrinkCategory && item.size ? `(${item.size})` : '';
          return (
            <div key={item.id}>
              <div>
                <strong style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{item.quantity} x</strong> {String(item.name.en)}{' '}
                {itemSize}
              </div>
              {item.notes && (
                <div>
                  <div>{String(item.name.en)}:</div>
                  {item.notes.split('\n').map((note: string, idx: number) => (
                    <div key={idx}>{note}</div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </StyledTableCell>
      <StyledTableCell>{order.notes}</StyledTableCell>
      <StyledTableCell>{order.items.reduce((total: number, item: any) => total + item.quantity, 0)}</StyledTableCell>
    </TableRow>
  );
};

export default OrderTableRow;
