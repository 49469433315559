import { getRecord } from 'actions/index';
import { useCustomQuery, UseCustomQueryOptions } from './base';
import { QueryFunctionContext } from 'react-query';

export const useGetSubscriptionData = (subscriptionId: string, options?: UseCustomQueryOptions<any, Error, any>) => {
  return useCustomQuery(
    ['subscriptions', subscriptionId],
    (context: QueryFunctionContext) => getRecord(context) as Promise<any>,
    {
      enabled: !!subscriptionId,
      ...options
    }
  );
};
