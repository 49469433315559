import { FoodType } from '@calo/types';
import { INITIAL_DELIVERY_FOOD_STATE } from 'lib/constants';
import { Delivery, MealPlanFoodState } from 'lib/interfaces';

export const categorizeFoodIntoMealPlans = (selDel: Delivery) => {
  // this is done for deep copy of initial state object
  const initialFoodState = JSON.parse(JSON.stringify(INITIAL_DELIVERY_FOOD_STATE));

  const giftedMeals =
    selDel.giftedItems && selDel.giftedItems.meal ? selDel.giftedItems.meal.map((meal) => ({ ...meal, isGiftedItem: true })) : [];

  const combinedMeals = [...(selDel.addons ?? []), ...(selDel.food ?? [])];
  const mealPlans = combinedMeals?.reduce((acc: MealPlanFoodState, meal, index) => {
    if (meal.type)
      for (const type of meal.type) {
        if (type === FoodType.dinner) {
          continue;
        } else if (type === FoodType.lunch) {
          acc.lunch.push({ ...meal, positionIndex: index });
        } else {
          const category = FoodType[type];
          if (category && typeof category === 'string') {
            acc[category] = acc[category] || [];
            acc[category].push({ ...meal, positionIndex: index });
          }
        }
      }
    return acc;
  }, initialFoodState);
  for (const [index, meal] of giftedMeals.entries()) {
    mealPlans.gifts.push({ ...meal, isGiftedItem: meal.isGiftedItem, positionIndex: index } as any);
  }

  return mealPlans;
};
