import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  AutoCompleteCard: {
    paddingX: 1,
    paddingY: 2,
    paddingBottom: '12px',
    borderRadius: '8px',
    width: '100%',
    overflow: 'visible'
  },
  chooseCustomerText: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '-0.38px'
  },
  customerPlanCard: {
    padding: 1,
    borderRadius: '8px',
    width: '100%'
  },
  cards: {
    padding: 2,
    borderRadius: '8px',
    width: '100%',
    marginTop: '12px'
  },
  customerPlanText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '-0.38px'
  },
  planTypeText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.38px',
    marginTop: '8px',
    textTransform: 'capitalize'
  },
  noUserText: {
    marginTop: '16px',
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: '40px',
    color: caloTheme.palette.neutral500,
    fontFamily: 'Roboto',
    textAlign: 'center'
  }
};

export default styles;
