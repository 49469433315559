import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '-0.38px'
  },
  noSubscriptionText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    marginY: 1,
    color: caloTheme.palette.red500
  },
  subInfoText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    marginY: 1
  },
  typeText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    color: caloTheme.palette.neutral900
  },
  valueText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '8px',
    textTransform: 'capitalize'
  },
  deliveryDaysStack: {
    border: 0,
    borderColor: caloTheme.palette.neutral100,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  deliveryDaysBox: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: '37px',
    textAlign: 'center',
    background: caloTheme.palette.primary100,
    mr: '8px',
    my: '4px',
    color: caloTheme.palette.primary700,
    width: '52px',
    height: '33px'
  },
  grid: { display: 'flex', flexDirection: 'row', m: 'auto', mt: '8px' },
  gridText: {
    display: 'flex',
    flexDirection: 'row',
    m: 'auto',
    color: caloTheme.palette.primary700,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 600
  }
};

export default styles;
