import 'react-datepicker/dist/react-datepicker.css';

import { useState } from 'react';

import { createDeliveryTicket, getListWithParams, quickSearch } from 'actions';
import cx from 'classnames';
import { Autocomplete, Button, CaloLoader, Icon, Select } from 'components';
import { addDays } from 'date-fns';
import { format, parseISO } from 'date-fns/fp';
import { customStyleDatePicker, customStyleKitchen, customStyles2, logisticChangeSelectStyles } from 'lib/componentStyles';
import { getAddressForDeliveryTicket } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { DeliveryTicketData, PaginatedDeliveries } from 'lib/interfaces';
import { groupBy, map, orderBy } from 'lodash';
import DatePicker from 'react-datepicker';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AddressService } from 'services';

import { DeliveryTicketType, Permission } from '@calo/dashboard-types';
import { Brand, DeliveryAddress, Kitchen } from '@calo/types';

import { DeliveryTime } from 'lib/enums';
import AddressChangeRequest from '../AddressChangeRequest';

const AddressChangePage = () => {
  const today = new Date();
  const roles = useUserRoles();
  const userKitchens: string[] = useUserKitchens();
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedShift, setSelectedShift] = useState<DeliveryTime>();
  const [deliveryExist, setDeliveryExist] = useState<boolean>(false);
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen>(Kitchen.BH1);
  const [selectedDateReq, setSelectedDateReq] = useState<string>(format('yyyy-MM-dd')(today));

  const [selectedDelivery, setSelectedDelivery] = useState<any>();
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<DeliveryAddress>();
  const [addressSelected, setAddressSelected] = useState<DeliveryAddress | undefined>();

  const { mutateAsync: createDeliveryTicketMutation } = useMutation(createDeliveryTicket);

  const {
    data: sentRequestData,
    refetch: refetchSentReq,
    isLoading: loadingSentReq
  } = useQuery<any, Error, any[]>(
    [
      'delivery-tickets',
      {
        filters: {
          type: DeliveryTicketType.ADDRESS,
          kitchen: selectedKitchen,
          date: { lte: selectedDateReq, gte: selectedDateReq }
        }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const {
    data: sentCancelRequestData,
    refetch: refetchSentCancelReq,
    isLoading: loadingSentCancelReq
  } = useQuery<any, Error, any[]>(
    [
      'delivery-tickets',
      {
        filters: {
          type: DeliveryTicketType.CANCEL,
          kitchen: selectedKitchen,
          date: { lte: selectedDateReq, gte: selectedDateReq }
        }
      }
    ],
    getListWithParams,
    {
      suspense: false
    }
  );

  const allSentReq = orderBy(sentCancelRequestData?.concat(sentRequestData), 'createdAt', 'desc');

  const { isLoading } = useQuery<any, Error, PaginatedDeliveries>(
    [
      'deliveries',
      {
        page: 0,
        filters: {
          driverId: '',
          day: {
            lte: selectedDate,
            gte: selectedDate
          },
          status: ['paymentRequired', 'upcoming'],
          skipped: false,
          brand: selectedCustomer?.data.brand,
          country: selectedCustomer?.data.country,
          phoneNumber: selectedCustomer?.phoneNumber
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      enabled: !!selectedCustomer && !!selectedDate,
      onSuccess: (data) => {
        const index = data.data.findIndex((delivery) => delivery.day === selectedDate);
        if (index >= 0) {
          setDeliveryExist(true);
          setSelectedDelivery(data.data[index]);
          setAddressSelected(data.data[index].deliveryAddress);
          setSelectedShift(data.data[index].time);
          setSelectedDeliveryAddress(data.data[index].deliveryAddress);
        } else {
          setDeliveryExist(false);
          setSelectedDelivery(undefined);
          setSelectedDeliveryAddress(undefined);
          toast(`No delivery for the selected customer on ${selectedDate}`, { type: 'error', autoClose: 2000 });
        }
      }
    }
  );

  const handleAddressReqChange = async () => {
    if (selectedDate !== selectedDelivery.day) {
      toast.error('Delivery date does not match the selected date, please refresh the page and try again.');
      return;
    }

    if (!addressSelected || !selectedDeliveryAddress) {
      toast.error('No address selected');
      return;
    }

    const reqData: DeliveryTicketData = {
      userName: selectedCustomer.name,
      type: DeliveryTicketType.ADDRESS,
      deliveryId: selectedDelivery.id,
      addressTicketData: {
        newDeliveryTime: selectedDelivery.time === selectedShift! ? undefined : selectedShift,
        oldAddress: getAddressForDeliveryTicket(selectedDeliveryAddress) as DeliveryAddress,
        newAddress:
          selectedDeliveryAddress === addressSelected
            ? undefined
            : (getAddressForDeliveryTicket(addressSelected) as DeliveryAddress)
      },
      deliveryTime: selectedDelivery.time!,
      userId: selectedCustomer.value,
      phoneNumber: selectedCustomer.phoneNumber
    };
    await createDeliveryTicketMutation(
      {
        date: selectedDate,
        data: reqData,
        brand: selectedCustomer.data.brand || Brand.CALO,
        country: selectedCustomer.data.country,
        kitchen: selectedCustomer.data.kitchen || Kitchen.BH1
      },
      {
        onSuccess: () => {
          refetchSentReq();
          refetchSentCancelReq();
          setSelectedDate('');
          setSelectedCustomer(undefined);
          toast('Request sent successfully', { type: 'success', autoClose: 2000 });
        },
        onError(error: any) {
          let errorMessage = 'Request failed with status code 500';
          if (error.response?.data?.Payload) {
            try {
              const payload = JSON.parse(error.response?.data.Payload);
              if (payload.errorMessage) {
                errorMessage = payload.errorMessage;
              }
            } catch (parseError) {
              console.error('Error parsing error response:', parseError);
            }
          }
          toast.error(errorMessage);
        }
      }
    );
  };

  const handleAddressCancelReq = async () => {
    if (selectedDate !== selectedDelivery.day) {
      toast.error('Delivery date does not match the selected date, please refresh the page and try again.');
      return;
    }

    if (!selectedDeliveryAddress) {
      toast.error('No address selected');
      return;
    }

    const reqData: DeliveryTicketData = {
      type: DeliveryTicketType.CANCEL,
      deliveryId: selectedDelivery.id,
      deliveryTime: selectedDelivery.time!,
      userName: selectedCustomer.name,
      addressTicketData: {
        oldAddress: getAddressForDeliveryTicket(selectedDeliveryAddress) as DeliveryAddress
      },
      userId: selectedCustomer.value,
      phoneNumber: selectedCustomer.phoneNumber
    };
    await createDeliveryTicketMutation(
      {
        date: selectedDate,
        data: reqData,
        brand: selectedCustomer.data.brand || Brand.CALO,
        country: selectedCustomer.data.country,
        kitchen: reqData.addressTicketData?.oldAddress?.kitchen || selectedCustomer.data.kitchen || Kitchen.BH1
      },
      {
        onSuccess: () => {
          refetchSentReq();
          refetchSentCancelReq();
          setSelectedDate('');
          setSelectedCustomer(undefined);
          toast('Request sent successfully', { type: 'success', autoClose: 2000 });
        },
        onError(error: any) {
          toast.error(error);
        }
      }
    );
  };

  return (
    <>
      <section className="section is-title-bar -my-8 ">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="uppercase -mb-4 font-roboto" style={{ fontSize: '33px' }}>
                Logistics Changes
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section w-full flex flex-row -mx-8">
        <div className="w-9/12">
          <div className="flex flex-row justify-between">
            <div className="w-8/12 mr-4">
              {roles.includes(Permission.VIEW_SEARCH_BAR) && (
                <>
                  <p className="uppercase font-roboto text-lg font-bold">Customer</p>
                  <Autocomplete
                    onSearch={(text) =>
                      quickSearch({
                        limit: 10,
                        query: text,
                        page: 1
                      })
                    }
                    transformer={(data: any[]) =>
                      map(groupBy(data, 'type'), (group, key) => ({
                        label: key,
                        options: group.map(
                          (row: any) =>
                            row.type === 'subscription' && {
                              value: row.id,
                              type: row.type,
                              data: row,
                              phoneNumber: row.phoneNumber.replace('+', ''),
                              name: row.name,
                              label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}`
                            }
                        )
                      }))
                    }
                    customStyles={customStyles2}
                    placeHolder={true}
                    placeHolderValue={selectedCustomer ? selectedCustomer.label : 'Search'}
                    onPick={(data) =>
                      data === undefined ? setSelectedCustomer(undefined) : (setSelectedCustomer(data), setDeliveryExist(true))
                    }
                  />
                </>
              )}
            </div>
            <div className="w-1/2">
              <p className="uppercase font-roboto text-lg font-bold">Date</p>
              <Select
                value={selectedDate}
                onChange={(data: any) => setSelectedDate(data.value)}
                customStyles={customStyleDatePicker}
                options={[
                  {
                    value: format('yyyy-MM-dd')(today),
                    label: format('yyyy-MM-dd')(today)
                  },
                  {
                    value: format('yyyy-MM-dd')(addDays(today, 1)),
                    label: format('yyyy-MM-dd')(addDays(today, 1))
                  }
                ]}
              />
            </div>
          </div>
          {isLoading ? (
            <div>
              <CaloLoader />
            </div>
          ) : !deliveryExist && selectedCustomer && selectedDate ? (
            <div className="flex">
              <span className="m-auto section">
                <p className="uppercase flex w-full text-3xl mt-16 justify-center font-bold text-gray-400">{`No Delivery for ${selectedCustomer?.name} on ${selectedDate}`}</p>
              </span>
            </div>
          ) : !selectedCustomer || !selectedDate ? (
            <div>
              <label className="uppercase flex w-full text-3xl mt-32 justify-center font-bold text-gray-400">
                {' '}
                Select User & Date
              </label>
            </div>
          ) : (
            <div className="section -mx-12">
              <div className="level mt-4">
                <div className="level-left">
                  <div className="level-item">
                    <p className="uppercase font-roboto text-3xl">Addresses</p>
                  </div>
                </div>
                <div className="level-right">
                  <span>
                    <Select
                      className="w-32"
                      customStyles={logisticChangeSelectStyles}
                      value={selectedShift}
                      onChange={(data: any) => setSelectedShift(data.value)}
                      options={[
                        { value: DeliveryTime.earlyMorning, label: 'Early Morning' },
                        { value: DeliveryTime.morning, label: 'Morning' },
                        { value: DeliveryTime.evening, label: 'Evening' }
                      ]}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex flex-column w-full">
                {selectedDelivery &&
                  (selectedDelivery.deliveryAddresses || [])
                    .filter((address: any) => !address.deletedAt)
                    .map((deliveryAddress: any) => (
                      <span
                        key={deliveryAddress.id}
                        className={cx('flex flex-row min-h-16 max-h-auto w-full justify-between border-2 mb-2 p-2 h-16 rounded', {
                          'border-cGreen': addressSelected?.id === deliveryAddress.id
                        })}
                        style={{ backgroundColor: addressSelected?.id === deliveryAddress.id ? '#F7FBF9' : '#F7F7F7' }}
                      >
                        <span>
                          <p>{AddressService.display(deliveryAddress as DeliveryAddress)} </p>
                          {deliveryAddress.notes && <p style={{ fontSize: '14px' }}> Notes: {deliveryAddress?.notes} </p>}
                        </span>
                        {addressSelected?.id !== deliveryAddress.id && (
                          <Button
                            onClick={() => setAddressSelected(deliveryAddress as any)}
                            icon="fas fa-check"
                            className="my-auto border-cGreen text-cGreen focus:text-cGreen rounded focus:border-cGreen hover:text-cGreen hover:border-cGreen"
                          />
                        )}
                      </span>
                    ))}
              </div>
              {selectedCustomer && selectedDate && deliveryExist && (
                <section className="section">
                  <div className="flex flex-row justify-center">
                    <span className="mr-4">
                      <Button
                        primary
                        content="Request Change"
                        disabled={
                          selectedDelivery.time === selectedShift && selectedDelivery.deliveryAddress.id === addressSelected?.id
                        }
                        onClick={() => handleAddressReqChange()}
                      />
                    </span>
                    <span className="mr-4">
                      <Button primary outlined content="Cancel Delivery" onClick={() => handleAddressCancelReq()} />
                    </span>
                    {/* <span>
                      <Button
                        outlined
                        primary
                        content='Add New Delivery Location'
                        onClick={() => addressModalRef.current?.open()}
                      />
                    </span> */}
                  </div>
                </section>
              )}
            </div>
          )}
        </div>

        <div className="w-4/12 ml-4">
          <div className="flex flex-column mx-auto max-h-full">
            <span className="flex flex-row w-full justify-between">
              <p className="font-roboto text-xl font-bold">Sent Request</p>
            </span>

            <div className="w-full">
              <div className="level" style={{ backgroundColor: '#F7F7F7' }}>
                <div className="level-left">
                  <div className="level-item">
                    <Select
                      customStyles={customStyleKitchen}
                      value={selectedKitchen}
                      onChange={(data: any) => setSelectedKitchen(data.value)}
                      options={userKitchens.map((kitchen) => ({
                        label: kitchen,
                        value: kitchen
                      }))}
                    />
                  </div>
                </div>
                <div className="level-left">
                  <div className="level-item">
                    <DatePicker
                      selected={parseISO(selectedDateReq)}
                      onChange={(date: any) => setSelectedDateReq(format('yyyy-MM-dd')(date))}
                      customInput={
                        <span className="outline-none border-2 border-cGreen rounded-md text-cGreen mr-1 flex w-32 justify-between">
                          <label className="w-6/12 mx-2">{format('dd/MM/yyyy')(parseISO(selectedDateReq))}</label>
                          <Icon name="calenderIcon" size={7} className="px-1 ml-2" />
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="h-full overflow-y-auto w-full" style={{ minHeight: '600px', maxHeight: '600px' }}>
                {loadingSentReq || loadingSentCancelReq ? (
                  <div>
                    {' '}
                    <CaloLoader />{' '}
                  </div>
                ) : !allSentReq || allSentReq.length === 0 ? (
                  <label className="overflow-y-hidden uppercase flex w-full text-3xl justify-center font-bold text-gray-400 mt-8">
                    No Data
                  </label>
                ) : (
                  allSentReq?.map((req) => <AddressChangeRequest req={req} />)
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddressChangePage;
