import { PricingService } from '@calo/services';
import { Currency, PlanType, SubscriptionTier } from '@calo/types';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { caloTheme } from 'assets/images/theme/calo';
import { Checkbox } from 'components';
import { pick, startCase } from 'lodash-es';
import { useEffect, useState } from 'react';

interface Breakdown {
  plan?: any;
  numberOfDays?: number;
  selectedMeals?: { [k: string]: number };
  subscriptionTier: SubscriptionTier | null;
  setSubscriptionTier: (value: SubscriptionTier) => void;
}

export default ({ plan, selectedMeals, numberOfDays, subscriptionTier, setSubscriptionTier }: Breakdown) => {
  if (!plan) {
    return <>Loading...</>;
  }

  const [total, setTotal] = useState(0);
  const [discountType, setDiscountType] = useState('fixed');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [pricePerDay, setPricePerDay] = useState(0);

  const foodPrices = pick(plan.foodTypePrices, plan.foodTypes);

  let vat = 0;
  switch (plan.currency) {
    case Currency.BHD:
      vat = 0.1;
      break;
    case Currency.SAR:
      vat = 0.15;
      break;
    case Currency.AED:
      vat = 0.05;
      break;
    case Currency.QAR:
    case Currency.KWD:
      vat = 0;
      break;
    default:
      vat = 0;
      break;
  }

  const calculatePricePerDay = () => {
    const pricePerDay = plan.pricePerDay || 0;
    setPricePerDay(pricePerDay);
    return pricePerDay;
  };

  const calculateTotalPrice = () => {
    let finalTotal = calculatePricePerDay() * (numberOfDays ?? 0);
    if (discountAmount > 0) {
      finalTotal = finalTotal - (discountType === 'fixed' ? discountAmount : finalTotal * (discountAmount / 100));
    }

    finalTotal = finalTotal * (vat + 1);
    finalTotal = PricingService.roundAmount(finalTotal, plan.currency);

    return finalTotal;
  };

  const handleDiscountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountType(event.target.value);
  };

  const toggleSubscriptionTier = () => {
    setSubscriptionTier(
      subscriptionTier === SubscriptionTier.pricingCaloClubOct2024
        ? SubscriptionTier.personalized
        : SubscriptionTier.pricingCaloClubOct2024
    );
  };

  useEffect(() => {
    setTotal(calculateTotalPrice());
  }, [discountType, discountAmount, selectedMeals, numberOfDays]);

  useEffect(() => {
    setDiscountAmount(0);
    setTotal(calculateTotalPrice());
  }, [plan]);

  return (
    <Box
      sx={{
        padding: 2,
        marginBottom: 1,
        borderRadius: 3,
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'row'
        },
        backgroundColor: caloTheme.palette.grey[100]
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography
          variant="h3"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          {startCase(plan.planId)}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'left',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          🍔 {plan.foodTypes.map((f) => startCase(f)).join(', ')}
        </Typography>
      </Stack>
      <Divider variant="fullWidth" sx={{ marginBottom: 1, marginTop: 1 }} />
      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
          fontFamily: caloTheme.typography.fontFamily
        }}
      >
        <ul>
          {plan.planId === PlanType.custom
            ? Object.entries(foodPrices).map(([fdt, price]) => (
                <li>
                  {startCase(fdt)} {PricingService.roundAmount(price, plan.currency)} {plan.currency}{' '}
                  <b>{`x${selectedMeals?.[fdt] || 0}`}</b>
                </li>
              ))
            : Object.entries(foodPrices).map(([fdt, price]) => (
                <li>
                  {startCase(fdt)} {PricingService.roundAmount(price, plan.currency)} {plan.currency}
                </li>
              ))}
        </ul>
      </Typography>
      <Divider variant="fullWidth" sx={{ marginBottom: 1, marginTop: 1 }} />
      <Grid2 container spacing={0} sx={{ padding: 0 }}>
        <Grid2 xs={7}>
          <Stack direction="column" spacing={1}>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'left',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {PricingService.roundAmount(pricePerDay, plan.currency)}{' '}
              <b>
                {plan.currency}/day <sup>(VAT EXCL.)</sup>
              </b>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'left',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {PricingService.getGrossAmount(pricePerDay, plan.currency)}{' '}
              <b>
                {plan.currency}/day <sup>(VAT INCL.)</sup>
              </b>
            </Typography>
            {discountAmount > 0 && (
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'left',
                  fontFamily: caloTheme.typography.fontFamily,
                  color: caloTheme.palette.error.main
                }}
              >
                Discount: - {discountAmount} {discountType === 'percentage' && '%'} {plan.currency}
              </Typography>
            )}
            <Typography
              variant="h5"
              sx={{
                textAlign: 'left',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                color: caloTheme.palette.primary.main
              }}
            >
              Total <sup>excl. </sup> {PricingService.getNetAmount(total, plan.currency)} <small>{plan.currency}</small>
            </Typography>
            <Typography
              variant="h5"
              sx={{
                textAlign: 'left',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                color: caloTheme.palette.primary.main
              }}
            >
              Total <sup>incl. </sup> {total} <small>{plan.currency}</small>
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 xs={5}>
          <FormControl>
            <FormLabel>Discount</FormLabel>

            <span className="flex flex-row mt-2">
              <Checkbox
                className="mx-1 mt-1.5"
                customStyles={{ accentColor: 'green', transform: 'scale(1.5)' }}
                checked={subscriptionTier === SubscriptionTier.pricingCaloClubOct2024}
                onChange={toggleSubscriptionTier}
              />
              <label className="ml-3"> Calo Club</label>
            </span>

            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
              <FormControlLabel
                value="percentage"
                control={<Radio checked={discountType === 'percentage'} onChange={handleDiscountTypeChange} />}
                label="Percentage"
              />
              <FormControlLabel
                value="fixed"
                control={<Radio checked={discountType === 'fixed'} onChange={handleDiscountTypeChange} />}
                label="Fixed"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Amount"
            variant="outlined"
            defaultValue={0}
            type="number"
            value={discountAmount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDiscountAmount(event.target.value as unknown as number);
            }}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
