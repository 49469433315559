import { PermissionService } from '@calo/services';
import { CustomMealCategory, Food, FoodActionType, FoodComponentType, FoodDietType, FoodType, Localized } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, IconButton, MenuItem, Select, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { customizeMeal, replaceFood, updateCustomFood } from 'actions';
import customMealImage from 'assets/images/customMealImage.png';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon, IOSSwitch, MealTotalInformationSection, ModalRef, ModifyMealPopup } from 'components';
import CustomFoodPopup from 'components/CustomFoodPopup';
import Popup from 'components/Popup';
import { checkIsRemovedByDefault, renderIngredients } from 'lib/helpers';
import { CustomComponentsByCategory, Delivery, FoodComponent, FoodWithPosition, Menu, MenuFood } from 'lib/interfaces';
import { compact, flatten, groupBy, startCase, uniqBy } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import ReplaceMealPopup from 'views/Nutrition/DeliveriesCard/DeliveryMealCard/ReplaceMealPopup';
import styles from './styles';

interface DeliveryMealCardProps {
  menu: Menu;
  foodPref: any;
  foodPrefRef: any;
  isFavorite: boolean;
  menuLoading: boolean;
  meal: FoodWithPosition;
  selectedDelivery: Delivery;
  isCustomCompsLoading: boolean;
  allFoodComponentsData: any[];
  caloKidsComponents: FoodComponent[];
  customFoodComponents: FoodComponent[];
  allCustomFoodComponents: CustomComponentsByCategory;
  handleUpdateSubscriptionFavoriteMeal: (data: string) => Promise<void>;
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  setAllCustomFoodComponent: React.Dispatch<React.SetStateAction<CustomComponentsByCategory>>;
  refetch: () => void;
}
const DeliveryMealCard = ({
  refetch,
  foodPref,
  foodPrefRef,
  menuLoading,
  selectedDelivery,
  allFoodComponentsData,
  handleUpdateSubscriptionFavoriteMeal,
  meal,
  menu,
  allCustomFoodComponents,
  setCustomFoodComponents,
  caloKidsComponents,
  isFavorite,
  customFoodComponents,
  isCustomCompsLoading
}: DeliveryMealCardProps) => {
  const enlargeRef = useRef<ModalRef>();
  const mealSwapRef = useRef<ModalRef>();
  const editMealRef = useRef<ModalRef>();
  const editCustomMeal = useRef<ModalRef>();
  const [selectedMeal, setSelectedMeal] = useState<Food>();
  const [customFood, setCustomFood] = useState<any | null>(null);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const { mutateAsync: customizeMealMutation } = useMutation(customizeMeal);
  const { mutateAsync: updateCustomFoodMutation } = useMutation(updateCustomFood);
  const [customizeMealLoading, setCustomizeMealLoading] = useState<boolean>(false);
  const [selectedMealToRemoveComponents, setSelectedMealToRemoveComponents] = useState<Food>();
  const [selectedComponent, setSelectedComponent] = useState<FoodComponent | undefined>(undefined);
  const [selectedRemovedComponentIdList, setSelectedRemovedComponentIdList] = useState<string[]>([]);
  const [selectedSwappedComponent, setSelectedSwappedComponent] = useState<{ oldId: string; newId: string }[]>([]);
  const [applyAlwaysForMeal, setApplyAlwaysForMeal] = useState<boolean>(false);
  const [previousApplyAlwaysForMeal, setPreviousApplyAlwaysForMeal] = useState<boolean>(applyAlwaysForMeal);

  const [selectedSize, setSelectedSize] = useState<any>({
    size: meal?.size,
    foodId: meal?.id,
    foodName: meal?.name?.en
  });
  const { mutateAsync: replaceMutation } = useMutation(replaceFood);

  useEffect(() => {
    const isRemovedByDefault = checkIsRemovedByDefault(foodPref, selectedMealToRemoveComponents);
    setApplyAlwaysForMeal(isRemovedByDefault);
  }, [selectedDelivery, selectedMealToRemoveComponents, foodPref]);

  useEffect(() => {
    if (previousApplyAlwaysForMeal && !applyAlwaysForMeal) {
      setSelectedRemovedComponentIdList([]);
      setSelectedSwappedComponent([]);
    }
    setPreviousApplyAlwaysForMeal(applyAlwaysForMeal);
  }, [applyAlwaysForMeal]);

  useEffect(() => {
    const isPreBuilt = selectedMealToRemoveComponents?.tags.includes(FoodDietType.preBuiltCustom);
    const isCaloKids = selectedMealToRemoveComponents?.type.includes(FoodType.caloKids);

    if (isPreBuilt) {
      const modifiedComp = allCustomFoodComponents.meal.filter((customComp) => {
        const mealSection = (customComp.sections ?? []).find((section) => section.category === CustomMealCategory.meal);
        return (
          mealSection?.tag ===
          (selectedComponent?.sections ?? []).find((section) => section.category === CustomMealCategory.meal)?.tag
        );
      });
      setCustomFoodComponents(modifiedComp || []);
    } else if (isCaloKids) {
      const modifiedComp = caloKidsComponents?.filter((customComp) => {
        const caloKidsSection = (customComp.sections ?? []).find((section) => section.category === CustomMealCategory.caloKids);
        return (
          caloKidsSection?.tag ===
          (selectedComponent?.sections ?? []).find((section) => section.category === CustomMealCategory.caloKids)?.tag
        );
      });
      setCustomFoodComponents(modifiedComp || []);
    } else {
      setCustomFoodComponents(
        allCustomFoodComponents.meal.filter(
          (customComp) =>
            (customComp.sections ?? []).find((seciton) => seciton.category === CustomMealCategory.meal)?.tag ===
            FoodComponentType.sauce
        ) || []
      );
    }
  }, [selectedComponent, selectedMealToRemoveComponents, caloKidsComponents]);

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = uniqBy(flatten(compact(allSizes.filter((r) => r.size === size))), 'id');
    setSelectedSize({
      size: selectedMealData[0].size,
      foodId: selectedMealData[0].id,
      foodName: selectedMealData[0].name?.en
    });
    handleReplace(selectedMealData[0]);
  };

  const handleReplace = async (food: any) => {
    await replaceMutation({
      id: selectedDelivery?.id || '',
      sourceId: meal.id,
      targetId: food.size === 'C' ? (food.id === 'food' ? food.sk : food.id) : food.id || food.foodId,
      positionIndex: meal?.positionIndex
    });
    refetch();
    mealSwapRef.current?.close();
    editMealRef.current?.close();
    setSelectedComponent(undefined);
  };

  const handleFoodSize = (currentFood: MenuFood) => {
    const sameMealSize = flatten(Object.values(groupBy(menu?.food, 'name.en')));
    const allSizes = uniqBy(compact(sameMealSize.map((r) => r.name?.en === currentFood.name?.en && r)), 'size');
    return (
      <Tooltip
        title={`${allSizes.length === 0 ? 'No Sizes' : allSizes.length <= 1 ? 'The only size for this meal is already Selected' : selectedDelivery && !PermissionService.deliveryCanBeEdited(selectedDelivery) ? 'Can not change size during lockup time' : ''}`}
        placement="top"
        arrow
      >
        <Select
          value={selectedSize.size === currentFood.size ? selectedSize.size : currentFood.size}
          name="size"
          style={{ ...styles.selectSize }}
          disabled={
            menuLoading ||
            !(allSizes.length > 1) ||
            (selectedDelivery && !PermissionService.deliveryCanBeEdited(selectedDelivery))
          }
          onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
        >
          {(allSizes.length === 0 ? [{ size: currentFood.size }] : Object.values(allSizes)).map((type) => (
            <MenuItem key={type.size} value={type.size}>
              {startCase(type.size)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    );
  };

  const handleCustomizeMeal = async (
    foodId: string,
    delId: string,
    removedIds: string[],
    swappedIds: { oldId: string; newId: string }[]
  ) => {
    const actions = [
      {
        type: FoodActionType.remove,
        componentId: removedIds
      },
      {
        type: FoodActionType.swap,
        swappedComponents: swappedIds,
        componentId: swappedIds.map((swap) => swap.oldId)
      }
    ];
    setCustomizeMealLoading(true);
    await customizeMealMutation(
      {
        foodId: foodId,
        deliveryId: delId,
        actions: actions.filter((action) => action.componentId.length !== 0),
        applyAlwaysForMeal
      },
      {
        onSuccess: () => {
          refetch();
          editMealRef.current?.close();
        },
        onError: () => {
          setCustomizeMealLoading(false);
        }
      }
    );
    foodPrefRef();
    setCustomizeMealLoading(false);
  };

  const handleActionData = (meal: FoodWithPosition) => {
    if (!meal.actions) return [];
    const results: string[] = [];

    const removedAction = meal.actions
      .filter((action) => action.type === FoodActionType.remove)
      .flatMap((component) => component.componentId);

    const swappedAction = meal.actions
      .filter((action) => action.type === FoodActionType.swap)
      .flatMap((component) => component.swappedComponents);

    if (removedAction.length > 0) {
      const removed = removedAction.map((removed) => {
        const matchingComponent = allFoodComponentsData.find((componentData) => componentData.id === removed);
        return matchingComponent?.name?.en || '';
      });
      results.push(removed.map((remove, index) => `${remove} removed ${index + 1 === removed.length ? '' : ','}`).join(' '));
    }

    if (swappedAction.length > 0) {
      results.push(
        swappedAction
          .map((removed, index) => {
            const oldComponentName = allFoodComponentsData.find((componentData) => componentData.id === removed?.oldId)?.name?.en;
            const newComponentName = [...allFoodComponentsData, ...customFoodComponents, ...allCustomFoodComponents.meal].find(
              (customComp) => customComp.id === removed?.newId
            )?.name?.en;
            return `Swapped ${oldComponentName} with ${newComponentName} ${index + 1 === swappedAction.length ? '' : ','}`;
          })
          .join(' ')
      );
    }
    return results.join(', ');
  };

  const handleUpdateCustomFood = async (x: any, customName: Localized, mealDescription: Localized) => {
    const customComponents = flatten(Object.values(x)).map((r: any) => ({ id: r.value }));
    await updateCustomFoodMutation(
      {
        deliveryId: selectedDelivery.id,
        foodId: customFood.id === 'food' ? customFood.sk : customFood.id,
        name: customName,
        components: customComponents.map((r) => r.id),
        description: mealDescription
      },
      {
        onSuccess: () => {
          editCustomMeal.current?.close();
          setCustomFood(null);
          refetch();
        }
      }
    );
  };

  const handleEnlargeMealImage = (selectedMeal: FoodWithPosition) => {
    setSelectedMeal(selectedMeal);
    enlargeRef.current?.open();
  };

  const handleFavoriteMeal = (selectedMeal: Food | undefined) => {
    if (!selectedMeal) return;
    setIsFavoriteLoading(true);
    handleUpdateSubscriptionFavoriteMeal(selectedMeal.id).then(() => {
      setIsFavoriteLoading(false);
    });
  };

  const closeCustomFoodPopupHandler = () => {
    setCustomFood(null);
    editCustomMeal.current?.close();
  };

  return (
    <Box
      id={meal.id}
      sx={{ border: 1, borderColor: caloTheme.palette.neutral300, marginY: 1, padding: 1, marginRight: 2, borderRadius: '8px' }}
    >
      <Stack flexDirection={'row'}>
        <Box sx={{ position: 'relative', marginRight: 1 }}>
          <Icon
            name={isFavorite ? 'redFavorite' : 'favorite'}
            onClick={() => (isFavoriteLoading ? null : handleFavoriteMeal(meal))}
            style={{
              ...styles.favMeal,
              position: 'absolute',
              width: isFavorite ? '44px' : '32px',
              height: isFavorite ? '44px' : '32px'
            }}
          />
          <img
            className="object-cover"
            width={120}
            style={{ maxHeight: '120px', maxWidth: '120px', borderRadius: 4 }}
            height={120}
            onClick={() => handleEnlargeMealImage(meal)}
            alt={meal.name?.en}
            onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
            src={meal.size === 'C' ? customMealImage : `${process.env.REACT_APP_BUCKET_URL}/food/${meal.id}/square@1x.jpg`}
          />
        </Box>
        <Stack flexDirection={'column'} width={'100%'}>
          <Stack justifyContent={'space-between'} flexDirection={'row'}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>{meal?.name?.en}</Typography>
            <Stack style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
              {meal.size === 'C' || meal.addedAs === 'addon' || meal.isGiftedItem ? (
                <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mr: 3, my: 'auto' }}>
                  <Typography sx={{ mx: 'auto' }}>{meal.size}</Typography>
                </Stack>
              ) : (
                handleFoodSize(meal)
              )}
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  height: '45px',
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  boxShadow: 'none',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  backgroundColor: caloTheme.palette.neutral50,
                  '&:hover': {
                    backgroundColor: caloTheme.palette.neutral50,
                    boxShadow: 'none'
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    width: 'auto'
                  }
                }}
                disabled={
                  !PermissionService.deliveryCanBeEdited(selectedDelivery) ||
                  meal.addedAs === 'addon' ||
                  meal.isGiftedItem ||
                  meal.type.includes(FoodType.juice) ||
                  meal.type.includes(FoodType.coffee) ||
                  meal.type.includes(FoodType.salad) ||
                  meal.type.includes(FoodType.dessert)
                }
                onClick={() => {
                  if (meal.size === 'C') {
                    editCustomMeal.current?.open();
                    setCustomFood(meal);
                  } else {
                    setSelectedMealToRemoveComponents(meal);
                    setSelectedSwappedComponent(
                      meal.actions
                        ?.filter((action) => action.type === FoodActionType.swap)
                        ?.flatMap((action) => action.swappedComponents || []) ?? []
                    );
                    setSelectedRemovedComponentIdList(
                      meal.actions
                        ?.filter((action) => action.type === FoodActionType.remove)
                        ?.flatMap((action) => action.componentId || []) ?? []
                    );
                    editMealRef.current?.open();
                  }
                }}
              >
                <Iconify icon="fluent:edit-28-regular" color={caloTheme.palette.neutral900} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: 'auto',
                  height: '45px',
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  boxShadow: 'none',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  backgroundColor: caloTheme.palette.neutral50,
                  '&:hover': {
                    backgroundColor: caloTheme.palette.neutral50,
                    boxShadow: 'none'
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    width: 'auto'
                  }
                }}
                disabled={
                  !PermissionService.deliveryCanBeEdited(selectedDelivery) || meal.addedAs === 'addon' || meal.isGiftedItem
                }
                onClick={() => mealSwapRef.current?.open()}
              >
                <Iconify icon="tdesign:refresh" color={caloTheme.palette.neutral900} />
              </Button>
            </Stack>
          </Stack>
          <MealTotalInformationSection meals={[meal]} key={selectedDelivery.id} />
        </Stack>
      </Stack>
      <Box sx={{ marginY: '8px' }}>
        <Stack>
          <Typography sx={styles.cardSubTitle}>Ingredients</Typography>
          {menuLoading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', mt: '4px' }}>
              <Skeleton variant="rectangular" height={5} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
              <Skeleton variant="rectangular" height={5} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
            </Box>
          ) : (
            <Stack display={'flex'} flexDirection={'row'} width={'full'} flexWrap={'wrap'} sx={{ marginY: '4px' }}>
              {renderIngredients(meal, selectedDelivery, [...allFoodComponentsData, ...allCustomFoodComponents.meal]).map(
                (i: any, index: number, array: any[]) => (
                  <Tooltip key={i.id} title={`${i.weightedIng}`} placement="top" arrow>
                    <Typography
                      key={index}
                      sx={{ ...styles.ingredientsText, color: i.isAlergen ? 'red' : caloTheme.palette.neutral900 }}
                    >
                      {`${i.name}${index === array.length - 1 ? '' : ',\u00A0'}`}
                    </Typography>
                  </Tooltip>
                )
              )}
            </Stack>
          )}
        </Stack>
        {meal.actions && meal.actions.length > 0 && (
          <Stack sx={{ marginY: '8px' }}>
            <Typography sx={styles.cardSubTitle}>Actions</Typography>
            {menuLoading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', mt: '4px' }}>
                <Skeleton variant="rectangular" height={5} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
                <Skeleton variant="rectangular" height={5} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
              </Box>
            ) : (
              <Stack display={'flex'} flexDirection={'row'} width={'full'} flexWrap={'wrap'} sx={{ paddingY: 1 }}>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '14px' }}>{handleActionData(meal)}</Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Box>

      <Popup fullWidth maxWidth="lg" ref={mealSwapRef} onClose={() => mealSwapRef.current?.close()} title="Replace Meal">
        <ReplaceMealPopup
          selectedMeal={meal}
          menuFood={menu?.food}
          selectedDelivery={selectedDelivery}
          refetchDel={() => refetch()}
          mealSwapRef={mealSwapRef}
          isCustomCompsLoading={isCustomCompsLoading}
          customFoodComps={allCustomFoodComponents}
          allFoodComponentsData={allFoodComponentsData}
        />
      </Popup>
      <Popup
        fullWidth
        maxWidth="lg"
        ref={editMealRef}
        onClose={() => {
          editMealRef.current?.close();
          setSelectedComponent(undefined);
        }}
        title="Customize Meal"
      >
        <ModifyMealPopup
          selectedMeal={meal}
          selectedDelivery={selectedDelivery}
          selectedComponent={selectedComponent}
          customFoodComponents={customFoodComponents}
          caloKidsComponent={caloKidsComponents}
          allFoodComponentsData={allFoodComponentsData}
          setCustomFoodComponents={setCustomFoodComponents}
          allCustomFoodComponents={allCustomFoodComponents}
          selectedSwappedComponent={selectedSwappedComponent}
          isCaloKidsMeal={meal.type?.includes(FoodType.caloKids)}
          setSelectedSwappedComponent={setSelectedSwappedComponent}
          setSelectedComponent={(comp) => setSelectedComponent(comp)}
          selectedRemovedComponentIdList={selectedRemovedComponentIdList}
          setSelectedRemovedComponentIdList={setSelectedRemovedComponentIdList}
          isPreBuiltMeal={meal.tags?.includes(FoodDietType.preBuiltCustom)}
        />
        <Box>
          <Stack
            sx={{
              backgroundColor: caloTheme.palette.grey[50],
              padding: 1,
              borderRadius: 2,
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography sx={{ fontFamily: caloTheme.typography.body1, fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
              Save changes for the future
            </Typography>
            <IOSSwitch onChange={() => setApplyAlwaysForMeal(!applyAlwaysForMeal)} checked={applyAlwaysForMeal} />
          </Stack>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ mt: 2, alignItems: 'center' }}>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              sx={{ ...styles.cancelButton }}
              onClick={() => {
                editMealRef.current?.close();
                setSelectedComponent(undefined);
              }}
              disabled={customizeMealLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              aria-label="confirm-remove-meal"
              sx={{ ...styles.saveButton }}
              disabled={customizeMealLoading}
              endIcon={customizeMealLoading ? <CircularProgress size={20} /> : null}
              onClick={() =>
                handleCustomizeMeal(
                  selectedMealToRemoveComponents!.id,
                  selectedDelivery!.id,
                  selectedRemovedComponentIdList,
                  selectedSwappedComponent
                )
              }
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Popup>
      <Popup maxWidth="lg" ref={editCustomMeal} onClose={closeCustomFoodPopupHandler}>
        <CustomFoodPopup
          isLoading={isCustomCompsLoading}
          customMealAction={'update'}
          customFood={customFood}
          closeHandler={closeCustomFoodPopupHandler}
          handleUpdateCustomFood={handleUpdateCustomFood}
          customFoodComps={allCustomFoodComponents}
        />
      </Popup>
      <Popup
        ref={enlargeRef}
        onClose={() => enlargeRef.current?.close()}
        title={selectedMeal?.name.en}
        info={
          <>
            <IconButton onClick={() => enlargeRef.current?.close()}>
              <CloseIcon />
            </IconButton>
          </>
        }
      >
        <Box>
          <Stack>
            <Icon
              name={isFavorite ? 'redFavorite' : 'favorite'}
              onClick={() => (isFavoriteLoading ? null : handleFavoriteMeal(selectedMeal))}
              style={{
                ...styles.favButtonEnlarged,
                position: 'absolute',
                width: isFavorite ? '64px' : '50px',
                height: isFavorite ? '64px' : '50px'
              }}
            />
            <img
              style={styles.enLargedIcon}
              alt={selectedMeal?.name.en}
              src={
                meal.size === 'C' ? customMealImage : `${process.env.REACT_APP_BUCKET_URL}/food/${selectedMeal?.id}/square@3x.jpg`
              }
              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
            />
          </Stack>
        </Box>
      </Popup>
    </Box>
  );
};
export default DeliveryMealCard;
