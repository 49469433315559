import { INITIAL_DELIVERY_DAY_STATUS_STATE } from 'lib/constants';
import { uniq } from 'lodash-es';

export const calculateDeliveryDayStatus = (subscriptionDeliveries) => {
  // this is done for deep copy of initial state object
  const deliveryStatus = JSON.parse(JSON.stringify(INITIAL_DELIVERY_DAY_STATUS_STATE));

  if (subscriptionDeliveries?.data) {
    for (const delivery of subscriptionDeliveries.data) {
      if (delivery.skipped) {
        deliveryStatus.skipped = uniq([...deliveryStatus.skipped, delivery.day]);
      }
      if (delivery.deliveredAt) {
        deliveryStatus.delivered = uniq([...deliveryStatus.delivered, delivery.day]);
      }
      if (delivery.status === 'cancelled') {
        deliveryStatus.cancelled = uniq([...deliveryStatus.cancelled, delivery.day]);
      }
      if (!delivery.deliveredAt) {
        deliveryStatus.pending = uniq([...deliveryStatus.pending, delivery.day]);
      }
      if (delivery.status === 'paused') {
        deliveryStatus.paused = uniq([...deliveryStatus.paused, delivery.day]);
      }
    }
  }

  return deliveryStatus;
};
