export const currentMonth = new Date().getMonth() + 1;
export const perksStartMonth = 5;
export const perksStartYear = 2024;
export const getListOfMonthNames = () => {
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
  return Array.from({ length: 12 }, (_, i) => formatter.format(new Date(perksStartYear, i)));
};
export const getYearsArray = (nextMonthYear: number) => {
  const years: number[] = [];
  for (let year = perksStartYear; year <= nextMonthYear; year++) {
    years.push(year);
  }
  return years;
};

export const getNextPerkMonthAndYear = () => {
  const date = new Date();
  const nextMonth = date.getMonth() + 2;
  const year = date.getFullYear();

  return nextMonth > 11 ? { year: year + 1, month: 1 } : { year, month: nextMonth };
};

export const getAvailableMonths = (year: number) => {
  const { month } = getNextPerkMonthAndYear();
  const allMonths = getListOfMonthNames();
  if (year === perksStartYear) {
    return allMonths.slice(perksStartMonth, currentMonth + 1);
  }
  if (year > perksStartYear) {
    return allMonths.slice(0, month);
  }
  return allMonths;
};

export const getLastAvailableMonth = (year: number) => {
  const allMonths = getListOfMonthNames();
  const availableMonths = getAvailableMonths(year);
  return allMonths.indexOf(availableMonths[availableMonths.length - 1]) + 1;
};
