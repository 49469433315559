import { Subscription } from '@calo/dashboard-types';
import { Icon as Iconify } from '@iconify/react';
import { Box, Button, IconButton, Skeleton, Stack, TextareaAutosize, Typography } from '@mui/material';
import { getRecord, updateFoodPreference } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { format, parseISO } from 'date-fns/fp';
import { HealthConditions } from 'lib/enums';
import { useUserData } from 'lib/hooks';
import { orderBy, startCase } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styles from './styles';
interface HealthConditionNotesProps {
  foodPref: any;
  favMealIds: string[];
  ingredientsToAvoid: any[];
  healthConditions: HealthConditions[];
  selectedCustomer: { value: string } | null;
  allCustomerNotes: HealthConditionNote[];
  setAllCustomerNotes: React.Dispatch<React.SetStateAction<HealthConditionNote[]>>;
}

export interface HealthConditionNote {
  userId: string;
  userName: string;
  note: string;
  createdAt: string;
}

export interface CustomerHealthConditions {
  healthConditions: HealthConditions[];
  notes?: HealthConditionNote[];
}

const HealthConditionNotes: React.FC<HealthConditionNotesProps> = ({
  selectedCustomer,
  allCustomerNotes,
  ingredientsToAvoid,
  setAllCustomerNotes,
  foodPref,
  healthConditions,
  favMealIds
}) => {
  const userD = useUserData();
  const [isLoadingAddingNote, setIsLoadingAddingNote] = useState<boolean>(false);
  const showAllNotesRef = useRef<ModalRef>();
  const addNotesRef = useRef<ModalRef>();
  const [editedNote, setEditedNote] = useState<{ index?: number; note?: string }>({});
  const [addedNote, setAddedNote] = useState<HealthConditionNote>({
    userId: userD.id,
    userName: userD.name,
    note: '',
    createdAt: ''
  });

  const { data: subscriptionData, isLoading } = useQuery(['subscriptions', selectedCustomer?.value], getRecord, {
    suspense: false,
    enabled: !!selectedCustomer
  });
  const subscription = subscriptionData as Subscription;

  const { mutateAsync: updateFoodPrefNotes } = useMutation(updateFoodPreference);

  const handleUpdateSubscription = async (data: HealthConditionNote[]) => {
    setIsLoadingAddingNote(true);
    await updateFoodPrefNotes(
      {
        subscriptionId: subscription?.id,
        allergicIng: ingredientsToAvoid || [],
        healthConditions: healthConditions || [],
        notes: data,
        favorite: favMealIds
      },
      {
        onSuccess: (updatedData) => {
          setAllCustomerNotes(orderBy(updatedData.foodPreference.healthConditions.notes, ['createdAt'], ['desc']));
        }
      }
    );
    setIsLoadingAddingNote(false);
    addNotesRef.current?.close();
    showAllNotesRef.current?.close();
    setEditedNote({});
  };

  const handleEdit = (index: number) => {
    setEditedNote({ index, note: allCustomerNotes[index - 1].note });
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedNote({ ...editedNote, note: event.target.value });
  };

  const handleUpdateNote = () => {
    if (editedNote.index !== undefined) {
      const updatedNotes = [...allCustomerNotes];
      updatedNotes[editedNote.index - 1] = { ...updatedNotes[editedNote.index - 1], note: editedNote.note || '' };
      handleUpdateSubscription(updatedNotes);
      setEditedNote({});
    }
  };

  const handleRenderNoteCard = (noteData: HealthConditionNote, edit: boolean, index: number) => {
    return (
      <Box
        sx={{
          backgroundColor: caloTheme.palette.neutral50,
          paddingY: 1,
          marginBottom: 1,
          flexDirection: 'row',
          width: '100%',
          display: 'flex'
        }}
      >
        <Stack sx={{ padding: 1, width: '99%' }}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Stack sx={{ my: '6px' }}>
              <Typography
                sx={{
                  color: caloTheme.palette.neutral900,
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '20px'
                }}
              >
                {noteData.note}
              </Typography>
            </Stack>
            {edit && (
              <Stack display={'flex'}>
                <IconButton
                  sx={{
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    backgroundColor: caloTheme.palette.neutral50,
                    '&:hover': {
                      backgroundColor: caloTheme.palette.neutral50,
                      boxShadow: 'none'
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      justifyItems: 'center',
                      margin: 'auto',
                      width: 'auto'
                    }
                  }}
                  onClick={() => {
                    handleEdit(index + 1);
                    addNotesRef.current?.open();
                  }}
                >
                  <Iconify icon="fluent:edit-28-regular" style={{ cursor: 'pointer' }} color={caloTheme.palette.neutral900} />
                </IconButton>
                <IconButton
                  sx={{
                    height: '45px',
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    backgroundColor: caloTheme.palette.neutral50,
                    '&:hover': {
                      backgroundColor: caloTheme.palette.neutral50,
                      boxShadow: 'none'
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      justifyItems: 'center',
                      margin: 'auto',
                      width: 'auto'
                    }
                  }}
                  onClick={() => handleUpdateSubscription(allCustomerNotes.filter((note, i) => index !== i))}
                >
                  <Iconify icon="ph:trash" style={{ cursor: 'pointer' }} color={caloTheme.palette.neutral900} />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
            <Typography
              sx={{
                my: 'auto',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '-0.38px'
              }}
            >
              {startCase(noteData.userName)}
            </Typography>
            <Typography
              sx={{
                my: 'auto',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '14px',
                letterSpacing: '-0.38px'
              }}
            >
              {noteData.createdAt && format('dd-MM-yyyy')(parseISO(noteData.createdAt))}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={50} sx={{ bgcolor: caloTheme.palette.neutral100 }} />
      </Stack>
    );
  }

  if (subscription) {
    return (
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} flexDirection={'column'}>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{ mb: 1 }}>
          <Typography sx={{ ...styles.addButtonText }}>Notes</Typography>
          <Typography sx={{ ...styles.addButtonText }} onClick={() => addNotesRef.current?.open()}>
            + Add
          </Typography>
        </Box>
        {allCustomerNotes?.length > 0 && (
          <>
            <Box sx={{ paddingY: 1 }}>
              {allCustomerNotes?.map((note: any, index) => index <= 2 && handleRenderNoteCard(note, true, index))}
            </Box>
            <Stack display={allCustomerNotes?.length > 3 ? 'flex' : 'none'}>
              <Button variant="text" sx={{ ...styles.showAllButton }} onClick={() => showAllNotesRef.current?.open()}>
                Show All
              </Button>
            </Stack>
          </>
        )}
        <Popup
          fullWidth
          maxWidth="lg"
          ref={showAllNotesRef}
          onClose={() => {
            setEditedNote({});
            showAllNotesRef.current?.close();
          }}
          title="Notes"
        >
          <Box>
            <Stack sx={{ overflow: 'auto', maxHeight: '42vh' }}>
              {allCustomerNotes?.map((noteData, index) => handleRenderNoteCard(noteData, true, index))}
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} sx={{ marginTop: 2 }}>
              <Button
                variant="contained"
                sx={{ ...styles.confirmButton }}
                onClick={() => handleUpdateSubscription(allCustomerNotes)}
                disabled={allCustomerNotes === foodPref?.healthConditions?.notes}
              >
                Confirm
              </Button>
              <Button variant="text" sx={{ ...styles.cancelButton }} onClick={() => showAllNotesRef.current?.close()}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </Popup>
        <Popup
          fullWidth
          maxWidth="lg"
          ref={addNotesRef}
          onClose={() => {
            setEditedNote({});
            addNotesRef.current?.close();
          }}
          title={editedNote?.index && editedNote.index >= 0 ? 'Edit Note' : 'Add Note'}
        >
          <Box>
            <Stack>
              <Typography>Note</Typography>
              <TextareaAutosize
                style={{
                  borderWidth: 1,
                  borderColor: caloTheme.palette.neutral900,
                  margin: 2,
                  borderRadius: '8px',
                  padding: 10
                }}
                minRows={10}
                placeholder="text"
                value={editedNote ? editedNote.note : addedNote ? addedNote.note : ''}
                onChange={(data) =>
                  editedNote.index
                    ? handleNoteChange(data)
                    : setAddedNote({
                        userId: userD.id,
                        userName: userD.name,
                        note: data.target.value,
                        createdAt: new Date().toISOString()
                      })
                }
              />
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} sx={{ marginTop: 2 }}>
              <Button
                variant="contained"
                sx={{ ...styles.editButton }}
                disabled={isLoadingAddingNote}
                onClick={() =>
                  editedNote.index ? handleUpdateNote() : handleUpdateSubscription([...allCustomerNotes, { ...addedNote }])
                }
              >
                {editedNote.index ? 'Edit' : 'Add'}
              </Button>
              <Button
                variant="text"
                sx={{ ...styles.cancelButton }}
                onClick={() => {
                  setAddedNote({
                    userId: userD.id,
                    userName: userD.name,
                    note: '',
                    createdAt: ''
                  });
                  addNotesRef.current?.close();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Popup>
      </Box>
    );
  }

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{ mb: 1 }}>
        <Typography sx={styles.addButtonText}>Notes</Typography>
      </Box>
      <Box>
        <Typography sx={styles.noSubscriptionText}>No Subscription Found</Typography>
      </Box>
    </>
  );
};

export default HealthConditionNotes;
