import { useEffect, useRef, useState } from 'react';

import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import cx from 'classnames';
import { Icon } from 'components';
import { format } from 'date-fns/fp';
import { DeliveryTime, OperationType } from 'lib/enums';
import { isDeliverable, makeFoodSlug } from 'lib/helpers';
import queryClient from 'lib/queryClient';
import { orderBy } from 'lodash';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { Brand, Delivery, DeliveryStatus, Food, Kitchen, SubscriptionTier } from '@calo/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  ButtonGroup,
  Card,
  ClickAwayListener,
  Grow,
  Button as MUIButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

import useDeliveryTickets from 'hooks/useDeliveryTickets';
import useExtraMeals from 'hooks/useExtraMeals';
import client from 'lib/client';
import ExtraMealList from '../ExtraMealList';
import RequestHistoryTable from '../RequestHistoryTable';
import CreateLogisticRequest from './CreateLogisticRequest/CreateLogisticRequest';
import CreateLogisticRequestInput from './CreateLogisticRequest/CreateLogisticRequestInput';
import { downloadPDF } from './DownloadPDF';
import { copyToClipBoard } from './utils/copyToClipBoard';

interface PaginatedDeliveries {
  data: Delivery[];
  meta: {
    limit: number;
    total: number;
  };
}

interface GetDeliveryCostRequest {
  planType: string;
  kitchen: string;
  tier: string;
  dietType: string;
  foodTypes: string[];
  frequency: string;
  food: Food[];
  addons: any[];
}

const OperationsChangePage = () => {
  const today = new Date();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [addedComment, setAddedComment] = useState<string>('');
  const [coolerBagSelected, setCoolerBagSelected] = useState<boolean>(false);
  const [cutlerySelected, setCutlerySelected] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const options = ['Copy Meals in English', 'Copy Meals in Arabic', 'Download English PDF', 'Download Arabic PDF'];
  const [deliveryExist, setDeliveryExist] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery>();
  const [deliverCostManual, setDeliveryCostManual] = useState<number>(0);
  const [selectedManualMeals, setSelectedManualMeals] = useState<any[]>();
  const [selectedManualAddress, setSelectedManualAddress] = useState<any>();
  const [selectedUserBalance, setSelectedUserBalance] = useState<number>();
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen>(Kitchen.BH1);
  const [selectedDateReq, setSelectedDateReq] = useState<string>(format('yyyy-MM-dd')(today));
  const [selectedOperation, setSelectedOperation] = useState<OperationType>(OperationType.mealSwap);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState<DeliveryTime>(DeliveryTime.morning);
  const [selectedMealSwaps, setSelectedMealSwaps] = useState<any[]>([]);

  let kitchen: Kitchen;
  if (selectedCustomer?.kitchen) {
    kitchen = selectedCustomer?.kitchen;
  } else if (selectedDelivery?.kitchen) {
    kitchen = selectedDelivery?.kitchen;
  } else {
    kitchen = Object.values(Kitchen).find((r) => r.includes(selectedCustomer?.country) && !r.includes('000')) as Kitchen;
  }

  const { refetchAll, allData, isLoading, loadingSentManualReq, loadingSentOtherlReq } = useDeliveryTickets({
    selectedKitchen,
    selectedDateReq
  });

  const clearData = () => {
    refetchAll();
    refetchExtraMeals();
    setSelectedDate('');
    setSelectedCustomer(undefined);
    setAddedComment('');
    setCoolerBagSelected(false);
    setCutlerySelected(false);
    setSelectedManualMeals([]);
    setSelectedMealSwaps([]);
  };

  const { extraMeals, refetchExtraMeals, loadingExtraMeals } = useExtraMeals({
    selectedDate,
    selectedCustomer,
    kitchen,
    selectedOperation,
    selectedDelivery
  });

  const { refetch: refetchDeliveries, isLoading: loadingDelivery } = useQuery<any, Error, PaginatedDeliveries>(
    [
      `deliveries`,
      {
        limit: 1,
        page: 0,
        filters: {
          day: { gte: selectedDate, lte: selectedDate },
          userIds: [selectedCustomer?.value],
          status: [DeliveryStatus.upcoming, DeliveryStatus.paymentRequired],
          skipped: false
        }
      }
    ],
    getListWithParams,
    {
      onSuccess: (data) => {
        const index = data.data.findIndex((delivery) => delivery.day === selectedDate);
        if (
          selectedOperation === OperationType.manualDelivery &&
          selectedCustomer &&
          data.data[index] &&
          isDeliverable(data.data[index]) &&
          index >= 0
        ) {
          setDeliveryExist(false);
          toast(`Delivery already exist for the selected customer on ${selectedDate}`, {
            type: 'error',
            autoClose: 2000
          });
        } else if (
          selectedOperation === OperationType.manualDelivery &&
          (index === -1 || (index >= 0 && !isDeliverable(data.data[index]))) &&
          selectedCustomer
        ) {
          setDeliveryExist(true);
          setSelectedDelivery(data.data[index]);
          setSelectedDeliverySlot(data.data[index]?.time || DeliveryTime.morning);
        } else if (index >= 0 && selectedCustomer) {
          setDeliveryExist(true);
          setSelectedDelivery(data.data[index]);
          console.log(
            selectedCustomer.data.deliveryAddresses.find((address: any) => address.default),
            '3'
          );
          setSelectedDeliverySlot(data.data[index]?.time || DeliveryTime.morning);
        } else if (selectedCustomer && index < 0) {
          setDeliveryExist(false);
          setSelectedDelivery(undefined);
          setSelectedDeliverySlot(data.data[index]?.time || DeliveryTime.morning);
          toast(`No delivery for the selected customer on ${selectedDate}`, { type: 'error', autoClose: 2000 });
        }
      },
      enabled: !!selectedCustomer && !!selectedDate
    }
  );

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedManualAddress(selectedCustomer.data.deliveryAddresses.find((address: any) => address.default).id || '');
    }
  }, [selectedCustomer]);
  const orderedRequests = orderBy(allData, 'createdAt', 'desc');

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedDelivery(undefined);
      refetchDeliveries();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedDelivery(undefined);
      refetchDeliveries();
    }
  }, [selectedDate]);

  useEffect(() => {
    refetchDeliveries();
    setSelectedManualMeals([]);
    setSelectedMealSwaps([]);
  }, [selectedOperation]);

  useEffect(() => {
    if (selectedCustomer && selectedOperation === OperationType.manualDelivery) {
      setDeliveryCostManual(0);
      if (selectedCustomer.data.balance[selectedCustomer.data.currency] <= 0) {
        setSelectedUserBalance(selectedCustomer.data.balance[selectedCustomer.data.currency]);
      } else {
        setSelectedUserBalance(undefined);
      }
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedManualMeals && selectedManualMeals.length > 0) {
      const fetchDeliveryCost = async () => {
        const deliveryFoodItems = selectedManualMeals.map((meal) => ({
          id: meal.id,
          foodType: meal.type,
          name: meal.name,
          size: meal.size,
          positionIndex: meal.positionIndex,
          ...meal.data
        }));

        const deliveryCost = await getDeliveryCost({
          planType: selectedCustomer!.data.plan.id,
          kitchen: selectedCustomer?.kitchen,
          tier: selectedCustomer.data.tier || SubscriptionTier.personalized,
          dietType: selectedCustomer!.data.plan.dietType,
          foodTypes: selectedCustomer!.data.plan.foodType,
          frequency: selectedCustomer!.data.plan.frequency,
          food: deliveryFoodItems,
          addons: []
        });
        setDeliveryCostManual(deliveryCost);
      };

      fetchDeliveryCost();
    } else {
      setDeliveryCostManual(0);
    }
  }, [selectedManualMeals]);

  useEffect(() => {
    if (extraMeals) {
      queryClient.setQueryData(
        [
          `kitchens/${selectedCustomer?.kitchen ? selectedCustomer.kitchen : selectedDelivery?.kitchen ? selectedDelivery?.kitchen : Object.values(Kitchen).find((r) => r.includes(selectedCustomer?.country) && !r.includes('000'))}/extra-meals`,
          {
            date: selectedDate,
            brand: selectedCustomer?.data.brand || Brand.CALO,
            userId: selectedCustomer?.value
          },
          {}
        ],
        extraMeals?.map((row) => ({
          ...row,
          slug:
            row.slug ||
            makeFoodSlug(row.name, selectedCustomer.data.brand || Brand.CALO, selectedCustomer.data.kitchen, row.isCustom, row.id)
        }))
      );
    }
  }, [extraMeals]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    if (index < 2) {
      copyToClipBoard(extraMeals || [], options[index].includes('English') ? 'En' : 'Ar');
    } else {
      downloadPDF(extraMeals || [], options[index].includes('English') ? 'En' : 'Ar');
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: 2,
            width: 'full',
            justifyContent: 'space-between',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              width: '50%',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Typography
              variant="h3"
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center'
                },
                textAlign: 'left',
                fontSize: '28px',
                lineHeight: '32px',
                mt: '2px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600
              }}
            >
              Operations Locktime Changes
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                mt: 2,
                width: '100%',
                textAlign: 'center',
                flexDirection: 'column'
              }
            }}
          >
            {extraMeals && extraMeals?.length > 0 && (!loadingDelivery || !loadingExtraMeals) && (
              <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="split button"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    border: 1,
                    height: '41px',
                    marginRight: 2,
                    borderRadius: '8px',
                    borderColor: caloTheme.palette.primary500,
                    color: caloTheme.palette.primary500,
                    ':hover': {
                      color: caloTheme.palette.primary600,
                      backgroundColor: caloTheme.palette.primary100,
                      border: 1,
                      borderRadius: '8px'
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '100%',
                      textAlign: 'center',
                      flexDirection: 'row'
                    }
                  }}
                >
                  <MUIButton
                    onClick={() => {
                      if (selectedIndex < 2) {
                        copyToClipBoard(extraMeals, options[selectedIndex].includes('English') ? 'En' : 'Ar');
                      } else {
                        downloadPDF(extraMeals, options[selectedIndex].includes('English') ? 'En' : 'Ar');
                      }
                    }}
                    sx={{
                      borderColor: caloTheme.palette.primary500,
                      color: caloTheme.palette.primary500,
                      fontWeight: 600,
                      ':hover': {
                        color: caloTheme.palette.primary600,
                        backgroundColor: caloTheme.palette.primary100,
                        border: 1,
                        borderRadius: '8px'
                      },
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        width: '100%',
                        textAlign: 'center',
                        flexDirection: 'row'
                      }
                    }}
                  >
                    {options[selectedIndex]}
                  </MUIButton>
                  <MUIButton
                    size="small"
                    aria-controls={'split-MUIButton-menu'}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select copy language"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    sx={{
                      borderColor: caloTheme.palette.primary500,
                      color: caloTheme.palette.primary500,
                      ':hover': {
                        color: caloTheme.palette.primary600,
                        backgroundColor: caloTheme.palette.primary100,
                        border: 1,
                        borderRadius: '8px'
                      }
                    }}
                  >
                    <ArrowDropDownIcon />
                  </MUIButton>
                </ButtonGroup>
                <Popper
                  sx={{
                    width: '241px',
                    zIndex: 1,
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: 'auto'
                    }
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                      }}
                    >
                      <Paper
                        sx={{
                          width: '100%'
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            id="split-button-menu"
                            autoFocusItem
                            sx={{
                              width: '100%'
                            }}
                          >
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={() => handleMenuItemClick(index)}
                                sx={{
                                  fontFamily: caloTheme.typography.fontFamily,
                                  fontWeight: 600,
                                  fontSize: '16px',
                                  lineHeight: '19px',
                                  flexGrow: 0,
                                  width: '100%',
                                  textTransform: 'capitalize'
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Stack>
            )}
          </Stack>
        </Box>
      </Card>
      <Card
        variant="outlined"
        sx={{
          mb: '14px',
          width: 'full',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <div className="mt-8">
          <div className="flex flex-row">
            <div className="flex flex-column w-8/12 h-8/12">
              <div className="flex flex-row mb-4">
                <span
                  className={cx('w-1/2 border-b-2 flex flex-row cursor-pointer', {
                    'border-cGray text-cGray': selectedOperation !== OperationType.mealSwap,
                    'border-cGreen text-cGreen': selectedOperation === OperationType.mealSwap
                  })}
                  onClick={() => setSelectedOperation(OperationType.mealSwap)}
                >
                  <p className="mx-auto font-bold" style={{ fontSize: '19px', lineHeight: '23px', fontFamily: 'Roboto' }}>
                    Meal Swap
                    <Icon
                      name={selectedOperation === OperationType.mealSwap ? 'greenSwap' : 'swap'}
                      size={6}
                      className="ml-2 mt-1"
                    />
                  </p>
                </span>
                <span
                  className={cx('w-1/2 border-b-2 flex flex-row cursor-pointer', {
                    'border-cGray text-cGray': selectedOperation !== OperationType.manualDelivery,
                    'border-cGreen text-cGreen': selectedOperation === OperationType.manualDelivery
                  })}
                  onClick={() => setSelectedOperation(OperationType.manualDelivery)}
                >
                  <p className="mx-auto font-bold" style={{ fontSize: '19px', lineHeight: '23px', fontFamily: 'Roboto' }}>
                    Manual Delivery
                    <Icon
                      name={selectedOperation === OperationType.manualDelivery ? 'greenTruck' : 'truck'}
                      size={6}
                      className="ml-2 mt-1"
                    />
                  </p>
                </span>
                <span
                  className={cx('w-1/2 border-b-2 flex flex-row cursor-pointer', {
                    'border-cGray text-cGray': selectedOperation !== OperationType.other,
                    'border-cGreen text-cGreen': selectedOperation === OperationType.other
                  })}
                  onClick={() => setSelectedOperation(OperationType.other)}
                >
                  <p className="mx-auto font-bold" style={{ fontSize: '19px', lineHeight: '23px', fontFamily: 'Roboto' }}>
                    Other
                    <Icon
                      name={selectedOperation === OperationType.other ? 'greenShoppingBag' : 'shoppingBag'}
                      size={6}
                      className="ml-2 mt-1"
                    />
                  </p>
                </span>
              </div>
              <CreateLogisticRequestInput
                today={today}
                addedComment={addedComment}
                deliverCostManual={deliverCostManual}
                deliveryExist={deliveryExist}
                extraMeals={extraMeals || []}
                selectedCustomer={selectedCustomer}
                selectedDate={selectedDate}
                selectedDelivery={selectedDelivery}
                selectedDeliverySlot={selectedDeliverySlot}
                selectedKitchen={selectedKitchen}
                selectedManualMeals={selectedManualMeals}
                selectedOperation={selectedOperation}
                selectedUserBalance={selectedUserBalance}
                coolerBagSelected={coolerBagSelected}
                cutlerySelected={cutlerySelected}
                setAddedComment={(comment) => setAddedComment(comment)}
                setDeliveryExist={(d) => setDeliveryExist(d)}
                setSelectedCustomer={(d) => setSelectedCustomer(d)}
                setSelectedDate={(d) => setSelectedDate(d)}
                setSelectedDeliverySlot={(d) => setSelectedDeliverySlot(d)}
                setSelectedManualMeals={(d) => setSelectedManualMeals(d)}
                selectedManualAddress={selectedManualAddress}
                setSelectedManualAddress={setSelectedManualAddress}
                setCoolerBagSelected={(d) => setCoolerBagSelected(d)}
                setCutlerySelected={(d) => setCutlerySelected(d)}
                selectedMealSwaps={selectedMealSwaps}
                setSelectedMealSwaps={setSelectedMealSwaps}
              />
              <CreateLogisticRequest
                selectedOperation={selectedOperation}
                deliverCostManual={deliverCostManual}
                selectedDate={selectedDate}
                selectedCustomer={selectedCustomer}
                selectedDelivery={selectedDelivery}
                coolerBagSelected={coolerBagSelected}
                cutlerySelected={cutlerySelected}
                selectedDeliverySlot={selectedDeliverySlot}
                setSelectedDeliverySlot={(d) => setSelectedDeliverySlot(d)}
                addedComment={addedComment}
                selectedManualMeals={selectedManualMeals}
                selectedManualAddress={selectedManualAddress}
                clearData={() => clearData()}
                selectedMealSwaps={selectedMealSwaps}
              />

              <RequestHistoryTable
                isLoading={isLoading || loadingSentManualReq || loadingSentOtherlReq}
                selectedDateReq={selectedDateReq}
                selectedKitchen={selectedKitchen}
                sentRequestData={orderedRequests || []}
                setSelectedKitchen={(d) => setSelectedKitchen(d)}
                setSelectedDateReq={(date) => setSelectedDateReq(date)}
              />
            </div>
            <ExtraMealList
              loadingDelivery={loadingDelivery}
              loadingExtraMeals={loadingExtraMeals}
              deliveryExist={deliveryExist}
              extraMeals={extraMeals || []}
              selectedCustomer={selectedCustomer}
              selectedDate={selectedDate}
              selectedOperation={selectedOperation}
            />
          </div>
        </div>
      </Card>
    </Box>
  );
};

export const getDeliveryCost = async (filters: GetDeliveryCostRequest) => {
  const { data } = await client.post('delivery/cost', filters);
  return data;
};

export default OperationsChangePage;
