import { getListWithParams, getListWithParamsPost } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import { useDocumentMedia } from 'hooks';
import { useUserRoles } from 'lib/hooks';
import { CustomComponentsByCategory, Delivery, FoodComponent, Menu as FoodMenu } from 'lib/interfaces';
import { flatMap, uniq, uniqBy } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';

import { AddonAddedAsType, Permission } from '@calo/dashboard-types';
import { Brand, DietType, Food, FoodType, Kitchen, MacrosType, Subscription } from '@calo/types';
import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material';

import AddMealAddonRow from './AddMealAddonRow';
import AddonPopup from './AddonPopup';
import DeficitMacrosBox from './DeficitMacrosBox';
import DeliveriesMealTableRow from './DeliveriesMealTableRow';
import DeliveriesMealTableRowMobile from './DeliveriesMealTableRowMobile';
import { StyledTableCell } from './styles';
import TargetMacrosBox from './TargetMacrosBox';
import TotalMacrosBox from './TotalMacrosBox';

interface DeliveriesMealTableProps {
  meals: Food[];
  addons: Food[];
  gifted: Food[];
  menu: FoodMenu;
  menuLoading: boolean;
  selectedDelivery: Delivery;
  subscription: Subscription;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
}

const DeliveriesMealTable = ({
  selectedDelivery,
  meals,
  gifted,
  addons,
  subscription,
  menu,
  menuLoading,
  refetchDelivery,
  setFetchMenu
}: DeliveriesMealTableProps) => {
  const { isMobile, isTablet } = useDocumentMedia();
  const addonModalRef = useRef<ModalRef>();

  const userRoles = useUserRoles();

  const [selectedAddType, setSelectedAddType] = useState<AddonAddedAsType>(AddonAddedAsType.addon);
  const [customFoodComponents, setCustomFoodComponents] = useState<FoodComponent[]>([]);
  const [allCustomFoodComponents, setAllCustomFoodComponents] = useState<CustomComponentsByCategory>({
    rice: [],
    meal: [],
    salad: [],
    sandwich: []
  });
  const [allMealComponents, setAllMealComponents] = useState<FoodComponent[]>([]);
  const [caloKidsComponents, setCaloKidsComponents] = useState<FoodComponent[]>([]);
  const [fetchAllComponents, setFetchAllComponents] = useState<boolean>(false);

  const isCaloFoodExisting = !![...meals, ...addons, ...gifted].find((food) => food.type?.includes(FoodType.caloKids));
  const allMealComponentIds = uniq(flatMap([...meals, ...addons, ...gifted], (food) => food.components?.map((comp) => comp.id)));

  useEffect(() => {
    setFetchAllComponents(false);
  }, [selectedDelivery]);

  const { isLoading: isComponentsLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/list',
      {
        limit: allMealComponentIds.length,
        filters: {
          withChildComponents: false,
          ids: allMealComponentIds,
          country: selectedDelivery.country,
          brand: selectedDelivery.brand || Brand.CALO,
          kitchen: selectedDelivery.kitchen || Kitchen.BH1
        }
      }
    ],
    getListWithParamsPost,
    {
      suspense: false,
      keepPreviousData: true,
      enabled: fetchAllComponents && allMealComponentIds.length > 0,
      onSuccess: (data) => {
        setAllMealComponents((prev) => uniqBy([...prev, ...(data.data ?? [])], 'id'));
      }
    }
  );

  const { isLoading: isCustomComponentsLoading } = useQuery<any, Error, any>(
    [
      'food-components/custom-food',
      {
        filters: {
          kitchen: selectedDelivery.kitchen || Kitchen.BH1,
          brand: selectedDelivery.brand || Brand.CALO
        }
      }
    ],
    getListWithParams,
    {
      retry: false,
      enabled: fetchAllComponents && userRoles.includes(Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD),
      onSuccess: (data) => {
        if (data) {
          setAllCustomFoodComponents((prev) => {
            return {
              ...prev,
              meal: data.mealComponents.data,
              rice: data.riceComponents.data,
              salad: data.saladComponents.data,
              sandwich: data.sandwichComponents?.data
            };
          });
          setCustomFoodComponents([...data.mealComponents.data]);
        }
      }
    }
  );

  const { isLoading: isCustomFoodsLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/custom-food',
      {
        filters: {
          country: selectedDelivery.country,
          brand: selectedDelivery.brand || Brand.CALO,
          kitchen: selectedDelivery.kitchen || Kitchen.BH1,
          foodType: FoodType.caloKids
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: true,
      enabled: fetchAllComponents && isCaloFoodExisting,
      onSuccess: (data) => {
        if (data) {
          setCaloKidsComponents(data.data);
        }
      }
    }
  );

  const findRespectiveComponents = (allComponents: FoodComponent[], meal: Food) => {
    return allComponents.filter((component) => meal.components?.map((comp) => comp.id).includes(component.id));
  };

  return (
    <>
      {isTablet || isMobile ? (
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {meals?.map((meal, index) => (
            <DeliveriesMealTableRowMobile
              key={meal.id}
              mealType={'meal'}
              menu={menu}
              menuLoading={menuLoading}
              delivery={selectedDelivery}
              refetchDelivery={refetchDelivery}
              subscription={subscription}
              meal={{ ...meal, positionIndex: index }}
              components={findRespectiveComponents(allMealComponents ?? [], meal)}
              customFoodComponents={customFoodComponents}
              allCustomFoodComponents={allCustomFoodComponents}
              caloKidsComponents={caloKidsComponents}
              isCustomComponentsLoading={isCustomComponentsLoading || isComponentsLoading || isCustomFoodsLoading}
              setCustomFoodComponents={setCustomFoodComponents}
              setFetchMenu={setFetchMenu}
              setFetchAllComponents={setFetchAllComponents}
            />
          ))}
          {addons?.map((meal, index) => (
            <DeliveriesMealTableRowMobile
              key={meal.id}
              mealType={'addon'}
              menu={menu}
              menuLoading={menuLoading}
              components={findRespectiveComponents(allMealComponents ?? [], meal)}
              delivery={selectedDelivery}
              refetchDelivery={refetchDelivery}
              subscription={subscription}
              meal={{ ...meal, positionIndex: index }}
              customFoodComponents={customFoodComponents}
              isCustomComponentsLoading={isCustomComponentsLoading || isComponentsLoading || isCustomFoodsLoading}
              allCustomFoodComponents={allCustomFoodComponents}
              caloKidsComponents={caloKidsComponents}
              setCustomFoodComponents={setCustomFoodComponents}
              setFetchMenu={setFetchMenu}
              setFetchAllComponents={setFetchAllComponents}
            />
          ))}
        </Box>
      ) : (
        <Table
          sx={{
            marginY: '4px',
            minHeight: '120px',
            tableLayout: 'fixed',
            overflow: 'auto',
            width: '100%',
            mx: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, borderRadius: '8px' }}>
            <TableRow>
              <StyledTableCell sx={{ width: '17%' }}>Name</StyledTableCell>
              <StyledTableCell sx={{ width: '5%' }}></StyledTableCell>
              <StyledTableCell sx={{ width: '12%' }} style={{ textAlign: 'center' }}>
                Portion
              </StyledTableCell>
              <StyledTableCell sx={{ width: '16%' }} style={{ textAlign: 'center' }}>
                Type
              </StyledTableCell>
              <StyledTableCell sx={{ width: '13%' }}>Macros</StyledTableCell>
              <StyledTableCell sx={{ width: '10%' }}>Tags</StyledTableCell>
              <StyledTableCell sx={{ width: '12%' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meals.length === 0 && addons.length === 0 && gifted.length === 0 ? (
              <AddMealAddonRow
                addonModalRef={addonModalRef}
                setSelectedAddType={setSelectedAddType}
                setFetchMenu={setFetchMenu}
              />
            ) : (
              <>
                {meals?.map((meal, index) => (
                  <DeliveriesMealTableRow
                    key={`${meal.id}-${index}`}
                    mealType={'meal'}
                    delivery={selectedDelivery}
                    refetchDelivery={refetchDelivery}
                    subscription={subscription}
                    menu={menu}
                    menuLoading={menuLoading}
                    meal={{ ...meal, positionIndex: index }}
                    components={findRespectiveComponents(allMealComponents ?? [], meal)}
                    customFoodComponents={customFoodComponents}
                    isCustomComponentsLoading={isCustomComponentsLoading || isComponentsLoading || isCustomFoodsLoading}
                    allCustomFoodComponents={allCustomFoodComponents}
                    caloKidsComponents={caloKidsComponents}
                    setCustomFoodComponents={setCustomFoodComponents}
                    setFetchMenu={setFetchMenu}
                    setFetchAllComponents={setFetchAllComponents}
                  />
                ))}
                {addons?.map((meal, index) => (
                  <DeliveriesMealTableRow
                    key={meal.id}
                    subscription={subscription}
                    menu={menu}
                    menuLoading={menuLoading}
                    mealType={'addon'}
                    delivery={selectedDelivery}
                    refetchDelivery={refetchDelivery}
                    meal={{ ...meal, positionIndex: index }}
                    components={findRespectiveComponents(allMealComponents ?? [], meal)}
                    customFoodComponents={customFoodComponents}
                    allCustomFoodComponents={allCustomFoodComponents}
                    caloKidsComponents={caloKidsComponents}
                    isCustomComponentsLoading={isCustomComponentsLoading || isComponentsLoading || isCustomFoodsLoading}
                    setCustomFoodComponents={setCustomFoodComponents}
                    setFetchMenu={setFetchMenu}
                    setFetchAllComponents={setFetchAllComponents}
                  />
                ))}
                {gifted?.map((gifted, index) => (
                  <DeliveriesMealTableRow
                    key={gifted.id || ''}
                    subscription={subscription}
                    menu={menu}
                    menuLoading={menuLoading}
                    mealType={'giftItem'}
                    refetchDelivery={refetchDelivery}
                    delivery={selectedDelivery}
                    meal={{ ...gifted, positionIndex: index }}
                    components={findRespectiveComponents(allMealComponents ?? [], gifted)}
                    customFoodComponents={customFoodComponents}
                    allCustomFoodComponents={allCustomFoodComponents}
                    caloKidsComponents={caloKidsComponents}
                    isCustomComponentsLoading={isCustomComponentsLoading || isComponentsLoading || isCustomFoodsLoading}
                    setCustomFoodComponents={setCustomFoodComponents}
                    setFetchMenu={setFetchMenu}
                    setFetchAllComponents={setFetchAllComponents}
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      )}
      <TotalMacrosBox selectedDelivery={selectedDelivery} />
      <TargetMacrosBox selectedDelivery={selectedDelivery} />
      {userRoles.includes(Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS) &&
        (subscription.macrosType === MacrosType.custom || subscription.plan.dietType === DietType.customMacros) && (
          <DeficitMacrosBox selectedDelivery={selectedDelivery} />
        )}

      <AddonPopup
        menu={menu}
        addonModalRef={addonModalRef}
        selectedDelivery={selectedDelivery}
        selectedAddType={selectedAddType}
        refetchDelivery={refetchDelivery}
        menuLoading={menuLoading}
      />
    </>
  );
};
export default DeliveriesMealTable;
