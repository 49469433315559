import { getRecordWithParams } from 'actions/index';
import { useCustomQuery, UseCustomQueryOptions } from './base';
import { QueryFunctionContext } from 'react-query';

export const useGetMenuData = (
  deliveryDay: string | undefined,
  params: Record<string, string | number | undefined>,
  options?: UseCustomQueryOptions<any, Error, any>
) => {
  return useCustomQuery(
    ['/menu', deliveryDay, params],
    (context: QueryFunctionContext) => getRecordWithParams(context) as Promise<any>,
    {
      enabled: !!deliveryDay,
      ...options
    }
  );
};
