import { DeliveryTicketType, Permission } from '@calo/dashboard-types';
import { Brand, Delivery, DeliveryAddress, Kitchen } from '@calo/types';
import { createDeliveryTicket } from 'actions/mealSwap';
import { Button } from 'components';
import { DeliveryTime, OperationType } from 'lib/enums';
import { getAddressForDeliveryTicket } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { DeliveryTicketData } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
interface CreateLogisticRequestProps {
  selectedDate: string;
  addedComment: string;
  coolerBagSelected: boolean;
  cutlerySelected: boolean;
  selectedDelivery?: Delivery;
  selectedCustomer: any;
  deliverCostManual: number;
  selectedManualMeals?: any[];
  selectedOperation: OperationType;
  selectedDeliverySlot: DeliveryTime;
  setSelectedDeliverySlot: (value: DeliveryTime) => void;
  selectedManualAddress: any;
  selectedMealSwaps: any[];
  clearData: () => void;
}

const CreateLogisticRequest = ({
  selectedOperation,
  deliverCostManual,
  selectedDate,
  selectedCustomer,
  selectedDelivery,
  coolerBagSelected,
  cutlerySelected,
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  addedComment,
  selectedManualMeals,
  selectedManualAddress,
  selectedMealSwaps,
  clearData
}: CreateLogisticRequestProps) => {
  const roles = useUserRoles();
  const { mutateAsync: createMutation } = useMutation(createDeliveryTicket);
  let kitchen: Kitchen;
  if (selectedCustomer?.kitchen) {
    kitchen = selectedCustomer?.kitchen;
  } else if (selectedDelivery?.kitchen) {
    kitchen = selectedDelivery?.kitchen;
  } else {
    kitchen = Object.values(Kitchen).find((r) => r.includes(selectedCustomer?.country) && !r.includes('000')) as Kitchen;
  }

  const handleCreateMealSwap = async () => {
    if (selectedDelivery?.day && selectedDate !== selectedDelivery.day) {
      toast.error('Delivery date does not match the selected date, please refresh the page and try again.');

      return;
    }

    const reqData: DeliveryTicketData = {
      userName: selectedCustomer.name,
      type: DeliveryTicketType.MEAL,
      deliveryId: selectedDelivery?.id,
      deliveryTime: selectedDelivery?.time || DeliveryTime.morning,
      userId: selectedCustomer.value,
      phoneNumber: selectedCustomer.phoneNumber,
      mealTicketData: selectedMealSwaps
        .filter((item) => item.oldFood && item.newFood)
        .map((item) => ({
          oldFood: {
            id: item.oldFood.innerValue,
            foodType: item.oldFood.type,
            name: item.oldFood.name,
            size: item.oldFood.size,
            slug: item.oldFood.slug,
            positionIndex: item.oldFood.positionIndex
          },
          newFood: {
            id: item.newFood.id,
            foodType: item.newFood.type,
            name: item.newFood.name,
            slug: item.newFood.slug,
            size: item.newFood.size,
            positionIndex: item.oldFood.positionIndex
          }
        })),
      comment: addedComment
    };

    await createMutation(
      {
        data: reqData,
        date: selectedDate,
        country: selectedCustomer.data.country,
        brand: selectedCustomer.data.brand || Brand.CALO,
        kitchen
      },
      {
        onSuccess: () => {
          clearData();
          toast.success('Meal swap request created successfully');
        },
        onError: (error: any) => {
          toast.error(error);
        }
      }
    );
  };
  const handleCreateOtherTicket = async () => {
    if (selectedDelivery?.day && selectedDate !== selectedDelivery.day) {
      toast.error('Delivery date does not match the selected date, please refresh the page and try again.');

      return;
    }

    const reqData: DeliveryTicketData = {
      userName: selectedCustomer.name,
      type: DeliveryTicketType.OTHER,
      deliveryId: selectedDelivery?.id,
      deliveryTime: selectedDelivery?.time || DeliveryTime.morning,
      userId: selectedCustomer.value,
      phoneNumber: selectedCustomer.phoneNumber,
      otherTicketData: {
        cutlery: cutlerySelected,
        coolerBag: coolerBagSelected
      },
      comment: addedComment
    };

    await createMutation(
      {
        data: reqData,
        date: selectedDate,
        country: selectedCustomer.data.country,
        brand: selectedCustomer.data.brand || Brand.CALO,
        kitchen
      },
      {
        onSuccess: () => {
          clearData();
          toast.success('Other Delivery Request created successfully');
        },
        onError: (error: any) => {
          toast.error(error);
        }
      }
    );
  };

  const handleCreateManualDelivery = async () => {
    const selectedAddress = selectedCustomer.data.deliveryAddresses.find((address: any) => address.id === selectedManualAddress);
    const reqData: DeliveryTicketData = {
      type: DeliveryTicketType.MANUAL,
      userName: selectedCustomer.name,
      deliveryId: selectedDelivery?.id,
      deliveryTime: selectedDeliverySlot ?? selectedDelivery?.time,
      userId: selectedCustomer.value,
      phoneNumber: selectedCustomer.phoneNumber,
      manualTicketData: {
        address: getAddressForDeliveryTicket(selectedAddress) as DeliveryAddress,
        cutlery: cutlerySelected,
        coolerBag: coolerBagSelected,
        foods: (selectedManualMeals ?? []).map((meal) => ({
          id: meal.id,
          foodType: meal.type,
          name: meal.name,
          size: meal.size,
          positionIndex: meal.positionIndex,
          slug: meal.slug
        }))
      },
      comment: addedComment
    };
    await createMutation(
      {
        data: reqData,
        date: selectedDate,
        country: selectedCustomer.data.country,
        brand: selectedCustomer.data.brand || Brand.CALO,
        kitchen: selectedCustomer?.kitchen
          ? selectedCustomer.kitchen
          : selectedDelivery?.kitchen
            ? selectedDelivery?.kitchen
            : Object.values(Kitchen).find((r) => r.includes(selectedCustomer.country) && !r.includes('000'))
      },
      {
        onSuccess: () => {
          clearData();
          setSelectedDeliverySlot(DeliveryTime.morning);
          toast.success('Manual delivery request created successfully');
        },
        onError: (error: any) => {
          toast.error(error);
        }
      }
    );
  };
  return (
    <div>
      {roles.includes(Permission.CREATE_DELIVERY_TICKET) &&
        (selectedOperation === OperationType.mealSwap ? (
          <div className="flex justify-center mt-8">
            <Button
              primary
              className="w-auto"
              content="Request Swapping"
              disabled={!selectedDate || !selectedCustomer || !selectedDelivery || selectedMealSwaps.length === 0}
              onClick={() => handleCreateMealSwap()}
            />
          </div>
        ) : selectedOperation === OperationType.manualDelivery ? (
          <div className="flex justify-center mt-8">
            <Button
              primary
              className="w-auto"
              content="Request Manual Delivery"
              onClick={() => handleCreateManualDelivery()}
              disabled={
                (!(selectedCustomer?.data.balance[selectedCustomer?.data.currency] - deliverCostManual >= 0) &&
                  !!selectedDate &&
                  !!selectedCustomer) ||
                !deliverCostManual
              }
            />
          </div>
        ) : (
          <div className="flex justify-center mt-8">
            <Button
              primary
              className="w-auto"
              content="Request Addition"
              onClick={() => handleCreateOtherTicket()}
              disabled={!selectedDate || !selectedCustomer || !selectedDelivery || !(coolerBagSelected || cutlerySelected)}
            />
          </div>
        ))}
    </div>
  );
};

export default CreateLogisticRequest;
