import { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { format } from 'date-fns/fp';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { Country, Kitchen, NewDeliveryAddress } from '@calo/types';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { multiPolygon, point } from '@turf/helpers';

import { generateRoute, updateDelivery } from 'actions';
import { AddressViewMode, DeliveryTime } from 'lib/enums';
import { AppState, Delivery, DeliveryPlan } from 'lib/interfaces';
import { AddressPicker, Input, Modal } from '../';
import { ModalRef } from '../Modal/types';
import SidebarDragComp from '../SidebarDragComp';
import SidebarAreaOptions from './SidebarAreaOptions';
import SidebarDriverOptions from './SidebarDriverOptions';

interface SidebarProps {
  isOpen: boolean;
  unassigned: Delivery[];
  assigned: any;
  deliveriesLength: number;
  handleMarkerClick: (delivery: Delivery) => void;
  updateDeliveries: (id: string, canceled: boolean) => void;
  deliveryPlans: DeliveryPlan[];
  setSelectedDeliveryPlan: (deliveryPlan: DeliveryPlan | null) => void;
  selectedDeliveryPlan: DeliveryPlan | null;
  day: string;
  time: DeliveryTime;
  setIsAreaView: (isArea: boolean) => void;
  isAreaView: boolean;
  polygonState: PolygonState;
  selectedDeliveries: Delivery[] | null;
  setSelectedDeliveries: (deliveries: Delivery[] | null) => void;
  country: Country;
}

const gettoBeDeliveredAt = (time?: string) => {
  return time ? format('hh:mm a')(new Date(time)) : '--:--';
};

const MapSidebar = ({
  country,
  setIsAreaView,
  isAreaView,
  isOpen,
  unassigned,
  assigned,
  deliveriesLength,
  handleMarkerClick,
  updateDeliveries,
  deliveryPlans,
  setSelectedDeliveryPlan,
  selectedDeliveryPlan,
  day,
  time,
  polygonState,
  selectedDeliveries,
  setSelectedDeliveries
}: SidebarProps) => {
  const users = useSelector((state: AppState) => state.user.keyedList);
  const [editMode, setEditMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string | undefined>();
  const [optionsId, setOptionsId] = useState<string | null>(null);
  const [searchDriverName, setSearchDriverName] = useState('');
  const [searchDeliveryName, setSearchDeliveryName] = useState('');
  useEffect(() => {
    setSelectedDeliveries(null);
    setSelectedDeliveryPlan(null);
  }, [day, time]);

  const filterAssignedByDriver = () => {
    if (searchDriverName.length > 0) {
      const ids = Object.values(users || {})
        .filter((i) => i.name && i.name.toLowerCase().includes(searchDriverName.toLowerCase()))
        .map((i) => i.id);
      return Object.keys(assigned).filter((id) => ids.includes(id));
    }
    return Object.keys(assigned);
  };

  const filterDeliveriesByName = () => {
    if (searchDeliveryName.length > 0) {
      return (selectedDeliveries || []).filter((i) => i.name && i.name.toLowerCase().includes(searchDeliveryName.toLowerCase()));
    }
    return selectedDeliveries || [];
  };

  useEffect(() => {
    setSelectedDeliveries(null);
    setSelectedDeliveryPlan(null);
  }, [isAreaView]);
  useEffect(() => {
    if (!selectedDeliveries) {
      setSelectedColor(undefined);
    }
  }, [selectedDeliveries]);

  // a little function to help us with reordering the result
  const reorder = (list: Delivery[] | null, startIndex: number, endIndex: number) => {
    if (list === null) {
      return null;
    } else {
      const result = [...list];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    }
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Delivery[] | null = reorder(selectedDeliveries, result.source.index, result.destination.index);
    setSelectedDeliveries(items);
    console.log('TODO handle order change');
  };

  const addressModalRef = useRef<ModalRef>();
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>();
  useEffect(() => {
    if (selectedDelivery) {
      addressModalRef.current?.open();
    } else {
      addressModalRef.current?.close();
    }
  }, [selectedDelivery]);

  const handleUpdateAddress = async (delivery: Delivery, deliveryAddress: NewDeliveryAddress) => {
    await updateDelivery({ deliveryAddress: deliveryAddress, id: delivery.id });
    setSelectedDelivery(null);
    updateDeliveries(delivery.id, false);
    if (delivery.driver) {
      generateRouteManually();
    }
  };

  const removeFromList = (deliveryID: string) => {
    if (selectedDeliveries) setSelectedDeliveries(selectedDeliveries?.filter((d) => d.id !== deliveryID));
  };

  const getActualPosition = (deliveryId: string) => {
    if (selectedDeliveryPlan && selectedDeliveryPlan.deliveredPositions) {
      const index = selectedDeliveryPlan.deliveredPositions.indexOf(deliveryId);
      if (index !== -1) {
        return (index + 1).toString();
      }
      return '--';
    }
    return '--';
  };

  const generateRouteManually = async (deliveryPlanId?: string, countryOfPlan?: Country) => {
    let id = deliveryPlanId;
    let country = countryOfPlan;
    if (selectedDeliveryPlan) {
      id = selectedDeliveryPlan.id;
      country = selectedDeliveryPlan.country;
    }
    if (!country) {
      country = Country.BH;
    }
    if (id) {
      await generateRoute(id, country);
    }
  };

  return (
    <div
      className={cx('flex justify-center bg-white h-full', {
        'w-0 hidden': !isOpen,
        'w-80': isOpen
      })}
    >
      <div className="h-screen-80 w-full px-1">
        <div className="pl-2">
          <button
            onClick={() => setIsAreaView(false)}
            className={
              isAreaView
                ? 'px-3 mr-5 rounded py-1 text-blue-500 border border-blue-500'
                : 'px-3 mr-5 rounded py-1 text-white bg-blue-500'
            }
          >
            On route
          </button>
          <button
            onClick={() => setIsAreaView(true)}
            className={
              isAreaView ? 'px-3 rounded py-1 text-white bg-blue-500' : 'px-3 rounded py-1 text-blue-500 border border-blue-500'
            }
          >
            Zones
          </button>
        </div>
        {selectedDeliveries === null ? (
          isAreaView ? (
            <div className="flex flex-col w-full overflow-y-auto items-start h-full p-2">
              {polygonState.polygons.map((poly, i) => {
                const todayIndex = new Date().getDay();
                const todayDriver = poly.drivers?.[todayIndex] || '';
                const polygon = multiPolygon([
                  [
                    [
                      ...poly.polygon
                        .getPath()
                        .getArray()
                        .map((b) => [b.lng(), b.lat()]),
                      [poly.polygon.getPath().getArray()[0].lng(), poly.polygon.getPath().getArray()[0].lat()]
                    ]
                  ]
                ]);
                const data: Delivery[] = assigned[todayDriver]?.data.filter((d: Delivery) =>
                  booleanPointInPolygon(point([d.deliveryAddress.lng, d.deliveryAddress.lat]), polygon)
                );
                if (data && data.length > 0) {
                  return (
                    <SidebarAreaOptions
                      key={i}
                      setSelectedColor={setSelectedColor}
                      setSelectedD={setSelectedDeliveries}
                      color={poly.color}
                      data={data}
                      driverName={users ? users[todayDriver]?.name : ''}
                      driverId={todayDriver}
                    />
                  );
                }
              })}
            </div>
          ) : (
            <ul className="flex flex-col w-full overflow-y-auto items-start h-full p-2">
              {unassigned.length > 0 && (
                <li className={cx('flex flex-row items-center h-12 rounded-lg text-gray-600 hover:text-gray-100 pl-3')}>
                  <button className="focus:outline-none" onClick={() => setSelectedDeliveries(unassigned)}>
                    <p>
                      Unassigned: ({unassigned.length}/{deliveriesLength})
                    </p>
                  </button>
                </li>
              )}
              <li className={cx('flex flex-row items-start w-full my-1 border border-gray-600 rounded ')}>
                <Input
                  placeholder="Search drivers"
                  className="w-full border-none p-2"
                  value={searchDriverName}
                  onChange={(e) => setSearchDriverName(e.target.value)}
                  debounce
                />
              </li>
              {filterAssignedByDriver().map((driverId) => (
                <SidebarDriverOptions
                  key={driverId}
                  driverId={driverId}
                  setSelectedD={setSelectedDeliveries}
                  data={assigned[driverId].data}
                  driverName={users && users[driverId]?.name}
                  generateRouteManually={generateRouteManually}
                  numOfDeliveries={assigned[driverId].data.length}
                  deliveryPlans={deliveryPlans}
                  setSelectedDeliveryPlan={setSelectedDeliveryPlan}
                  optionsId={optionsId}
                  setOptionsId={setOptionsId}
                />
              ))}
            </ul>
          )
        ) : (
          <div className="flex relative flex-col h-full  w-full">
            <button
              className="hover:text-white focus:outline-none py-3 self-start px-3"
              onClick={() => {
                setSelectedDeliveries(null);
                setSelectedDeliveryPlan(null);
                setOptionsId(null);
              }}
            >
              <i className="fas fa-arrow-left fa-lg"></i>
            </button>
            <button
              className={cx('hover:text-white focus:outline-none py-3 absolute right-1 self-start px-3', {
                'text-green-500': editMode
              })}
              onClick={() => {
                setEditMode(!editMode);
                setOptionsId(null);
              }}
            >
              <i className="fas fa-pencil-alt fa-lg"></i>
            </button>
            <div className="m-3 border border-gray-600 rounded">
              <Input
                placeholder="Search deliveries"
                className="w-full border-none p-2"
                value={searchDeliveryName}
                onChange={(e) => setSearchDeliveryName(e.target.value)}
                debounce
              />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-col w-full overflow-y-auto items-center"
                  >
                    {filterDeliveriesByName().map((item, index) => (
                      <SidebarDragComp
                        setSelectedDelivery={setSelectedDelivery}
                        generateRouteManually={generateRouteManually}
                        updateDeliveries={updateDeliveries}
                        editMode={editMode}
                        removeFromList={removeFromList}
                        selectedColor={selectedColor}
                        estimatedPosition={
                          selectedDeliveryPlan && selectedDeliveryPlan.routePlan[item.id]
                            ? selectedDeliveryPlan.routePlan[item.id].priority?.toString()
                            : '--'
                        }
                        index={index}
                        key={item.id}
                        item={item}
                        handleMarkerClick={handleMarkerClick}
                        optionsId={optionsId}
                        actualPosition={getActualPosition}
                        eta={
                          selectedDeliveryPlan && selectedDeliveryPlan.routePlan[item.id]
                            ? gettoBeDeliveredAt(selectedDeliveryPlan.routePlan[item.id].toBeDeliveredAt)
                            : '--:--'
                        }
                        setOptionsId={setOptionsId}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
      <Modal ref={addressModalRef} onClose={() => setSelectedDelivery(null)} isNarrow>
        <AddressPicker
          time={time}
          country={country}
          kitchen={(selectedDeliveries && selectedDeliveries[0].kitchen) || Kitchen.BH1}
          onCancel={() => setSelectedDelivery(null)}
          {...(selectedDelivery?.id
            ? {
                label: 'Update Address',
                onPick: (da) => {
                  handleUpdateAddress(selectedDelivery, da);
                },
                deliveryAddress: selectedDelivery.deliveryAddress,
                viewMode: AddressViewMode.form
              }
            : {
                label: 'Create New Address',
                onPick: () => {
                  //do nothing
                },
                viewMode: AddressViewMode.map
              })}
        />
      </Modal>
    </div>
  );
};

export default MapSidebar;
