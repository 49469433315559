import { Button, Card, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import type { NotificationCardProps } from './types';

export const ReEnableAudioCard = ({ onConfirm }: NotificationCardProps) => (
  <Card
    variant="outlined"
    sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1000,
      padding: 2,
      backgroundColor: caloTheme.palette.warning.main,
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '80%',
      maxWidth: '600px',
      textAlign: 'center'
    }}
  >
    <Typography variant="h6" sx={{ marginBottom: 2 }}>
      To continue receiving sound notifications, please tap the button below to re-enable audio.
    </Typography>
    <Button color="secondary" variant="contained" size="large" onClick={onConfirm} sx={{ color: 'white' }}>
      Re-enable Sound Notifications
    </Button>
  </Card>
);
