import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  noteText: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '-0.38px'
  },
  noSubscriptionText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    marginY: 1,
    color: caloTheme.palette.red500
  },
  addButtonText: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '-0.38px',
    cursor: 'pointer'
  },
  showAllButton: {
    mx: 'auto',
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.neutral900,
    '&:hover': {
      color: caloTheme.palette.neutral900,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  },
  confirmButton: {
    mr: 1,
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary500,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  },
  cancelButton: {
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    color: caloTheme.palette.neutral400,
    '&:hover': {
      color: caloTheme.palette.neutral400,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  },
  editButton: {
    mr: 1,
    height: '45px',
    lineHeight: '17px',
    fontWeight: 600,
    textTransform: 'capitalize',
    fontSize: '14px',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '14px 20px 14px 20px',
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      backgroundColor: caloTheme.palette.primary500,
      boxShadow: 'none'
    },
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      justifyItems: 'center',
      margin: 'auto',
      width: 'auto'
    }
  }
};

export default styles;
