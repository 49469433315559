import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { Icon as Iconify } from '@iconify/react';
import { deleteCustomFood } from 'actions/food';
import { caloTheme } from 'assets/images/theme/calo';
import IngredientList from 'components/CustomFoodPopup/CustomMealForm/Shared/IngredientList';
import { useUserRoles } from 'lib/hooks';
import { Delivery, Food, MenuFood } from 'lib/interfaces';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

interface CustomMealPickerProps {
  isLastItem: boolean;
  selectedMeal: MenuFood;
  customMeal: Food;
  delivery: Delivery;
  isMealInDelivery: boolean;
  removeMealFromList: (id: string) => void;
  onPick: (food: any) => void;
  setCustomFood: React.Dispatch<React.SetStateAction<Food | null>>;
  setCustomMealAction: (value: string) => void;
}

const CustomMealListItem = ({
  selectedMeal,
  isLastItem,
  delivery,
  customMeal,
  isMealInDelivery,
  removeMealFromList,
  onPick,
  setCustomFood,
  setCustomMealAction
}: CustomMealPickerProps) => {
  const roles = useUserRoles();

  const { mutateAsync: deleteCustomFoodMutation, isLoading: isDeleteLoading } = useMutation(deleteCustomFood);

  const isSelectedMeal = selectedMeal?.id === customMeal?.id;

  const handleDeleteMeal = async () => {
    if (isSelectedMeal) {
      return toast("Can't delete a selected meal", { type: 'error' });
    }
    if (isMealInDelivery) {
      return toast("Can't delete a meal selected in delivery", { type: 'error' });
    }
    await deleteCustomFoodMutation(
      { id: customMeal.id, userId: delivery.userId },
      {
        onSuccess: () => {
          removeMealFromList(customMeal.id);
        }
      }
    );
  };

  const isDisabled = !PermissionService.deliveryCanBeEdited(delivery) || !roles.includes(Permission.UPDATE_DELIVERY_CUSTOM_FOOD);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderBottomWidth: '1px',
        paddingY: '14px',
        borderColor: isLastItem ? 'transparent' : caloTheme.palette.neutral100
      }}
    >
      <Stack sx={{ width: '70%', paddingRight: '6px' }}>
        <Typography sx={{ fontWeight: 600 }}>{customMeal.name.en}</Typography>
        <IngredientList ingredients={customMeal.ingredients} sx={{ fontSize: '12px', fontWeight: 400 }} />
      </Stack>
      {roles.includes(Permission.DELETE_CUSTOM_FOOD) && (
        <IconButton
          onClick={handleDeleteMeal}
          disabled={isDeleteLoading || !roles.includes(Permission.DELETE_CUSTOM_FOOD)}
          sx={{
            width: '5%',
            marginTop: '5px',
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Iconify icon="ph:trash" width={18} height={18} color="black" />
        </IconButton>
      )}
      <Tooltip title="Cannot update custom meal during lock-up time" arrow disableHoverListener={!isDisabled}>
        <Stack
          sx={{
            width: '5%',
            marginTop: '5px'
          }}
        >
          <IconButton
            onClick={() => {
              setCustomMealAction('update');
              setCustomFood(customMeal);
            }}
            className="has-tooltip-danger w-full"
            disabled={isDisabled}
            sx={{
              ':hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <Iconify icon="lucide:edit-2" width="16px" height="16px" style={{ color: 'black' }} />
          </IconButton>
        </Stack>
      </Tooltip>
      <Button
        variant="contained"
        aria-label="select-customMeal"
        sx={{
          mr: 1,
          border: 1,
          width: '120px',
          height: '45px',
          fontWeight: 600,
          fontSize: '16px',
          boxShadow: 'none',
          borderRadius: '8px',
          textTransform: 'none',
          borderColor: isSelectedMeal ? caloTheme.palette.primary500 : caloTheme.palette.neutral900,
          color: isSelectedMeal ? caloTheme.palette.white : caloTheme.palette.neutral900,
          cursor: isSelectedMeal ? 'not-allowed' : 'pointer',
          backgroundColor: isSelectedMeal ? caloTheme.palette.primary500 : caloTheme.palette.white,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: isSelectedMeal ? caloTheme.palette.primary500 : caloTheme.palette.neutral100,
            color: isSelectedMeal ? caloTheme.palette.white : caloTheme.palette.neutral900
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            justifyItems: 'center',
            margin: 'auto',
            marginTop: 4,
            width: 'auto'
          }
        }}
        onClick={() => (isSelectedMeal ? null : onPick(customMeal))}
      >
        {isSelectedMeal ? 'Selected' : 'Select Meal'}
      </Button>
    </Stack>
  );
};
export default CustomMealListItem;
