import { useFormik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
  ActivityLevel,
  AddressType,
  Brand,
  Country,
  DietType,
  Gender,
  Goal,
  Kitchen,
  PaymentMethod,
  PlanType,
  SubscriptionFrequency
} from '@calo/types';
import { DeliveryTime } from 'lib/enums';
import { CreateSubscriptionReq } from 'lib/interfaces';

const Form = (onSubmit: (values: CreateSubscriptionReq) => any) =>
  useFormik<CreateSubscriptionReq>({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      brand: Brand.CALO,
      deliveryAddress: {
        building: '',
        country: Country.BH,
        lat: 26.21536,
        lng: 50.5832,
        street: '',
        type: AddressType.home,
        apartment: '',
        city: '',
        region: '',
        district: ''
      },
      macrosData: {
        activityLevel: ActivityLevel.level1,
        dob: '2000-01-01',
        gender: Gender.male,
        goal: Goal.gainWeight,
        height: 170,
        weight: 85,
        targetWeight: 85,
        weightGoal: 0.5
      },
      deliveryDays: [0, 1, 2, 3, 4],
      planType: PlanType.businessLunch,
      frequency: SubscriptionFrequency.monthly,
      deliveryTime: DeliveryTime.evening,
      startDate: '',
      dietType: DietType.balanced,
      country: Country.BH,
      kitchen: Kitchen.BH1,
      paymentMethod: PaymentMethod.benefit
    },
    validate: (values: CreateSubscriptionReq) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = true;
      }
      if (!values.email) {
        errors.email = true;
      }
      if (!values.kitchen) {
        errors.kitchen = true;
      }
      if (!values.macrosData.weight) {
        errors.weight = true;
      }
      if (!values.macrosData.height) {
        errors.height = true;
      }
      if (!values.macrosData.targetWeight) {
        errors.targetWeight = true;
      }
      if (!values.startDate) {
        errors.startDate = true;
      }

      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);

      if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
        errors.phoneNumber = true;
      }

      return errors;
    },

    onSubmit: async (values) => {
      try {
        await onSubmit({
          ...values,
          deliveryAddress: {
            ...values.deliveryAddress,
            country: values.country
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  });

export default Form;
