import { getListWithParams, getRecord } from 'actions/index';
import { useCustomQuery, UseCustomQueryOptions } from './base';
import { QueryFunctionContext } from 'react-query';
import { PaginatedDeliveries } from 'lib/interfaces';
import { format } from 'date-fns/fp';

export const useGetDeliveryDetails = (selectedDeliveryId?: string, options?: UseCustomQueryOptions<any, Error, any>) => {
  return useCustomQuery(
    ['deliveries', selectedDeliveryId],
    (context: QueryFunctionContext) => getRecord(context) as Promise<any>,
    {
      enabled: !!selectedDeliveryId,
      suspense: false,
      ...options
    }
  );
};

export const useGetSubscriptionDeliveries = (
  subscriptionId: string,
  selectedDate: Date | null,
  options?: UseCustomQueryOptions<any, Error, PaginatedDeliveries>
) => {
  const today = new Date(Date.now());

  return useCustomQuery(
    [`subscriptions/${subscriptionId}/deliveries`, { page: 0, limit: 31, day: format('yyyy-MM-dd')(selectedDate ?? today) }],
    (context: QueryFunctionContext) => getListWithParams(context) as Promise<any>,
    { enabled: !!subscriptionId && !!selectedDate, ...options }
  );
};
