import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { startCase } from 'lodash-es';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Brand } from '@calo/types';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { Subscription } from 'lib/interfaces';

interface SubscriptionListMobileProps {
  subscription: Subscription;
}
const SubscriptionListMobile = ({ subscription }: SubscriptionListMobileProps) => {
  const roles = useUserRoles();
  return (
    <Card key={subscription.id} sx={{ width: 'full', mb: 2 }}>
      <CardHeader
        title={
          roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
            <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
              <Typography>
                <Link to={Routes.subscription.replace(':id', subscription.id)}>{subscription.name}</Link>
              </Typography>
              <Typography sx={{ width: subscription.brand === Brand.CALO ? '100px' : 'auto' }}>
                {subscription.brand === Brand.CALO ? (
                  <Icon name="calo" size={6} className="w-full" />
                ) : (
                  <Icon name="mealo" size={16} className="w-auto -mb-12 -mr-4" />
                )}
              </Typography>
            </Stack>
          ) : (
            <Stack sx={{ width: 'full', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
              <Typography>{subscription.name}</Typography>
              <Typography sx={{ width: subscription.brand === Brand.CALO ? '100px' : 'auto' }}>
                {subscription.brand === Brand.CALO ? (
                  <Icon name="calo" size={6} className="w-full" />
                ) : (
                  <Icon name="mealo" size={16} className="w-auto -mb-12 -mr-4" />
                )}
              </Typography>
            </Stack>
          )
        }
      />
      <CardContent>
        <Stack>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            Phone Number: {subscription.phoneNumber}
          </Typography>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            Email: {subscription.email}
          </Typography>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            Balance: {subscription.pendingAmount[subscription.currency]} {subscription.currency}
          </Typography>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            First Day: {subscription.startedAt || '---'}
          </Typography>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            Last Day: {subscription.lastDeliveredDate || '---'}
          </Typography>
          <Typography sx={{ borderBottom: 1, borderColor: caloTheme.palette.neutral100, my: 1, width: 'full' }}>
            Frequency: {startCase(subscription.plan.frequency)}
          </Typography>
          <Typography sx={{ my: 1, width: 'full' }}>Kitchen: {subscription.kitchen}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default SubscriptionListMobile;
