import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState, PerksFilters } from 'lib/interfaces';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Country } from '@calo/types';

import {
  getAvailableMonths,
  getLastAvailableMonth,
  getListOfMonthNames,
  getNextPerkMonthAndYear,
  getYearsArray
} from './helpers';

interface SettingsProps {
  onFilter: (values: PerksFilters) => any;
  filters: PerksFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const dispatch = useDispatch();
  const userKitchens: string[] = useUserKitchens();
  const { year } = getNextPerkMonthAndYear();
  const years = getYearsArray(year);

  const allMonths = getListOfMonthNames();
  const availableMonthsMemo = useMemo(() => {
    return getAvailableMonths(filters.year);
  }, [filters.year]);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Month"
          value={filters.month}
          onChange={(data) => onFilter({ ...filters, month: +data.target.value })}
          options={availableMonthsMemo.map((m) => ({
            value: allMonths.indexOf(m) + 1,
            label: m
          }))}
        />
        <Select2
          label="Year"
          value={filters.year}
          onChange={(data) =>
            onFilter({ ...filters, year: +data.target.value, month: getLastAvailableMonth(+data.target.value) })
          }
          options={years.map((y) => ({
            value: y,
            label: y as unknown as string
          }))}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
