import { Brand, Country, HealthConditionNote, HealthConditions, Kitchen } from '@calo/types';
import { Box, Card, Stack, Typography } from '@mui/material';
import { quickSearch } from 'actions';
import { Autocomplete } from 'components';
import { customStyles2 } from 'lib/componentStyles';
import { Delivery } from 'lib/interfaces';
import { groupBy, map, orderBy } from 'lodash-es';
import { useState } from 'react';
import DeliveriesCard from './DeliveriesCard';
import FoodRestrictions from './FoodRestrictions';
import HealthConditionNotes from './HealthConditionNotes';
import UserInformation from './UserInformation';
import UserPlanDetails from './UserPlanDetails';
import styles from './styles';
import { useGetDeliveryDetails } from 'hooks/actions/delivery';
import { useGetFoodPreferenceData } from 'hooks/actions/food';

const Nutritionist = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [allCustomerNotes, setAllCustomerNotes] = useState<HealthConditionNote[]>([]);
  const [healthConditionsData, setHealthConditionData] = useState<HealthConditions[]>([]);
  const [allergicIngredients, setAllergicIngredients] = useState<any[]>([]);
  const [favMealIds, setFavMealIds] = useState<string[]>([]);
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery>();

  const {
    data: selDel,
    refetch: delRefetch,
    isLoading: selectedDeliveryLoading
  } = useGetDeliveryDetails(selectedDelivery?.id, {
    suspense: false,
    enabled: !!selectedDelivery,
    onSuccess: (data: Delivery) => {
      setSelectedDelivery(data);
    }
  });

  const { data: foodPref } = useGetFoodPreferenceData(selectedCustomer?.data?.id, {
    suspense: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      setAllCustomerNotes(data?.healthConditions?.notes || []);
      setHealthConditionData(data?.healthConditions?.healthConditions || []);
      setAllergicIngredients(data?.ingredientsToAvoid || []);
      setFavMealIds(data?.favouriteFood || []);
    }
  });

  const handleSearchUserSelect = (data) => {
    setSelectedCustomer(data);
    setAllCustomerNotes([]);
    setHealthConditionData([]);
    setAllergicIngredients([]);
    setFavMealIds([]);
  };

  return (
    <Box sx={{ height: 'calc(100vh - 128px)' }}>
      <Card variant="outlined" sx={{ ...styles.AutoCompleteCard }}>
        <Box display={'flex'} flexDirection={'column'}>
          <Stack style={{ paddingBottom: '12px' }}>
            <Typography style={{ ...styles.chooseCustomerText }}>Choose Customer</Typography>
          </Stack>
          <Stack style={{ paddingBottom: '4px' }}>
            <Autocomplete
              onSearch={(text) =>
                quickSearch({
                  limit: 10,
                  query: text,
                  page: 1
                })
              }
              transformer={(data: any[]) =>
                map(groupBy(data, 'type'), (group, key) => ({
                  label: key,
                  options: group.map(
                    (row: any) =>
                      row.type === 'subscription' && {
                        value: row.id,
                        type: row.type,
                        data: row,
                        phoneNumber: row.phoneNumber.replace('+', ''),
                        name: row.name,
                        label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}, ${row.country || Country.BH}, ${row.kitchen || Kitchen.BH1}`
                      }
                  )
                }))
              }
              customStyles={customStyles2}
              placeHolder={true}
              placeHolderValue={selectedCustomer ? selectedCustomer.label : 'Search'}
              onPick={(data) => handleSearchUserSelect(data)}
            />
          </Stack>
        </Box>
      </Card>
      {selectedCustomer ? (
        <Box display={'flex'} flexDirection={'row'} style={{ paddingTop: '12px', justifyContent: 'space-between' }}>
          <Stack display={'flex'} flexDirection={'column'} width={'30%'} style={{ marginBottom: 12 }}>
            {selectedCustomer && (
              <>
                <Card variant="outlined" sx={{ ...styles.cards, mt: '1px' }}>
                  <UserPlanDetails selectedCustomer={selectedCustomer} />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <UserInformation selectedCustomer={selectedCustomer} />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <FoodRestrictions
                    foodPref={foodPref}
                    favMealIds={favMealIds}
                    selectedCustomer={selectedCustomer}
                    customerNotes={allCustomerNotes}
                    healthConditionsData={healthConditionsData}
                    setHealthConditionData={setHealthConditionData}
                    allergicIngredients={allergicIngredients}
                    setAllergicIngredients={setAllergicIngredients}
                    selectedDelivery={selectedDelivery}
                  />
                </Card>
                <Card variant="outlined" sx={{ ...styles.cards }}>
                  <HealthConditionNotes
                    foodPref={foodPref}
                    favMealIds={favMealIds}
                    selectedCustomer={selectedCustomer}
                    allCustomerNotes={orderBy(allCustomerNotes, ['createdAt'], ['desc'])}
                    setAllCustomerNotes={setAllCustomerNotes}
                    ingredientsToAvoid={allergicIngredients}
                    healthConditions={healthConditionsData}
                  />
                </Card>
              </>
            )}
          </Stack>
          <Stack display={'flex'} flexDirection={'column'} width={'68%'}>
            <Card variant="outlined" sx={{ ...styles.customerPlanCard }}>
              <DeliveriesCard
                favMealIds={favMealIds}
                setFavMealIds={setFavMealIds}
                selectedCustomer={selectedCustomer}
                selectedDelivery={selDel}
                setSelectedDelivery={setSelectedDelivery}
                delRefetch={delRefetch}
                selectedDeliveryLoading={selectedDeliveryLoading}
              />
            </Card>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ flexDirection: 'row', m: 'auto' }}>
          <Typography sx={{ ...styles.noUserText }}>Select Customer</Typography>
        </Box>
      )}
    </Box>
  );
};
export default Nutritionist;
