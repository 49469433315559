import { useRef, useState } from 'react';

import { isNil, omitBy } from 'lodash-es';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

import { AddressService } from '@calo/services';
import { Country, DeliveryAddress, Kitchen, NewDeliveryAddress } from '@calo/types';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { updateBussinessAddress } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { AddressPickerMUI, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { AddressViewMode, DeliveryTime } from 'lib/enums';
import { BusinessAccount } from 'lib/interfaces';

interface DeliveryAddressCardProps {
  account: BusinessAccount;
  country: Country;
  kitchen: Kitchen;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}
const DeliveryAddressCard = ({ account, country, kitchen, refetch }: DeliveryAddressCardProps) => {
  const [selectedAddress, setSelectedAddress] = useState<Partial<DeliveryAddress> | null>();
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);

  const { mutateAsync: updateAddressMutation } = useMutation(updateBussinessAddress);

  const addressDialogRef = useRef<ModalRef>();
  const driverNotesPopUpRef = useRef<ModalRef>();

  // const handleNewAddress = async (deliveryAddress: NewDeliveryAddress) => {
  //   console.log(deliveryAddress, 'deliveryAddress')
  //   const deliveryAddressCountry = { ...deliveryAddress, country: country, kitchen }
  //   console.log(deliveryAddressCountry, 'deliveryAddressCountry')

  //   // await updateAddressMutation({
  //   //   ...omitBy(deliveryAddressCountry, isNil) as NewDeliveryAddress,
  //   //   accId: account.id
  //   // });

  //   setSelectedAddress(null);
  //   addressDialogRef.current?.close()
  // };

  const handleUpdateAddress = async (id: string, deliveryAddress: Partial<NewDeliveryAddress>, kitchen: Kitchen) => {
    await updateAddressMutation({
      ...(omitBy({ ...deliveryAddress, isDefault: true, kitchen }, isNil) as NewDeliveryAddress),
      daid: id
    });
    setSelectedAddress(null);
    addressDialogRef.current?.close();
    refetch();
  };

  // const makeAddressDefault = (id: string) => updateAddressMutation({
  //   accId: account.id
  //   daid: id,
  //   default: true
  // });

  const StyledTableCellHeader = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    }
  }));

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.root}`]: {
      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
      },
      '&:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
      }
    }
  }));

  return (
    <>
      <Box sx={{ display: 'flex', direction: 'row', width: 'full', justifyContent: 'space-between', padding: '8px' }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '19px',
            lineHeight: '23px',
            my: 'auto',
            mx: 2,
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Delivery Addresses
        </Typography>
        {/* <Button
          variant="outlined"
          aria-label="add-address-button"
          sx={{
            float: 'right',
            margin: '4px',
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            padding: '12px 20px 12px 20px',
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.primary500,
            '&:hover': {
              borderColor: caloTheme.palette.primary600
            }
          }}
          startIcon={'+'}
          disabled
          onClick={() => {
            setSelectedAddress({})
            addressDialogRef.current?.open()
          }}
        >
          Add Address
        </Button> */}
      </Box>
      <Box sx={{ width: 'full' }}>
        <TableContainer>
          <Table sx={{ width: '97%', marginX: 'auto', mt: '4px', mb: '8px' }}>
            <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, color: 'black' }}>
              <TableRow>
                <StyledTableCellHeader style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                  Address
                </StyledTableCellHeader>
                <StyledTableCellHeader align="right">Notes</StyledTableCellHeader>
                <StyledTableCellHeader align="right">Driver Notes</StyledTableCellHeader>
                <StyledTableCellHeader align="right"></StyledTableCellHeader>
                <StyledTableCellHeader align="right"></StyledTableCellHeader>
                <StyledTableCellHeader
                  style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
                ></StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 0 }}>
              {account.deliveryAddresses.map((deliveryAddress: any) => (
                <>
                  <TableRow
                    key={`${deliveryAddress.lat} - ${deliveryAddress.lng}`}
                    sx={{
                      borderTopWidth: '4px',
                      borderBottomWidth: '2px solid',
                      '&:first-child': { borderTopWidth: '4px', borderTopColor: 'white' },
                      backgroundColor: deliveryAddress.default ? caloTheme.palette.primary100 : caloTheme.palette.white,
                      borderTopColor: 'white',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      borderBottomColor: caloTheme.palette.neutral100
                    }}
                  >
                    <StyledTableCell>{AddressService.display(deliveryAddress)}</StyledTableCell>
                    <StyledTableCell align="right">
                      {deliveryAddress.notes ? (
                        <Icon
                          name={'notes'}
                          className="cursor-pointer"
                          size={6}
                          onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            setIsNoteOpen(!isNoteOpen);
                          }}
                        />
                      ) : (
                        '---'
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right" key={`${deliveryAddress.lat}`}>
                      {deliveryAddress.driverNote ? (
                        <Icon
                          name={'notes'}
                          className="cursor-pointer"
                          size={6}
                          onClick={() => {
                            setSelectedAddress(deliveryAddress);
                            driverNotesPopUpRef.current?.open();
                          }}
                        />
                      ) : (
                        '---'
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Icon
                        name={'edit'}
                        size={5}
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedAddress(deliveryAddress);
                          addressDialogRef.current?.open();
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {/* {!deliveryAddress.default && (
                        <Icon name={'trash'} size={5} className='cursor-pointer' onClick={() => deleteAddress(deliveryAddress.id)} />
                      )} */}
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* {!deliveryAddress.default && (
                        // <Icon name={'check'} size={6} className='cursor-pointer' onClick={() => makeAddressDefault(deliveryAddress.id)} />
                      )} */}
                    </StyledTableCell>
                  </TableRow>
                  {isNoteOpen && selectedAddress?.id === deliveryAddress.id && (
                    <TableRow key={deliveryAddress.id}>
                      <StyledTableCell
                        colSpan={6}
                        sx={{
                          familyFont: caloTheme.typography.fontFamily,
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '14px',
                          color: caloTheme.palette.neutral900,
                          backgroundColor: caloTheme.palette.secondaryBlue50
                        }}
                      >
                        {selectedAddress?.notes}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Popup fullWidth ref={addressDialogRef} onClose={() => addressDialogRef.current?.close()}>
        <AddressPickerMUI
          onCancel={() => setSelectedAddress(null)}
          country={country}
          time={account.deliveryTime || DeliveryTime.morning}
          label={selectedAddress?.id ? 'Update Address' : 'Create New Address'}
          {...(selectedAddress?.id
            ? {
                onPick: (da) => handleUpdateAddress(selectedAddress.id!, da, kitchen),
                deliveryAddress: selectedAddress,
                viewMode: AddressViewMode.form
              }
            : {
                onPick: () => console.log('check me later'),
                viewMode: AddressViewMode.map
              })}
        />
      </Popup>

      <Popup title="Driver Note" ref={driverNotesPopUpRef} onClose={() => driverNotesPopUpRef.current?.close()}>
        <Stack display={'flex'} flexDirection={'row'}>
          <Typography
            display={'flex'}
            flexDirection={'row'}
            sx={{
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              color: caloTheme.palette.neutral900
            }}
          >
            {selectedAddress?.driverNote}
          </Typography>
        </Stack>
      </Popup>
    </>
  );
};
export default DeliveryAddressCard;
