import { DeliveryStatus, PaymentMethod } from '@calo/types';
import cx from 'classnames';
import { compact } from 'lodash-es';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { PhoneNumber } from 'components';
import { DeliveryTime, Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import { AddressService } from 'services';

interface DeliveryRowProps {
  delivery: Delivery;
  // handleDelivering: () => Promise<void>;
  // selected: boolean;
  // onToggle: (delivery: Delivery) => void;
}

const DeliveryRow = ({ delivery }: DeliveryRowProps) => {
  const roles = useUserRoles();
  return (
    <div className="card">
      <header className="card-header">
        <div className="card-header-icon">
          {delivery.paymentMethod === PaymentMethod.cash && (
            <span className="fa-stack" title={delivery.status === DeliveryStatus.upcoming ? 'Paid by cash' : 'Not paid (cash)'}>
              <i className="fas fa-money-bill fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.paymentMethod === PaymentMethod.cc && (
            <span
              className="fa-stack"
              title={delivery.status === DeliveryStatus.upcoming ? 'Paid by credit card' : 'Not paid (credit card)'}
            >
              <i className="fas fa-credit-card fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.paymentMethod === PaymentMethod.benefit && (
            <span
              className="fa-stack"
              title={delivery.status === DeliveryStatus.upcoming ? 'Paid by Benefit' : 'Not paid (Benefit)'}
            >
              <i className="far fa-credit-card fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.deliveryAddress.lat && delivery.deliveryAddress.lng && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/dir/?api=1&destination=${delivery.deliveryAddress.lat},${delivery.deliveryAddress.lng}&dir_action=navigate&travelmode=driving`}
            >
              <i className="fas fa-map-marked-alt"></i>
            </a>
          )}
          {roles.includes(Permission.VIEW_DELIVERY) && (
            <Link to={Routes.delivery.replace(':id', delivery.id)} className="card-header-icon" aria-label="edit">
              <i className="fas fa-edit" title="edit" />
            </Link>
          )}
        </div>
      </header>
      <div className="card-content" style={{ padding: '0.5rem' }}>
        <div className="content">
          <p>
            <strong>Address</strong>: {AddressService.display(delivery.deliveryAddress)}
          </p>
          <p>
            <strong>Notes</strong>: {compact([delivery.deliveryAddress.notes, delivery.name]).join(', ')},{' '}
            <PhoneNumber>{delivery.phoneNumber}</PhoneNumber>
          </p>
          {delivery.pendingAmount?.[delivery.currency] > 0 && (
            <p>
              <strong>Pending amount</strong>: {delivery.pendingAmount[delivery.currency]}
              {delivery.currency}
            </p>
          )}
          <span
            className={cx('tag', {
              'is-dark': delivery.time === DeliveryTime.evening,
              'is-light': delivery.time !== DeliveryTime.evening
            })}
          >
            {delivery.time || DeliveryTime.morning}
          </span>
        </div>
      </div>
    </div>
  );
};
export default DeliveryRow;
