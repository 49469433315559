import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  macrosTitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 4,
    marginTop: 4
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '-0.38px'
  },
  noSubscriptionText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    marginY: 1,
    color: caloTheme.palette.red500
  }
};
