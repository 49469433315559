import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { Suspense } from 'react';
import { Container, createRoot } from 'react-dom/client';
import App from './app';
import { components, customTheme, formFields } from './components/AmplifySignIn';
import './theme/index.scss';
import './theme/main.min.css';

const domNode = document.getElementById('root');
const root = createRoot(domNode as Container);

root.render(
  <ThemeProvider theme={customTheme}>
    <Authenticator socialProviders={['google']} formFields={formFields} components={components} hideSignUp>
      <Suspense fallback={<section className="element is-loading" />}>
        <App />
      </Suspense>
    </Authenticator>
  </ThemeProvider>
);
