import { removePerksEmployee } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import { PerksStatus, Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { EmployeePerks, EmployeePerksInfo, PerksFilters } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { getNextPerkMonthAndYear } from 'views/EmployeePerks/Settings/helpers';

import { Permission } from '@calo/dashboard-types';
import { Icon } from '@iconify/react';
import { IconButton, TableCell, TableRow } from '@mui/material';

import PerksIcon from './StatusIcon';

interface EmployeePerksRowProps {
  employee: EmployeePerksInfo;
  filters: PerksFilters;
  setEmployeePerks: (employeePerks: EmployeePerks) => void;
  status: PerksStatus;
}

const EmployeePerksRow = ({ employee, filters, setEmployeePerks, status }: EmployeePerksRowProps) => {
  const { year: nextPerkYear, month: nextPerkMonth } = getNextPerkMonthAndYear();
  const userRoles = useUserRoles();
  const { mutateAsync: removeEmployeeMutation } = useMutation(removePerksEmployee);
  const handleRemovingEmployee = async (employeeId: string) => {
    await removeEmployeeMutation(
      {
        country: filters.country,
        month: filters.month,
        year: filters.year,
        employeeId
      },
      {
        onSuccess(data) {
          setEmployeePerks(data.data);
        }
      }
    );
  };
  const isDisabled =
    !userRoles.includes(Permission.UPDATE_EMPLOYEE_PERKS) ||
    status === PerksStatus.done ||
    filters.year !== nextPerkYear ||
    filters.month !== nextPerkMonth;

  return (
    <TableRow
      key={employee.id}
      sx={{
        borderBottom: '1px solid' + caloTheme.palette.neutral100,
        '&:last-child': {
          borderBottom: 'none'
        }
      }}
    >
      <TableCell sx={{ fontSize: '14px', paddingY: 1 }}>
        {userRoles.includes(Permission.VIEW_SUBSCRIPTION) || userRoles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
          <Link to={Routes.subscription.replace(':id', employee.id)}>{startCase(employee.name)}</Link>
        ) : (
          startCase(employee.name)
        )}
      </TableCell>
      <TableCell sx={{ fontSize: '14px', paddingY: 1, paddingLeft: 3 }}>{employee.amount}</TableCell>
      <TableCell sx={{ fontSize: '14px', paddingY: 1, paddingLeft: 3 }}>{employee.currency}</TableCell>
      <TableCell sx={{ fontSize: '14px', paddingY: 1, paddingLeft: 3 }}>{employee.usedBalance}</TableCell>
      <TableCell sx={{ fontSize: '14px', paddingY: 1 }}>
        <PerksIcon status={employee.status} />
      </TableCell>
      <TableCell sx={{ fontSize: '14px', paddingY: 1, paddingLeft: 1 }}>
        <IconButton disabled={isDisabled} onClick={() => handleRemovingEmployee(employee.id)}>
          <Icon
            icon="lucide:trash"
            width={35}
            height={35}
            style={{
              color: isDisabled ? caloTheme.palette.neutral500 : caloTheme.palette.neutral900
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default EmployeePerksRow;
