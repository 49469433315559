import { Brand, FoodActionType, FoodType, Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { updateFoodPreference } from 'actions';
import { CaloLoader, MealTotalInformationSection } from 'components';
import { format } from 'date-fns/fp';
import {
  CustomComponentsByCategory,
  Delivery,
  DetailedSubscription,
  FoodComponent,
  MealPlanFoodState,
  Menu,
  PaginatedDeliveries
} from 'lib/interfaces';
import handleRenderDay, { DeliveryDayStatusProps } from 'lib/renderDayStyle';
import { compact, flattenDeep, uniq, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import DeliveryMealCard from './DeliveryMealCard';
import { useGetDeliveryDetails, useGetSubscriptionDeliveries } from 'hooks/actions/delivery';
import { useGetSubscriptionData } from 'hooks/actions/subscription';
import { useGetMenuData } from 'hooks/actions/menu';
import { useGetFoodPreferenceData } from 'hooks/actions/food';
import { useGetCustomFoodComponents, useGetFoodComponents } from 'hooks/actions/foodComponent';
import { INITIAL_CUSTOM_COMPONENT_STATE, INITIAL_DELIVERY_DAY_STATUS_STATE, INITIAL_DELIVERY_FOOD_STATE } from 'lib/constants';
import { calculateDeliveryDayStatus } from 'lib/helpers/calculateDeliveryDayStatus';
import { categorizeFoodIntoMealPlans } from 'lib/helpers/categorizeFoodIntoMealPlans';

interface DeliveriesCardProps {
  favMealIds: string[];
  selectedCustomer: { value: string };
  setFavMealIds: (val: string[]) => void;
  selectedDelivery: Delivery | undefined;
  setSelectedDelivery: React.Dispatch<React.SetStateAction<Delivery | undefined>>;
  delRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  selectedDeliveryLoading: boolean;
}

const DeliveriesCard = ({
  favMealIds,
  setFavMealIds,
  selectedCustomer,
  selectedDelivery,
  setSelectedDelivery,
  delRefetch,
  selectedDeliveryLoading
}: DeliveriesCardProps) => {
  const today = new Date(Date.now());
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [foodComponentData, setFoodComponentsData] = useState<any[]>([]);
  const [childFoodComponentsData, setChildFoodComponentsData] = useState<any[]>([]);
  const [customFoodComponents, setCustomFoodComponents] = useState<FoodComponent[]>([]);
  const [allCustomFoodComponents, setAllCustomFoodComponents] = useState<CustomComponentsByCategory>({
    ...INITIAL_CUSTOM_COMPONENT_STATE
  });
  const childComponentIdsRef = useRef<string[]>([]);
  const [selectedDeliveryFood, setSelectedDeliveryFood] = useState<MealPlanFoodState>({ ...INITIAL_DELIVERY_FOOD_STATE });
  const [deliveryDayStatus, setDeliveryDayStatus] = useState<DeliveryDayStatusProps>({ ...INITIAL_DELIVERY_DAY_STATUS_STATE });

  const { mutateAsync: updateFavMeal } = useMutation(updateFoodPreference);

  const { isLoading: delLoading } = useGetDeliveryDetails(selectedDelivery?.id, {
    onSuccess: (data: Delivery) => {
      const mealPlans = categorizeFoodIntoMealPlans(data);
      setSelectedDeliveryFood(mealPlans);
    }
  });

  const {
    data: del,
    isLoading: subscriptionDeliveriesLoading,
    refetch: subscriptionDeliveriesRefetch
  } = useGetSubscriptionDeliveries(selectedCustomer.value, selectedDate, {
    suspense: false,
    keepPreviousData: false,
    onSuccess: (data) => {
      const updatedDeliveryDayStatus = calculateDeliveryDayStatus(data);
      setDeliveryDayStatus(updatedDeliveryDayStatus);
    }
  });
  const subscriptionDeliveries = del as PaginatedDeliveries;

  const { data: subscriptionData } = useGetSubscriptionData(selectedCustomer.value, {
    suspense: false
  });
  const subscription = subscriptionData as DetailedSubscription;

  const { data, isLoading: menuLoading } = useGetMenuData(
    selectedDelivery?.day,
    {
      brand: Brand.CALO,
      kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
      userId: selectedDelivery?.userId
    },
    { retry: false }
  );
  const menu = data as Menu;

  const {
    data: foodPref,
    isLoading: foodPrefLoading,
    refetch: foodPrefRef
  } = useGetFoodPreferenceData(selectedCustomer.value, {
    suspense: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      setFavMealIds(data?.favouriteFood || []);
    }
  });

  const deliveryFoodComponentIds = useMemo(() => {
    return uniq(selectedDelivery?.food?.flatMap((item) => item.components?.map((component) => component.id))) || [];
  }, [selectedDelivery?.food]);

  const menuFoodComponentIds = useMemo(() => {
    return uniq(menu?.food?.flatMap((item) => item.components?.map((component) => component.id))) || [];
  }, [menu]);

  const giftedMealComponentIds = useMemo(() => {
    return (
      selectedDelivery?.giftedItems?.meal?.flatMap((meal: any) =>
        meal.components?.map((component: FoodComponent) => component.id)
      ) || []
    );
  }, [selectedDelivery?.giftedItems]);

  const swappedComponentIds = useMemo(() => {
    return (
      uniq(
        foodPref?.mealsCustomization
          ?.filter((customizeAction: any) => customizeAction.action === FoodActionType.swap)
          .map((swapAction: any) => swapAction.swappedWithId)
      ) || []
    );
  }, [foodPref]);

  const chunkedAllData = useMemo(() => {
    const combinedData = [
      ...deliveryFoodComponentIds,
      ...menuFoodComponentIds,
      ...giftedMealComponentIds,
      ...swappedComponentIds
    ];
    return compact(uniq(combinedData)); // Compact and deduplicate
  }, [deliveryFoodComponentIds, menuFoodComponentIds, giftedMealComponentIds, swappedComponentIds]);

  const { data: fcd, isLoading: LoadingComponents } = useGetFoodComponents(
    {
      page: 0,
      filters: {
        kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
        brand: Brand.CALO,
        ids: chunkedAllData
      },
      limit: chunkedAllData.length
    },
    {
      suspense: false,
      enabled: !!selectedDelivery && !!menu && !!foodPref && chunkedAllData.length > 0,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.data) {
          const childComponentsIds = flattenDeep(
            data.data.map((comp: any) => comp.childComponents?.map((childData: any) => childData.id))
          );
          childComponentIdsRef.current = compact(uniq(childComponentsIds)) || [];
          setFoodComponentsData((prev) => uniqBy([...prev, ...data.data], 'id'));
        }
      }
    }
  );

  const { isLoading: loadingCustomComponents } = useGetCustomFoodComponents(
    {
      filters: {
        kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
        brand: Brand.CALO
      }
    },
    {
      retry: false,
      onSuccess: (data) => {
        if (data) {
          setAllCustomFoodComponents((prev) => {
            return {
              ...prev,
              meal: data.mealComponents.data,
              rice: data.riceComponents.data,
              salad: data.saladComponents.data,
              sandwich: data.sandwichComponents.data
            };
          });
          if (data.data) {
            setCustomFoodComponents([...data.data] || []);
          }
        }
      }
    }
  );

  const { data: caloKidsComponents, isLoading: loadingCustomCaloKidsComponents } = useGetCustomFoodComponents(
    {
      filters: {
        country: selectedDelivery?.country,
        brand: Brand.CALO,
        kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
        foodType: FoodType.caloKids
      },
      limit: 100
    },
    {
      suspense: false
    }
  );

  const { isLoading: LoadingChildComponents } = useGetFoodComponents(
    {
      page: 0,
      filters: {
        kitchen: selectedDelivery?.kitchen || Kitchen.BH1,
        brand: Brand.CALO,
        ids: childComponentIdsRef.current
      },
      limit: childComponentIdsRef.current.length
    },
    {
      suspense: false,
      enabled: !!fcd && childComponentIdsRef.current.length > 0,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.data) {
          setChildFoodComponentsData((prev) => uniqBy([...prev, ...data.data], 'id'));
        }
      }
    }
  );

  useEffect(() => {
    setSelectedDelivery(undefined);
    setSelectedDeliveryFood(JSON.parse(JSON.stringify(INITIAL_DELIVERY_FOOD_STATE)));
    const formattedDate = selectedDate ? format('yyyy-MM-dd')(selectedDate) : null;

    const allDeliveryData = selectedDate && subscriptionDeliveries?.data.find((del) => del.day === formattedDate);
    if (allDeliveryData) {
      setSelectedDelivery(allDeliveryData);
      const mealPlans = categorizeFoodIntoMealPlans(allDeliveryData);
      setSelectedDeliveryFood(mealPlans);
    }
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
  }, [subscriptionDeliveries, selectedDate, subscription, selectedDelivery?.food]);

  useEffect(() => {
    setFoodComponentsData([]);
    setChildFoodComponentsData([]);
  }, [selectedDate]);

  const handleUpdateSubscriptionFavoriteMeal = async (mealId: string) => {
    let updatedFavIds: string[] = [];
    updatedFavIds = favMealIds.includes(mealId) ? favMealIds.filter((id) => id !== mealId) : [...favMealIds, mealId];

    await updateFavMeal(
      {
        subscriptionId: subscription.id,
        allergicIng: foodPref?.ingredientsToAvoid || [],
        healthConditions: foodPref?.healthConditions.healthConditions || [],
        notes: foodPref?.healthConditions?.notes || [],
        favorite: updatedFavIds
      },
      {
        onSuccess: (updatedData) => {
          if (updatedData) {
            setFavMealIds(updatedData.foodPreference.favouriteFood);
            delRefetch();
          }
        }
      }
    );
  };

  const refetchDeliveries = () => {
    subscriptionDeliveriesRefetch();
    delRefetch();
  };

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <Stack style={{ padding: 1, justifyContent: 'center' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '19px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '23px',
              letterSpacing: '-0.38px'
            }}
          >
            Delivery Day
          </Typography>
        </Stack>
        <Stack style={{ padding: '8px' }}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={selectedDate || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      borderColor: 'black',
                      textAlign: 'center'
                    },
                    endAdornment: (
                      <IconButton size="small" sx={{ padding: 1 }}>
                        {params.InputProps?.endAdornment}
                      </IconButton>
                    )
                  }}
                  label="Select Date"
                  InputLabelProps={{
                    style: {
                      alignContent: 'center'
                    }
                  }}
                />
              )}
              renderDay={(day, _value, DayComponentProps) => handleRenderDay(day, DayComponentProps, deliveryDayStatus)}
              onChange={(endDate: any) => endDate && setSelectedDate(endDate as Date)}
              onMonthChange={(newDate) => setSelectedDate(newDate as Date)}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      {selectedDeliveryLoading || delLoading || foodPrefLoading || subscriptionDeliveriesLoading ? (
        <CaloLoader />
      ) : (
        <>
          <Box sx={{ flexDirection: 'row', width: '100%', marginY: '1px', marginX: '4px' }}>
            <Stack>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px'
                }}
              >
                Total Information:
              </Typography>
            </Stack>
            <MealTotalInformationSection meals={selectedDelivery?.food || []} />
          </Box>
          {selectedDelivery ? (
            <Box style={{ flexDirection: 'row', width: '100%', margin: 8 }}>
              {['breakfast', 'lunch', 'snack', 'dessert', 'caloKids', 'salad', 'juice', 'coffee', 'gifts'].map((mealType) => (
                <Stack key={mealType} display={selectedDeliveryFood[mealType]?.length > 0 ? 'flex' : 'none'}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', textTransform: 'capitalize' }}>
                    {mealType === 'lunch' ? 'lunch ' : mealType === 'caloKids' ? 'Kids' : mealType}
                  </Typography>
                  <Stack>
                    {selectedDeliveryFood[mealType].map((meal: any) => (
                      <DeliveryMealCard
                        key={meal.id}
                        meal={meal}
                        menu={menu}
                        foodPref={foodPref}
                        foodPrefRef={foodPrefRef}
                        refetch={refetchDeliveries}
                        selectedDelivery={selectedDelivery}
                        customFoodComponents={customFoodComponents}
                        isCustomCompsLoading={loadingCustomComponents}
                        allCustomFoodComponents={allCustomFoodComponents}
                        setCustomFoodComponents={setCustomFoodComponents}
                        isFavorite={favMealIds?.includes(meal.id) || false}
                        caloKidsComponents={caloKidsComponents?.data || []}
                        setAllCustomFoodComponent={setAllCustomFoodComponents}
                        handleUpdateSubscriptionFavoriteMeal={handleUpdateSubscriptionFavoriteMeal}
                        allFoodComponentsData={uniq(compact([...(foodComponentData || []), ...(childFoodComponentsData || [])]))}
                        menuLoading={
                          menuLoading ||
                          LoadingComponents ||
                          loadingCustomComponents ||
                          LoadingChildComponents ||
                          loadingCustomCaloKidsComponents
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Box>
          ) : (
            <Stack style={{ textAlign: 'center' }}>
              <Typography sx={{ paddingY: 2 }}> No Delivery</Typography>
            </Stack>
          )}
        </>
      )}
    </>
  );
};
export default DeliveriesCard;
