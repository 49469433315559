import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Select, Select2, SideDrawer } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

import { DeliveryFilters } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { DeliveryTime } from 'lib/enums';
import { omit } from 'lodash-es';

interface SettingsProps {
  onFilter: (values: DeliveryFilters) => any;
  filters: DeliveryFilters;
  drivers: any;
}

const Settings = ({ filters, onFilter, drivers }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const dispatch = useDispatch();
  const userKitchens: string[] = useUserKitchens();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country || getAccessibleCountries(userKitchens)[0]}
          onChange={(e) =>
            onFilter({
              ...filters,
              country: e.target.value as Country,
              kitchen: userKitchens.find((k) => k.includes(e.target.value)) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e: any) => onFilter({ ...filters, kitchen: e.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country || getAccessibleCountries(userKitchens)[0])}
          disabled={Boolean(filters.country && Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0)}
        />
        <Select
          label="Delivery Time"
          value={filters.deliveryTime}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['deliveryTime']),
              ...(v.value && {
                deliveryTime: v.value
              })
            })
          }
          options={[
            {
              value: DeliveryTime.earlyMorning,
              label: 'Early Morning'
            },
            {
              value: DeliveryTime.morning,
              label: 'Morning'
            },
            {
              value: DeliveryTime.evening,
              label: 'Evening'
            }
          ]}
        />
        <Select
          label="Driver"
          value={filters.driverId}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['driverId']),
              ...(v.value && {
                driverId: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...drivers.map((d: any) => ({
              value: d.id,
              label: `${d.name} (${d.phoneNumber})`
            }))
          ]}
        />
        <div className="field">
          <label className="label">Day</label>
          <div className="control is-clearfix">
            <DateRangePicker
              clearIcon={null}
              calendarType={'US'}
              onChange={(dates: any) =>
                onFilter({
                  ...filters,
                  day: {
                    gte: format('yyyy-MM-dd')(dates[0]),
                    lte: format('yyyy-MM-dd')(dates[1])
                  }
                })
              }
              value={[parseISO(filters.day.gte), parseISO(filters.day.lte)]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
