import { MapFilters } from '@calo/dashboard-types';
import { Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select, SideDrawer } from 'components';
import { addWeeks, format, parseISO } from 'date-fns/fp';
import { DeliveryTime } from 'lib/enums';
import { getAccessibleCountries, setCountryToLocalStorage } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { omit } from 'lodash-es';
import { useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsProps {
  onFilter: (values: MapFilters) => any;
  filters: MapFilters;
  day: string;
  setDay: (day: string) => void;
  kitchenMap: boolean;
}

const Settings = ({ kitchenMap, filters, onFilter, day, setDay }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const changeCountry = (data: any) => {
    setCountryToLocalStorage(data.value);
    onFilter({
      ...filters,
      country: data.value,
      kitchen: userKitchens.find((k) => k.includes(data.value)) as Kitchen
    });
  };

  useEffect(() => {
    onFilter({
      ...filters,
      kitchen: userKitchens.find((k) => k.includes(filters.country!)) as Kitchen
    });
  }, []);

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select
          label="Country"
          value={filters.country}
          onChange={(data: any) => changeCountry(data)}
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
          data-test="dashboard-country-select"
        />
        <Select
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data: any) => {
            onFilter({ ...filters, kitchen: data.value as Kitchen });
          }}
          options={getKitchenOptions(userKitchens, filters.country!)}
          isDisabled={userKitchens.filter((r) => r.includes(filters.country!))?.length === 0}
          data-test="deliveryPlanner-kitchen-select"
        />
        {!kitchenMap && (
          <>
            <Select
              label="Delivery Time"
              value={filters.deliveryTime}
              onChange={(v: any) =>
                onFilter({
                  ...omit(filters, ['deliveryTime']),
                  ...(v.value && {
                    deliveryTime: v.value
                  })
                })
              }
              options={[
                {
                  value: DeliveryTime.earlyMorning,
                  label: 'Early Morning'
                },
                {
                  value: DeliveryTime.morning,
                  label: 'Morning'
                },
                {
                  value: DeliveryTime.evening,
                  label: 'Evening'
                }
              ]}
            />
            <label className="label">Date:</label>
            <DatePicker
              minDate={new Date()}
              maxDate={addWeeks(2)(Date.now())}
              onChange={(date: any) => setDay(format('yyyy-MM-dd')(date))}
              value={parseISO(day)}
              clearIcon={null}
            />
          </>
        )}
      </section>
    </SideDrawer>
  );
};

export default Settings;
