import { Permission } from '@calo/dashboard-types';
import { Button, Select } from 'components';
import { DeliveryTime } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Delivery, UpdateDeliveryReq } from 'lib/interfaces';
import { useState } from 'react';
import { MutateFunction } from 'react-query';

interface SettingsCardProps {
  delivery: Delivery;
  onSave: MutateFunction<
    void,
    unknown,
    UpdateDeliveryReq & {
      id: string;
    },
    unknown
  >;
}

const DeliverySettingsCard = ({ delivery, onSave }: SettingsCardProps) => {
  const [time, setTime] = useState(delivery.time);
  const roles = useUserRoles();

  return (
    <div className=" flex-1 ">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title bg-black text-white">Settings</p>
        </header>
        <div className="card-content">
          {roles.includes(Permission.UPDATE_DELIVERY) ? (
            <>
              <Select
                label="Delivery Time"
                value={time}
                options={[
                  { value: DeliveryTime.morning, label: 'Morning' },
                  { value: DeliveryTime.evening, label: 'Evening' }
                ]}
                onChange={(data: any) => setTime(data.value)}
              />
              <Button
                content="SAVE"
                fluid
                primary
                disabled={time === delivery.time}
                onClick={() => onSave({ id: delivery.id, time })}
              />
            </>
          ) : (
            time
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliverySettingsCard;
