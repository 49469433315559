import queryClient from 'lib/queryClient';
import { flattenDeep, isArray } from 'lodash-es';
import { QueryKey } from 'react-query';

export default (key: QueryKey, data: any) => {
  queryClient.setQueryData<Record<string, any>>(key, (prevData) => {
    console.log(prevData);
    if (prevData?.data && isArray(prevData.data)) {
      const index = prevData.data.findIndex((row) => row.id === data.id);
      console.log(index);

      if (index && index > 0) {
        prevData.data = flattenDeep([
          prevData?.data.slice(0, index),
          {
            ...data
          },
          prevData?.data.slice(index + 1)
        ]);
      } else if (index === 0) {
        prevData.data = flattenDeep([
          {
            ...data
          },
          prevData?.data.slice(index + 1)
        ]);
      } else {
        prevData.data.push(data);
      }
      console.log(data, [...prevData?.data]);
      return {
        ...prevData
      };
    }

    if (prevData) {
      return {
        ...prevData,
        ...data
      };
    }
    return data;
  });
};

export const simpleMutation = (key: QueryKey, data: any) => {
  queryClient.setQueryData<Record<string, any>>(key, () => {
    return data;
  });
};
