import { DeliveryAddress, Subscription } from '@calo/types';
import { Grid, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format, setDay } from 'date-fns/fp';
import { DeliveryTime } from 'lib/enums';
import { DeliveryPreferences } from 'lib/interfaces';
import { DeliveryLocationPicker, DeliveryTimePicker } from '../index';

type DeliveryPreferencesSectionProps = {
  isEditing: boolean;
  isApplyForAllDays: boolean;
  subscription: Subscription;
  selectedDeliveryPreferences: DeliveryPreferences;
  changeDeliveryAddress: (isApplyForAllDays: boolean, addressId: string, day?: string) => void;
  changeDeliveryTime: (isApplyForAllDays: boolean, deliveryTime: DeliveryTime, day?: string) => void;
  deliveryDays: string[];
};
const DeliveryPreferencesSection = ({
  isEditing,
  subscription,
  selectedDeliveryPreferences,
  changeDeliveryAddress,
  changeDeliveryTime,
  isApplyForAllDays = false,
  deliveryDays
}: DeliveryPreferencesSectionProps) => {
  return (
    <>
      {isApplyForAllDays ? (
        <Stack flexDirection={'row'} gap={2} className="mt-2" sx={{ marginTop: '22px' }}>
          <Grid container spacing={2} className="mt-2">
            <DeliveryLocationPicker
              isEditing={isEditing}
              isApplyForAllDays={isApplyForAllDays}
              deliveryAddresses={subscription.deliveryAddresses.filter((address: DeliveryAddress) => !address.deletedAt)}
              selectedDeliveryPreferences={selectedDeliveryPreferences}
              changeDeliveryAddress={changeDeliveryAddress}
            />
            <DeliveryTimePicker
              isEditing={isEditing}
              kitchen={subscription.kitchen}
              deliveryTime={selectedDeliveryPreferences[0]?.deliveryTime || DeliveryTime.morning}
              changeDeliveryTime={changeDeliveryTime}
              isApplyForAllDays={isApplyForAllDays}
            />
          </Grid>
        </Stack>
      ) : (
        deliveryDays.map((value, index) => (
          <Stack sx={{ marginTop: index === 0 ? '22px' : '16px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                my: 'auto',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400
              }}
            >
              {format('EEEE')(setDay(Number(value))(Date.now()))}
            </Typography>
            {!subscription.deliveryDays.includes(Number(value)) && (
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  my: 'auto',
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600,
                  color: caloTheme.palette.secondaryRed300
                }}
              >
                We will not deliver to this location since it is not chosen in the packages page
              </Typography>
            )}
            <Grid container spacing={2} className="mt-2">
              <DeliveryLocationPicker
                isEditing={isEditing}
                isApplyForAllDays={isApplyForAllDays}
                deliveryAddresses={subscription.deliveryAddresses.filter((address) => !address.deletedAt)}
                deliveryDay={value}
                selectedDeliveryPreferences={selectedDeliveryPreferences}
                changeDeliveryAddress={changeDeliveryAddress}
              />
              <DeliveryTimePicker
                isEditing={isEditing}
                kitchen={subscription.kitchen}
                deliveryDay={value}
                deliveryTime={
                  selectedDeliveryPreferences[value as keyof DeliveryPreferences]?.deliveryTime || DeliveryTime.morning
                }
                changeDeliveryTime={changeDeliveryTime}
                isApplyForAllDays={isApplyForAllDays}
              />
            </Grid>
          </Stack>
        ))
      )}
    </>
  );
};

export default DeliveryPreferencesSection;
