import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Card, Typography } from '@mui/material';
import type { NotificationCardProps } from './types';

export const SoundTestCard = ({ onConfirm }: NotificationCardProps) => (
  <Card
    variant="outlined"
    sx={{
      position: 'fixed',
      top: '0',
      marginTop: '64px',
      right: 36,
      zIndex: 1000,
      padding: 1.5,
      paddingBottom: 0,
      backgroundColor: '#E5F6FD',
      color: '#014361',
      display: 'flex',
      flexDirection: 'row',
      width: '90%',
      maxWidth: '400px',
      borderRadius: 2,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      border: 'none'
    }}
  >
    <InfoOutlinedIcon
      sx={{
        color: '#0288D1',
        fontSize: 24,
        mb: 2.5
      }}
    />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginInline: '12px'
      }}
    >
      <Typography
        sx={{
          fontSize: '0.9rem',
          fontWeight: 'bold',
          color: '#014361',
          mb: 1,
          lineHeight: 1.5
        }}
      >
        Sound test
      </Typography>
      <Typography
        sx={{
          fontSize: '0.8rem',
          fontWeight: 400,
          color: '#014361',
          mb: 3.5,
          lineHeight: 1.5,
          maxWidth: '320px'
        }}
      >
        This is a sound test. Please confirm if you can hear the notification sound.
      </Typography>
    </div>
    <Button
      variant="contained"
      onClick={onConfirm}
      sx={{
        backgroundColor: '#0288D1',
        color: 'white',
        textTransform: 'none',
        borderRadius: 1,
        px: 2,
        py: 0.5,
        minWidth: '80px',
        fontSize: '0.8rem',
        fontWeight: 500,
        alignSelf: 'flex-start',
        '&:hover': {
          backgroundColor: '#0288D1',
          opacity: 0.9
        }
      }}
    >
      CONFIRM
    </Button>
  </Card>
);
