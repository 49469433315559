import { useState } from 'react';

import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Permission, WalletFilters } from '@calo/dashboard-types';
import { Brand, Currency, Kitchen } from '@calo/types';

import { exportWallet, getListWithParams } from 'actions';
import { Button, CaloLoader, Pagination } from 'components';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import PaymentRow from './PaymentRow';
import Settings from './Settings';
import Stats from './Stats';

interface PaginatedPayment {
  data: any[];
  meta: {
    limit: number;
    total: number;
    totalAmount: number;
  };
}
interface WalletFiltersWithKitchen extends WalletFilters {
  kitchen: Kitchen;
}
const PaymentList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roles = useUserRoles();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<WalletFiltersWithKitchen>({
    currency: Currency.BHD,
    brand: Brand.CALO,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<any, Error, PaginatedPayment>(
    ['wallets', { page, filters, limit: 25 }],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const allPayment = data?.data;

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Payment / {filters.brand}</li>
              </ul>
            </div>
          </div>
          {roles.includes(Permission.EXPORT_WALLET_LIST) && (
            <div className="level-left">
              <div className="level-item">
                <Button onClick={() => exportWallet(filters)} icon="fas fa-file-export" />
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="py-8">
        <Stats totalPayment={data?.meta.totalAmount || 0} transaction={data?.meta.total || 0} currency={filters.currency} />
      </div>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="overflow-y-auto">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>User</th>
                        <th style={{ color: 'white' }}>Phone Number</th>
                        <th style={{ color: 'white' }}>Date</th>
                        <th style={{ color: 'white' }}>Payment method</th>
                        <th style={{ color: 'white' }}>Amount</th>
                        <th style={{ color: 'white' }}>Currency</th>
                        <th style={{ color: 'white' }}>Transaction type</th>
                        <th style={{ color: 'white' }}>Reason</th>
                        <th style={{ color: 'white' }}>Note</th>
                      </tr>
                    </thead>
                    {allPayment && allPayment.length === 0 ? (
                      <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO PAYMENT</span>
                    ) : (
                      <tbody>{allPayment?.map((user) => <PaymentRow key={user.user} user={user} />)}</tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        <Settings onFilter={setFilters} filters={filters} />
        {data && (
          <Pagination
            isHidden={data.data.length === 0 || data.meta.total < 25}
            limit={data.meta.limit}
            total={data.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </section>
    </>
  );
};

export default PaymentList;
