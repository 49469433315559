import { MenuFoodComponent } from '@calo/dashboard-types';
import { Brand, CustomMealCategory, Dictionary, Macros, Subscription } from '@calo/types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { createCustomFood, getListWithParamsPost, replaceFood, updateCustomFood } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import { getOrderedComponents } from 'components/Sections/helpers';
import { Food, FoodComponent } from 'lib/interfaces';
import { keyBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';
import { getCreateCustomizeFoodFormInitialValues, useCreateCustomizeFoodForm } from '../CreateCustomizeFoodForm';
import CustomizedFoodTable from '../CustomizedFoodTable';

interface CustomizeFoodMacrosResultsCardProps {
  newMacros?: Macros;
  food: Food;
  deliveryId: string;
  subscription: Subscription;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  positionIndex: number;
  calculatedCost: string;
  originalCost: string;
  oldMacros: Macros;
}

const CustomizeFoodMacrosResultsCard = ({
  newMacros,
  oldMacros,
  deliveryId,
  subscription,
  refetchDelivery,
  positionIndex,
  food,
  originalCost,
  calculatedCost
}: CustomizeFoodMacrosResultsCardProps) => {
  const selectedFoodCompIds = useMemo(() => food?.components?.map((comp) => comp.id) ?? [], [food]);
  const [selectedFoodComponents, setSelectedFoodComponents] = useState<FoodComponent[]>([]);

  const { isLoading: isLoadingComponent } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/list',
      {
        limit: selectedFoodCompIds.length,
        filters: {
          withChildComponents: true,
          ids: selectedFoodCompIds,
          country: subscription.country,
          brand: Brand.CALO,
          kitchen: food?.kitchen
        }
      }
    ],
    getListWithParamsPost,
    {
      suspense: false,
      keepPreviousData: true,
      enabled: !!selectedFoodCompIds.length,
      onSuccess: (data) => {
        if (data.data) {
          setSelectedFoodComponents(data.data);
        }
      }
    }
  );

  const [allComponents, setAllComponents] = useState<Dictionary<FoodComponent>>({});
  const keyedSelectedComponents = useMemo(() => keyBy(selectedFoodComponents, 'id'), [selectedFoodComponents]);

  const { mutateAsync: replaceMutation, isLoading: isLoadingReplace } = useMutation(replaceFood);
  const { mutateAsync: createCustomFoodMutation, isLoading: isLoadingCustomFood } = useMutation(createCustomFood);
  const { mutateAsync: updateCustomFoodMutation, isLoading: isLoadingUpdateCustomFood } = useMutation(updateCustomFood);

  const { values, setValues, setFieldValue } = useCreateCustomizeFoodForm(food, subscription.id, deliveryId);

  useEffect(() => {
    setAllComponents((prev) => {
      const newComponents = {};
      for (const id in prev) {
        if (values.components?.find((comp) => comp.id === id)) {
          newComponents[id] = prev[id];
        }
      }
      return { ...newComponents, ...keyedSelectedComponents };
    });
  }, [keyedSelectedComponents]);

  useEffect(() => {
    setValues(getCreateCustomizeFoodFormInitialValues(food, subscription.id, deliveryId));
  }, [food, subscription.id, deliveryId]);

  const handleComponentsChange = async (rows: MenuFoodComponent[]) => {
    setFieldValue('components', rows);
  };

  const createAndReplaceMeal = async () => {
    let newMeal;
    if (food.isCustom && food.category === CustomMealCategory.athlete) {
      newMeal = await updateCustomFoodMutation({
        deliveryId,
        foodId: food.id,
        name: food.name,
        description: food.description,
        components: (values.components ?? []).map((comp) => comp.id),
        componentsWithQuantity: (values.components ?? []).map((comp) => {
          return {
            id: comp.id,
            quantity: comp.quantity ?? 0
          };
        }),
        metadata: JSON.stringify({
          referenceId: food.metadata?.referenceId,
          referenceSize: food.size,
          targetMacros: newMacros
        })
      });
    } else {
      newMeal = await createCustomFoodMutation({
        deliveryId,
        subscriptionId: subscription.id,
        name: food.name,
        description: food.description,
        foodComponentIds: (values.components ?? []).map((comp) => comp.id),
        components: (values.components ?? []).map((comp) => {
          return {
            id: comp.id,
            quantity: comp.quantity
          };
        }),
        category: CustomMealCategory.athlete,
        type: food.type,
        // @ts-ignore
        metadata: JSON.stringify({
          referenceId: food.id,
          referenceSize: food.size,
          targetMacros: newMacros
        })
      });
    }

    await replaceMutation({
      id: deliveryId || '',
      sourceId: food.id,
      targetId: newMeal.size === 'C' ? (newMeal.id === 'food' ? newMeal.sk : newMeal.id) : newMeal.id || newMeal.foodId,
      positionIndex,
      originalCost,
      calculatedCost,
      isAthleteMealReplacedManually: true,
      oldMacros,
      newMacros
    });
    refetchDelivery();
  };

  return (
    <Stack>
      <Stack sx={{ mt: 1 }} justifyContent={'space-between'} direction={'row'} gap={4} width={'100%'}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Results: Updated Meal Components</Typography>

        <LoadingButton
          variant="outlined"
          sx={{
            padding: '10px, 16px',
            color: caloTheme.palette.neutral900,
            borderColor: caloTheme.palette.neutral900,
            fontWeight: 600
          }}
          loading={isLoadingReplace || isLoadingCustomFood || isLoadingUpdateCustomFood}
          onClick={createAndReplaceMeal}
        >
          Replace meal
        </LoadingButton>
      </Stack>
      <Box sx={{ mt: 4 }}>
        {isLoadingReplace || isLoadingCustomFood || isLoadingUpdateCustomFood || isLoadingComponent ? (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <CustomizedFoodTable
            isDisabled={false}
            isChild={false}
            onChange={handleComponentsChange}
            allComponents={allComponents}
            selectedComponents={getOrderedComponents(values.components!, allComponents)}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CustomizeFoodMacrosResultsCard;
