import { QueryFunctionContext, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export type UseCustomQueryOptions<TQueryFnData, TError, TData> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData>,
  'queryKey' | 'queryFn'
>;

export const useCustomQuery = <TQueryFnData, TError, TData>(
  queryKey: Array<string | number | undefined | Record<string, any>>,
  queryFn: (context: QueryFunctionContext) => Promise<TQueryFnData>,
  options?: UseCustomQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> => {
  return useQuery<TQueryFnData, TError, TData>({
    queryKey,
    queryFn,
    ...options
  });
};
