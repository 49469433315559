import { RetailBranch } from '@calo/types';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { SideDrawer } from 'components';
import { AppState } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RetailFilter } from '../../types';

interface SettingsProps {
  onFilter: (values: RetailFilter) => any;
  filters: RetailFilter;
}

const retailBranchData = [
  { label: RetailBranch.SEEF, value: RetailBranch.SEEF },
  { label: RetailBranch.BOXAT, value: RetailBranch.BOXAT },
  { label: RetailBranch.HITTEN, value: RetailBranch.HITTEN },
  { label: RetailBranch.ROSHAN, value: RetailBranch.ROSHAN },
  { label: RetailBranch.SEDRA, value: RetailBranch.SEDRA }
];

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();
  const [localFilters, setLocalFilters] = useState(filters);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  useEffect(() => {
    setHasChanged(JSON.stringify(localFilters) !== JSON.stringify(filters));
  }, [localFilters, filters]);

  const handleChange = (field: string, value: any) => {
    setLocalFilters({ ...localFilters, [field]: value });
  };

  const handleApplyFilters = () => {
    onFilter(localFilters);
  };

  const handleClearFilters = () => {
    const defaultFilters: RetailFilter = {
      country: filters.country,
      brand: filters.brand,
      branch: RetailBranch.SEEF
    };
    setLocalFilters(defaultFilters);
    onFilter(defaultFilters);
  };

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <h5 className="title is-5">Filters</h5>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Branch</InputLabel>
          <Select
            value={localFilters.branch}
            onChange={(e) => handleChange('branch', e.target.value as RetailBranch)}
            label="Branch"
          >
            {retailBranchData.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack
          direction="row"
          spacing={2}
          sx={{ position: 'absolute', bottom: 48, width: '100%', right: 0, left: 0, px: '16px' }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={handleClearFilters}
            sx={{
              color: 'grey.500',
              borderColor: 'grey.300',
              fontWeight: 500,
              flex: 1,
              '&:hover': {
                borderColor: 'grey.400',
                backgroundColor: 'grey.50'
              }
            }}
          >
            clear filter
          </Button>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            disabled={!hasChanged}
            sx={{
              backgroundColor: caloTheme.palette.primary500,
              color: 'white',
              fontWeight: 500,
              flex: 1,
              '&:hover': {
                backgroundColor: caloTheme.palette.primary500,
                opacity: 0.9
              },
              '&.Mui-disabled': {
                backgroundColor: 'grey.300',
                color: 'grey.500'
              }
            }}
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </SideDrawer>
  );
};

export default Settings;
