import { useState } from 'react';

import { getListWithParamsPost } from 'actions';
import { resolveCountryFromKitchen } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { FoodComponentQuery } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { FoodComponentFilters, Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import CaloLoader from 'components/CaloLoader';
import Pagination from 'components/Pagination';
import SharedListHeaderCard from 'components/SharedListHeaderCard';
import FoodComponentListCard from './FoodComponentListCard';
import Settings from './Settings';

const FoodComponentList = () => {
  const roles = useUserRoles();
  const [page, setPage] = useState<number>(0);
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<FoodComponentFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const isDisabled = !roles.includes(Permission.CREATE_FOOD_COMPONENTS);

  const { data, isLoading } = useQuery<any, Error, FoodComponentQuery>(
    [
      'food-components/list',
      {
        page,
        filters: {
          ...filters,
          name: filters.name ? filters.name : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined
        },
        limit: 50
      }
    ],
    getListWithParamsPost,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <SharedListHeaderCard
        filters={filters}
        list={data?.data ?? []}
        listType="Components"
        history={history}
        isDisabled={isDisabled}
      />
      {isLoading ? <CaloLoader /> : <FoodComponentListCard components={data?.data ?? []} isLoading={isLoading} />}
      <Settings onFilter={setFilters} filters={filters} />
      {data?.data && (
        <Pagination
          isHidden={data.data.length === 0 || data.meta.total < 50}
          limit={50}
          total={data.meta.total}
          page={page}
          onChange={setPage}
        />
      )}
    </>
  );
};

export default FoodComponentList;
