import { Ingredient } from '@calo/types';

export const cleanComponentIngredients = (ingredients: Ingredient[]) => {
  return ingredients.map((ing) => ({
    id: ing.id,
    quantity: ing.quantity,
    header: ing.header,
    action: ing.action
  }));
};
