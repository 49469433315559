import { getRecordWithParams, updateDelivery } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { format as formatDate, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { convertTimeToRangeWithFormatting } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Delivery, Menu as FoodMenu } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AddressService } from 'services';

import { Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { Brand, Country, DeliveryAddress, DeliveryStatus, Food, Subscription } from '@calo/types';
import { Box, Card, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { DeliveryTime } from 'lib/enums';
import DeliveriesMealTable from './DeliveriesMealTable';
import DeliveryAction from './DeliveryAction';
import DeliveryPaymentIcons from './DeliveryPaymentIcons';
import DetailItem from './DetailItem';
import styles from './styles';

interface DeliveriesMealsProps {
  selectedDate: string;
  selectedDelivery: Delivery;
  refetchAllDeliveries: () => void;
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; ratings: any[] };
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

const handleCopyDeliveryId = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast('Delivery ID copied!', { type: 'success', autoClose: 2000 });
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};

const DeliveriesMeals = ({
  refetchAllDeliveries,
  subscription,
  selectedDate,
  selectedDelivery,
  refetchDelivery
}: DeliveriesMealsProps) => {
  const [selectedAddress, setSelectedAddress] = useState<Delivery['deliveryAddress']>(selectedDelivery?.deliveryAddress);
  const [selectedAddressCustomTime, setSelectedAddressCustomTime] = useState<string>();
  const [fetchMenu, setFetchMenu] = useState<boolean>(false);

  const roles = useUserRoles();
  useEffect(() => {
    setFetchMenu(true);
  }, [selectedDate]);

  const { data, isLoading: menuLoading } = useQuery(
    [
      '/menu',
      selectedDelivery?.day,
      {
        brand: selectedDelivery.brand ?? Brand.CALO,
        kitchen: selectedDelivery.kitchen,
        userId: selectedDelivery.userId
      }
    ],
    getRecordWithParams,
    {
      retry: false,
      enabled: fetchMenu
    }
  );

  useEffect(() => {
    setSelectedAddress(selectedDelivery?.deliveryAddress);
  }, [selectedDelivery]);

  const { mutateAsync: updateMutation } = useMutation(updateDelivery);

  const handleChangeAddress = async (deliveryAddress: string) => {
    await updateMutation({
      id: selectedDelivery.id,
      deliveryAddressId: deliveryAddress
    });
    refetchAllDeliveries();
    refetchDelivery();
  };

  const handleChangeTime = async (time: DeliveryTime) => {
    await updateMutation({ id: selectedDelivery.id, time: time });
    refetchAllDeliveries();
    refetchDelivery();
  };

  useEffect(() => {
    const address = subscription.deliveryAddresses.find((add) =>
      selectedAddress ? selectedAddress.id === add.id : add.default
    ) as DeliveryAddress & { customDeliveryTime: string };
    if (address && address.customDeliveryTime) {
      const list = address.customDeliveryTime.split(':');
      const time = new Date();
      time.setHours(parseInt(list[0]));
      time.setMinutes(parseInt(list[1]));
      const formattedTime = formatDate(time, 'h:mm a');
      setSelectedAddressCustomTime(formattedTime);
    }
  }, [selectedAddress, selectedDelivery, selectedDate]);

  const giftItems = selectedDelivery.giftedItems?.meal?.map((meal) => ({ ...meal, isGiftedItem: true })) ?? [];

  return (
    <Card variant="outlined" sx={{ ...styles.mainContainer }}>
      <Box sx={{ ...styles.mainBox }} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Stack display={'flex'} flexDirection={'row'}>
          <Typography sx={{ textAlign: 'left', ...styles.selectedDateText }}>{selectedDate}</Typography>
          {[DeliveryStatus.cancelled, DeliveryStatus.paused].includes(selectedDelivery.status) ? (
            <Typography sx={{ ...styles.deliveryStatusText }}>
              {selectedDelivery.status === DeliveryStatus.cancelled ? 'Cancelled' : 'Paused'}
            </Typography>
          ) : (
            <Typography
              sx={{
                ...styles.deliveryDeliveredAtText,
                color: selectedDelivery.deliveredAt ? caloTheme.palette.primary500 : caloTheme.palette.secondaryYellow500
              }}
            >
              {selectedDelivery.deliveredAt
                ? `Delivered ${format('hh:mm a')(new Date(Date.parse(selectedDelivery.deliveredAt)))}`
                : 'Pending'}
            </Typography>
          )}
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
            <DeliveryPaymentIcons delivery={selectedDelivery} />
            {roles.includes(Permission.VIEW_DEBUG_OPTIONS) && (
              <Typography
                sx={{
                  ml: 'auto',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '15px',
                  marginTop: '5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    width: 'auto'
                  }
                }}
                onClick={() => handleCopyDeliveryId(selectedDelivery.id)}
              >
                <Icon name={'copyBlack'} className="cursor-pointer" size={4} />
                Delivery ID
              </Typography>
            )}
            {roles.includes(Permission.VIEW_DEBUG_OPTIONS) && selectedDelivery?.country === Country.SA && (
              <Typography
                sx={{
                  ml: 'auto',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '15px',
                  marginTop: '5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    width: 'auto'
                  }
                }}
              >
                Kitchen - {selectedDelivery.kitchen}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack>
          <DeliveryAction
            menu={data as FoodMenu}
            subscription={subscription}
            delivery={selectedDelivery}
            refetchDelivery={refetchDelivery}
            refetchAllDeliveries={refetchAllDeliveries}
            setFetchMenu={setFetchMenu}
            menuLoading={menuLoading}
          />
        </Stack>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'} width={'100%'} mb={3}>
          <DetailItem title="Name">{selectedDelivery.name}</DetailItem>
          <DetailItem title="Phone">{selectedDelivery.phoneNumber}</DetailItem>
          <DetailItem title="Driver">{selectedDelivery.driver?.name || '----'}</DetailItem>
        </Box>
        <Box display={'flex'} flexDirection={'row'} width={'100%'}>
          <DetailItem title="Delivery Cost">
            {selectedDelivery.cost || 0} {selectedDelivery.currency}
          </DetailItem>
          <DetailItem title="Paid Amount">
            {selectedDelivery.paidAmount} {selectedDelivery.currency}
          </DetailItem>
          {selectedDelivery.eta?.range && selectedDelivery.eta?.time && (
            <DetailItem title="Delivery ETA">
              {`${format('hh:mm a')(parseISO(selectedDelivery.eta?.range.gte))} - ${format('hh:mm a')(parseISO(selectedDelivery.eta?.range.lte))} (Estimate: ${format('hh:mm a')(parseISO(selectedDelivery.eta?.time))})`}
            </DetailItem>
          )}
          {selectedAddressCustomTime && (
            <DetailItem title="Custom Delivery ETA">
              {`${convertTimeToRangeWithFormatting(selectedAddressCustomTime)[0]} - ${convertTimeToRangeWithFormatting(selectedAddressCustomTime)[1]}`}
            </DetailItem>
          )}
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} sx={{ mt: 4 }}>
        <DeliveriesMealTable
          meals={selectedDelivery.food as Food[]}
          refetchDelivery={refetchDelivery}
          selectedDelivery={selectedDelivery}
          subscription={subscription}
          menu={data as FoodMenu}
          menuLoading={menuLoading}
          // @ts-ignore
          addons={selectedDelivery.addons || []}
          gifted={(giftItems as any) || []}
          setFetchMenu={setFetchMenu}
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          my: 4,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            flexDirection: 'column'
          }
        }}
      >
        <TextField
          select
          type="text"
          name="deliveryTime"
          label="Delivery Time"
          disabled={!PermissionService.deliveryCanBeEdited(selectedDelivery)}
          sx={{
            width: '50%',
            mx: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: '91%',
              mb: 2
            }
          }}
          placeholder="Select Delivery Time"
          id="exact-subscription-deilveryTime"
          value={selectedDelivery.time}
          onChange={(data: any) => handleChangeTime(data.target.value)}
          InputProps={{ inputProps: { style: { borderRadius: 8, width: '100%' } }, style: { borderRadius: 8, width: '100%' } }}
        >
          {Object.values(DeliveryTime).map((deliveryTime) => (
            <MenuItem key={deliveryTime} value={deliveryTime}>
              {startCase(deliveryTime)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          type="text"
          name="Delivery Address"
          disabled={!PermissionService.deliveryCanBeEdited(selectedDelivery)}
          value={selectedAddress?.id}
          label="Delivery Address"
          sx={{
            width: '50%',
            mr: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: '92%',
              mb: 2,
              mx: 2
            }
          }}
          placeholder="Select Delivery Time"
          id="exact-subscription-deilveryTime"
          onChange={(data: any) => handleChangeAddress(data.target.value)}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {subscription.deliveryAddresses
            ?.filter(
              (deliveryAddress) => !deliveryAddress.deletedAt || selectedDelivery.deliveryAddress?.id === deliveryAddress.id
            )
            .map((deliveryAddress) => (
              <MenuItem key={deliveryAddress.id} value={deliveryAddress.id}>
                {deliveryAddress.notes ? (
                  <Typography>
                    {' '}
                    {AddressService.display(deliveryAddress)}
                    <br />
                    <Icon name={'notes'} className="cursor-pointer" size={5} />
                    <i>{deliveryAddress.notes}</i>
                  </Typography>
                ) : (
                  AddressService.display(deliveryAddress)
                )}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    </Card>
  );
};
export default DeliveriesMeals;
