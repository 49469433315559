import { useState } from 'react';

import { startCase } from 'lodash-es';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';

import { updateBusinessAccount } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { InputMUI } from 'components';
import { DeliveryTime } from 'lib/enums';
import { isValidEmail } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { BusinessAccount, UpdateAccountSubscriptionReq } from 'lib/interfaces';
import { toast } from 'react-toastify';
import DeliveryAddressCard from './DeliveryAddressCard';
import useAccountInformationForm from './useAccountInformationForm';

interface AccountInformationCardProprs {
  account: BusinessAccount;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}
const AccountInformationCard = ({ account, refetch }: AccountInformationCardProprs) => {
  const roles = useUserRoles();
  const { mutateAsync: updateMutation } = useMutation(updateBusinessAccount);
  const [countryKitchen, setCountryKitchen] = useState<{ country: Country; kitchen: Kitchen }>({
    country: account.deliveryAddresses[0].country,
    kitchen: account.deliveryAddresses[0].kitchen
  });

  const onSubmit = async (data: UpdateAccountSubscriptionReq) => {
    await updateMutation({
      accId: account.id,
      data
    });
    refetch();
  };

  const handlePhoneInputChange = (data: any) => {
    const input = data.target.value;
    data.target.value = input.slice(0, 2) === '00' ? `+${input.slice(2)}` : input;
    handleChange(data);
  };

  const { values, handleBlur, handleChange, setFieldValue } = useAccountInformationForm(account, onSubmit);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          mb: '14px',
          width: 'full',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: '100%'
          }
        }}
      >
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Customer Information
          </Typography>
        </Box>
        <Box
          component="form"
          autoComplete="off"
          sx={{
            width: 'full',
            mx: 2,
            '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
            }
          }}
        >
          <InputMUI
            debounce
            label="Name"
            placeholder="Enter Name"
            value={values.fullName}
            name="fullName"
            disabled={!roles.includes(Permission.UPDATE_BUSINESS_USER)}
            onChange={handleChange}
            id="exact-acc-fullName"
            onSubmit={(d) =>
              onSubmit({
                ...values,
                fullName: d
              })
            }
            inputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            id="new-account-email"
            label="Email"
            placeholder="Enter Email"
            value={account.email}
            name="email"
            onChange={handleChange}
            disabled
            onBlur={handleBlur}
            type="email"
            error={!isValidEmail(account.email!) && account.email!.length >= 1}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            select
            id="new-account-brand"
            label="Brand"
            value={account.brand}
            disabled
            name="brand"
            onChange={(data: any) => setFieldValue('brand', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(Brand).map((brand) => (
              <MenuItem value={brand}>{startCase(brand)}</MenuItem>
            ))}
          </TextField>

          <InputMUI
            required
            debounce
            id="create-account-phoneNumber"
            label="Phone Number"
            placeholder="Enter Phone Number"
            value={values.phoneNumber}
            name="phoneNumber"
            onChange={handlePhoneInputChange}
            onSubmit={(d) =>
              onSubmit({
                ...values,
                phoneNumber: d
              })
            }
            onBlur={handleBlur}
            type="text"
            inputProps={{ inputProps: { style: { borderRadius: 8 }, pattern: '[0-9]*' }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            select
            id="new-account-country"
            label="Country"
            value={countryKitchen.country}
            name="Country"
            disabled
            onChange={(data: any) => {
              const kitchen = Object.values(Kitchen).find((r) => r.includes(data.target.value) && !r.includes('000'));
              if (kitchen) {
                setCountryKitchen({
                  country: data.target.value,
                  kitchen
                });
              } else {
                toast.error('No kitchen found for country');
              }
            }}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.keys(Country).map((country) => (
              <MenuItem value={country}>{startCase(country)}</MenuItem>
            ))}
          </TextField>
          <TextField
            required
            select
            id="new-account-kitchen"
            label="Kitchen"
            value={countryKitchen.kitchen}
            name="name"
            disabled
            onChange={(data: any) => setCountryKitchen({ ...countryKitchen, kitchen: data.target.value })}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(Kitchen)
              .filter((r) => r.includes(countryKitchen.country!) && !r.includes('000'))
              .map((kitchen) => (
                <MenuItem value={kitchen}>{kitchen}</MenuItem>
              ))}
          </TextField>

          <TextField
            required
            select
            id="new-account-deliveryTime"
            label="Delivery Time"
            value={values.deliveryTime}
            name="deliveryTime"
            onChange={(data: any) => {
              setFieldValue('deliveryTime', data.target.value);
              onSubmit({
                ...values,
                deliveryTime: data.target.value
              });
            }}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime}>{startCase(deliveryTime)}</MenuItem>
            ))}
          </TextField>

          <DeliveryAddressCard
            key={account.id}
            refetch={refetch}
            account={account}
            country={countryKitchen.country}
            kitchen={countryKitchen.kitchen}
          />
        </Box>
      </Card>
    </>
  );
};
export default AccountInformationCard;
