import { useEffect, useRef, useState } from 'react';

import { getRecord, postRequest, updateFoodComponent } from 'actions';
import { ModalRef } from 'components';
import FoodComponentCalculatedMacrosModal from 'components/FoodComponentCalculatedMacrosModal/FoodComponentCalculatedMacrosModal';
import { FoodComponent, Ingredient, IngredientHeaderItem } from 'lib/interfaces';
import { isNil, omit, sortBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { MeasurementUnit, Permission } from '@calo/dashboard-types';
import { Macros, Micronutrients } from '@calo/types';

import { Card, Stack, TextField, Typography } from '@mui/material';
import {
  ComponentHeaderCard,
  ComponentInformationCard,
  ComponentMethodCard,
  ComponentNameAndImageCard,
  CupsListCard,
  CustomSectionCard,
  IngredientListCard,
  MacrosMicrosInputCard
} from 'components/FoodComponentShared';
import { ChildComponentsCard } from 'components/FoodComponentShared/ExistingComponent';
import { FormOperation, InputValueRestrictionType, Routes } from 'lib/enums';
import { cleanComponentIngredients, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import ComponentStandardsCard from './ComponentStandardsCard';
import SyncComponentPopup from './SyncComponentPopup';
import { filterAndSortUsedOnMenu, isNewValidHeader } from './helpers';
import useFoodComponentForm from './useFoodComponentForm';

const ExactFoodComponent = () => {
  const roles = useUserRoles();
  const isDisabled = !roles.includes(Permission.UPDATE_FOOD_COMPONENTS);

  const { id } = useParams<{ id: string }>();
  const syncComponentsPopupRef = useRef<ModalRef>();
  const calculatedMacrosRef = useRef<ModalRef>();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [childComponents, setChildComponents] = useState<FoodComponent[]>([]);
  const [calculatedMacrosFromIngredients, setCalculatedMacrosFromIngredients] = useState<Macros | undefined>();
  const [calculatedMicronutrientsFromIngredients, setCalculatedMicronutrientsFromIngredients] = useState<
    Micronutrients | undefined
  >();
  const [isCalculatedMacrosDifferent, setIsCalculatedMacrosDifferent] = useState(false);
  const [structuredIngredients, setStructuredIngredients] = useState<IngredientHeaderItem[]>([]);

  const { data: foodComponent, isLoading } = useQuery<any, Error, FoodComponent>(['food-components', id], getRecord, {
    suspense: true,
    keepPreviousData: false
  });

  const { data, isLoading: loadingFood } = useQuery<any, Error, any>(
    [
      'food/list',
      {
        filters: {
          brand: foodComponent?.brand,
          country: foodComponent?.country,
          kitchen: foodComponent?.kitchen,
          componentId: foodComponent?.id,
          isDeleted: false
        },
        page: 0,
        limit: 50
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!foodComponent && roles.includes(Permission.DELETE_INGREDIENT)
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updateFoodComponent);

  const handleUpdateFood = (values: Partial<FoodComponent>, structuredIngredients: IngredientHeaderItem[]) => {
    const updatedIngredients: Ingredient[] = [];
    let tempHeaderName: string | null | undefined = null;
    for (const structuredIngredient of structuredIngredients) {
      if (structuredIngredient.type === 'header') {
        tempHeaderName = structuredIngredient.header;
      } else {
        updatedIngredients.push({ ...structuredIngredient.ingredient, header: tempHeaderName ?? undefined });
      }
    }

    const cleanedIngredients = cleanComponentIngredients(updatedIngredients);

    const { purchasingCost: _purchasingCost, prototype: _prototype, parentComponentIds: _, ...rest } = values;
    return updateMutation({ id, ...rest, ingredients: cleanedIngredients as any });
  };

  const submitValues = async (values: Partial<FoodComponent>) => {
    await handleUpdateFood(values, structuredIngredients);
  };

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, setFieldValue, setValues, errors } =
    useFoodComponentForm(foodComponent, submitValues);

  let currentHeader: string | null | undefined;
  useEffect(() => {
    const sortedIngredients = sortBy(values.ingredients, [(ingredient) => (isNil(ingredient.header) ? -1 : 0), 'header']);

    const updatedStructuredIngredients: IngredientHeaderItem[] = [];
    for (const ingredient of sortedIngredients) {
      if (isNewValidHeader(ingredient.header, currentHeader)) {
        currentHeader = ingredient.header;
        updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
      }
      updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
    }

    setStructuredIngredients(updatedStructuredIngredients);
  }, [values.ingredients]);

  useEffect(() => {
    if (!foodComponent) {
      return;
    }
    setChildComponents([...(foodComponent.childComponents as unknown as FoodComponent[])]);
    setValues({
      ...(omit(foodComponent, ['id', 'createdAt', 'updatedAt', 'usedOnMenu', 'lastUsedOnMenu']) as FoodComponent),
      micronutrients: {
        addedSugar: foodComponent?.micronutrients?.addedSugar || 0,
        cholesterol: foodComponent?.micronutrients?.cholesterol || 0,
        saturatedFats: foodComponent?.micronutrients?.saturatedFats || 0,
        sodium: foodComponent?.micronutrients?.sodium || 0,
        totalSugar: foodComponent?.micronutrients?.totalSugar || 0,
        transFats: foodComponent?.micronutrients?.transFats || 0
      },
      childComponents: (foodComponent.childComponents ?? []).map((child) => ({ id: child.id, quantity: child.quantity }))
    });
  }, [foodComponent]);

  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  const filteredFoodUsed = filterAndSortUsedOnMenu(foodComponent);

  const handleSubmitSellingPrice = (value: number) => {
    setIsEdit(false);
    setFieldValue('sellingPrice', value);
  };

  return (
    <>
      <ComponentHeaderCard
        operation={FormOperation.update}
        foodComponent={foodComponent}
        filteredFoodUsed={filteredFoodUsed}
        syncComponentsPopupRef={syncComponentsPopupRef}
        isValid={isValid}
        isSubmitting={isSubmitting}
        isEdit={isEdit}
        componentFoodList={data?.data ?? []}
        isFoodLoading={loadingFood}
        route={Routes.foodComponentList}
        handleSubmit={handleSubmit}
        isDisabled={isDisabled}
      />
      {foodComponent && (
        <>
          <ComponentNameAndImageCard
            foodComponent={foodComponent}
            values={values}
            errors={errors}
            isLoading={isLoading}
            handleChange={handleChange}
            isDisabled={isDisabled}
          />
          <IngredientListCard
            values={values}
            childComponents={childComponents}
            structuredIngredients={structuredIngredients}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setStructuredIngredients={setStructuredIngredients}
          />
          <Card
            variant="outlined"
            sx={{
              marginTop: '16px',
              border: 'none',
              borderRadius: '16px',
              px: '18px',
              py: '18px'
            }}
          >
            <Typography sx={{ fontSize: '19px', fontWeight: 600, marginBottom: '10px' }}>Selling Price</Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <TextField
                type="number"
                name="sellingPrice"
                value={values.sellingPrice}
                onChange={(e) => handleSubmitSellingPrice(Number(e.target.value))}
                disabled={isDisabled}
                fullWidth
              />
              {errors.sellingPrice && <Typography color="error">{errors.sellingPrice}</Typography>}
            </Stack>
          </Card>
          <ChildComponentsCard
            values={values}
            foodComponent={foodComponent}
            childComponents={childComponents}
            setChildComponents={setChildComponents}
            setValues={setValues}
            setFieldValue={setFieldValue}
            setIsCalculatedMacrosDifferent={setIsCalculatedMacrosDifferent}
            setCalculatedMicronutrientsFromIngredients={setCalculatedMicronutrientsFromIngredients}
            setCalculatedMacrosFromIngredients={setCalculatedMacrosFromIngredients}
            isDisabled={isDisabled}
          />
          <MacrosMicrosInputCard
            operation={FormOperation.update}
            values={values}
            errors={errors}
            setValues={setValues}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            calculatedMacrosRef={calculatedMacrosRef}
            isCalculatedMacrosDifferent={isCalculatedMacrosDifferent}
            calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
            calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
            isDisabled={isDisabled}
          />
          <ComponentInformationCard
            values={values}
            errors={errors}
            childComponents={childComponents}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleChangeForNumRestrictions={handleChangeForNumRestrictions}
            isDisabled={isDisabled}
          />
          <CustomSectionCard values={values} setFieldValue={setFieldValue} errors={errors} isDisabled={isDisabled} />
          {values.measurementUnit === MeasurementUnit.cup && (
            <CupsListCard
              values={values}
              isDisabled={isDisabled}
              operation={FormOperation.create}
              setValues={setValues}
              setFieldValue={setFieldValue}
            />
          )}
          <ComponentMethodCard
            operation={FormOperation.update}
            values={values}
            errors={errors}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setValues={setValues}
            setFieldValue={setFieldValue}
            isDisabled={isDisabled}
          />
          <ComponentStandardsCard values={values} setValues={setValues} isDisabled={isDisabled} />
        </>
      )}
      <FoodComponentCalculatedMacrosModal
        calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
        calculatedMacrosRef={calculatedMacrosRef}
        calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
      />
      {foodComponent && (
        <SyncComponentPopup
          id={id}
          componentName={foodComponent.name.en}
          currentKitchen={foodComponent.kitchen}
          closePopup={() => syncComponentsPopupRef.current?.close()}
          ref={syncComponentsPopupRef}
        />
      )}
    </>
  );
};

export default ExactFoodComponent;
