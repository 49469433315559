import { FoodCategory, ProteinFoodTags, TasteFoodTags } from '@calo/dashboard-types';
import { FoodDietType, FoodType } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button as MUIButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { BaseFoodTags } from 'lib/enums';
import { startCase } from 'lodash-es';
import React, { CSSProperties, useMemo } from 'react';
import { selectedMenuFilters } from './AddMealsInterfaces';
import { styles } from './styles';

interface FiltersSectionProps {
  selectedMenuFilters: selectedMenuFilters;
  setSelectedMenuFilters: (value: selectedMenuFilters) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

const FiltersSection: React.FC<FiltersSectionProps> = ({
  selectedMenuFilters,
  setSelectedMenuFilters,
  searchTerm,
  setSearchTerm
}) => {
  const foodTypeOptions = useMemo(() => Object.values(FoodType), []);
  const foodDietTypeOptions = useMemo(() => Object.values(FoodDietType), []);
  const proteinFoodTagsOptions = useMemo(() => Object.values(ProteinFoodTags), []);
  const tasteFoodTagsOptions = useMemo(() => Object.values(TasteFoodTags), []);
  const baseFoodTagsOptions = useMemo(() => Object.values(BaseFoodTags), []);
  const foodCategoryOptions = useMemo(() => ['Any', ...Object.values(FoodCategory)], []);

  const handleChange = (field: keyof selectedMenuFilters) => (event: any) => {
    const value = event.target.value === 'Any' ? undefined : event.target.value;
    setSelectedMenuFilters({ ...selectedMenuFilters, [field]: value });
  };

  return (
    <Box display="flex" flexDirection="column" sx={styles.mainContainer}>
      <Stack display="flex" flexDirection="row" justifyContent="space-between" sx={styles.mainContainer}>
        <TextField
          select
          label="Type"
          value={selectedMenuFilters.type || 'Any'}
          placeholder="Select Type"
          id="menu-new-type-filters-select"
          sx={styles.typeText}
          onChange={handleChange('type')}
          InputProps={{ inputProps: { style: { borderRadius: 8 }, placeholder: 'Choose' }, style: { borderRadius: 8 } }}
        >
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {foodTypeOptions.map((type) => (
            <MenuItem key={type} value={type}>
              {startCase(type)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Plan"
          value={selectedMenuFilters.plan || 'Any'}
          placeholder="Select Plan"
          id="menu-new-plan-filters-select"
          sx={styles.typeText}
          onChange={handleChange('plan')}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {foodDietTypeOptions.map((plan) => (
            <MenuItem key={plan} value={plan}>
              {startCase(plan)}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Last Used (Optional)"
            value={selectedMenuFilters.lastUsed || null}
            onChange={(date: any) =>
              setSelectedMenuFilters({ ...selectedMenuFilters, lastUsed: format('yyyy-MM-dd')(date as Date) })
            }
            renderInput={(params) => <TextField {...params} style={styles.datePickerStyle as CSSProperties} />}
          />
        </LocalizationProvider>
      </Stack>

      <Stack display="flex" flexDirection="row" justifyContent="space-between" sx={styles.mainContainer}>
        <TextField
          select
          label="Protein"
          value={selectedMenuFilters.protein || 'Any'}
          placeholder="Select Protein"
          id="menu-new-protein-filters-select"
          sx={styles.typeText}
          onChange={handleChange('protein')}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {proteinFoodTagsOptions.map((protein) => (
            <MenuItem key={protein} value={protein}>
              {startCase(protein)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Taste"
          value={selectedMenuFilters.taste || 'Any'}
          placeholder="Select Taste"
          id="menu-new-taste-filters-select"
          sx={styles.typeText}
          onChange={handleChange('taste')}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {tasteFoodTagsOptions.map((taste) => (
            <MenuItem key={taste} value={taste}>
              {startCase(taste)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Base"
          value={selectedMenuFilters.base || 'Any'}
          placeholder="Select Base"
          id="menu-new-base-filters-select"
          sx={styles.typeText}
          onChange={handleChange('base')}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {baseFoodTagsOptions.map((base) => (
            <MenuItem key={base} value={base}>
              {startCase(base)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Sandwich"
          value={selectedMenuFilters.sandwich === undefined ? 'Any' : selectedMenuFilters.sandwich ? 'Yes' : 'No'}
          placeholder="Yes/No"
          id="menu-new-sandwich-filters-select"
          sx={styles.typeText}
          onChange={handleChange('sandwich')}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {[
            { label: 'Any', value: 'Any' },
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' }
          ].map((sandwich) => (
            <MenuItem key={sandwich.label} value={sandwich.value}>
              {startCase(sandwich.label)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Autocomplete
        options={foodCategoryOptions}
        getOptionLabel={(option) => (option === 'Any' ? 'Any' : startCase(option))}
        value={selectedMenuFilters.category || 'Any'}
        onChange={(event, newValue) => {
          const selectedCategory = newValue === 'Any' || newValue === null ? '' : (newValue as string);
          setSelectedMenuFilters({
            ...selectedMenuFilters,
            category: selectedCategory
          });

          setSearchTerm(selectedCategory);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Category"
            placeholder="Type to search..."
            sx={styles.autoCompleteField}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: 8 }
            }}
          />
        )}
      />

      {searchTerm && (
        <Stack direction="row" alignItems="center" sx={{ ...styles.searchTermStyle, width: searchTerm.length * 10 + 60 }}>
          <Typography>{searchTerm}</Typography>
          <MUIButton
            onClick={() => {
              setSelectedMenuFilters({ ...selectedMenuFilters, category: '' });
              setSearchTerm('');
            }}
            sx={{ paddingLeft: '0px' }}
          >
            <CloseIcon sx={{ color: caloTheme.palette.secondaryBlue700 }} />
          </MUIButton>
        </Stack>
      )}
    </Box>
  );
};

export default FiltersSection;
