import { MeasurementUnit } from '@calo/dashboard-types';
import { Brand, Country, IngredientCategory, Kitchen } from '@calo/types';
import { useFormik } from 'formik';

import { hasValidCharactersForLanguage } from 'lib/helpers';
import { IngredientFormData } from 'lib/interfaces';
import { isNumber } from 'lodash';

export default (onSubmit: (values: IngredientFormData) => any) =>
  useFormik<IngredientFormData>({
    initialValues: {
      key: '',
      internalName: '',
      name: {
        en: '',
        ar: ''
      },
      macros: {
        cal: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        fiber: 0
      },
      micronutrients: {
        addedSugar: 0,
        cholesterol: 0,
        saturatedFats: 0,
        sodium: 0,
        totalSugar: 0,
        transFats: 0
      },
      cost: 0,
      measurementUnit: MeasurementUnit.g,
      category: [IngredientCategory.other],
      wastage: 1,
      weight: 1,
      country: Country.BH,
      kitchen: Kitchen.BH1,
      brand: Brand.CALO
    },
    validate: (values: IngredientFormData) => {
      const errors: any = {};

      if (!values.name.en) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!values.name.ar) {
        errors.name = {
          ...errors.name,
          ar: true
        };
      }

      if (!hasValidCharactersForLanguage(values.name?.ar || '', 'AR')) {
        errors.name = {
          ...errors.name,
          ar: true
        };
      }

      if (!hasValidCharactersForLanguage(values.name?.en || '', 'EN')) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!isNumber(values.macros.cal)) {
        errors.macros = {
          ...errors.macros,
          cal: true
        };
      }
      if (!isNumber(values.macros.protein)) {
        errors.macros = {
          ...errors.macros,
          protein: true
        };
      }
      if (!isNumber(values.macros.carbs)) {
        errors.macros = {
          ...errors.macros,
          carbs: true
        };
      }
      if (!isNumber(values.macros.fat)) {
        errors.macros = {
          ...errors.macros,
          fat: true
        };
      }

      if (!values.country) {
        errors.country = true;
      }

      if (!values.brand) {
        errors.brand = true;
      }

      if (!values.kitchen || values.kitchen.length === 0) {
        errors.kitchen = true;
      }

      if (!values.wastage || values.wastage === 0) {
        errors.wastage = true;
      }

      if (!isNumber(values.cost)) {
        errors.cost = true;
      }

      if (!values.measurementUnit) {
        errors.measurementUnit = true;
      }

      return errors;
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
        setErrors({
          name: {
            en: 'Name already exists'
          }
        });
        return;
      }
    }
  });
