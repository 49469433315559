import SortIcon from '@mui/icons-material/Sort';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { compareDesc } from 'date-fns/fp';
import { useState } from 'react';
import { PickUpStatus } from '../../types';
import OrderTableRow from './OrderTableRow';

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between'
  }
}));

interface OrderTableProps {
  orders: any[];
  loadingItemIds: string[];
  isFetching: boolean;
  isLoading: boolean;
  onChangeStatus: (pickUpId: string, status: PickUpStatus) => void;
}

const getDateFromString = (dateString: string) => new Date(dateString);

function compareDates(a: Date, b: Date) {
  return compareDesc(a)(b);
}

function descendingComparator(a: any, b: any, orderBy: keyof any) {
  if (orderBy === 'createdAt') {
    return compareDates(getDateFromString(a.createdAt), getDateFromString(b.createdAt));
  }
  if (orderBy === 'pickUpAt') {
    return compareDates(getDateFromString(a.pickUp.pickUpAt), getDateFromString(b.pickUp.pickUpAt));
  }
  return 0;
}

function getComparator(order: Order, orderBy: keyof any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export const OrderTable = ({ orders, loadingItemIds, isFetching, isLoading, onChangeStatus }: OrderTableProps) => {
  const [orderBy, setOrderBy] = useState<'createdAt' | 'pickUpAt'>('createdAt');
  const [order, setOrder] = useState<Order>('desc');

  const handleRequestSort = (property: 'createdAt' | 'pickUpAt') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedOrders = [...orders].sort(getComparator(order, orderBy));

  return (
    <Table
      sx={{
        marginY: '4px',
        minHeight: '120px',
        overflow: 'hidden',
        width: '95%',
        mx: 'auto',
        boxShadow: '2',
        borderRadius: '15px',
        mt: '32px',
        backgroundColor: 'white',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <TableHead
        style={{ borderRadius: '8px' }}
        sx={{
          backgroundColor: 'rgba(238, 238, 238, 1)',
          color: 'black',
          flexWrap: 0,
          justifyContent: 'space-between',
          width: '100%',
          borderRadius: '8px'
        }}
      >
        <TableRow>
          <StyledTableCell>
            <strong>Order</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Customer</strong>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={orderBy === 'createdAt'}
              direction={orderBy === 'createdAt' ? order : 'asc'}
              onClick={() => handleRequestSort('createdAt')}
              IconComponent={SortIcon}
              sx={{
                '& .MuiTableSortLabel-icon': {
                  opacity: 0.5
                },
                '&.Mui-active .MuiTableSortLabel-icon': {
                  opacity: 1
                }
              }}
            >
              <strong>Created&nbsp;On</strong>
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <TableSortLabel
              active={orderBy === 'pickUpAt'}
              direction={orderBy === 'pickUpAt' ? order : 'asc'}
              onClick={() => handleRequestSort('pickUpAt')}
              IconComponent={SortIcon}
              sx={{
                '& .MuiTableSortLabel-icon': {
                  opacity: 0.5
                },
                '&.Mui-active .MuiTableSortLabel-icon': {
                  opacity: 1
                }
              }}
            >
              <strong>Pickup&nbsp;Time</strong>
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Status</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Items</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Comments</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Total Items</strong>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedOrders.map((order, index) => (
          <OrderTableRow
            key={index}
            order={order}
            loadingItemIds={loadingItemIds}
            isFetching={isFetching}
            isLoading={isLoading}
            onChangeStatus={onChangeStatus}
          />
        ))}
      </TableBody>
    </Table>
  );
};
