import { getList } from 'actions/index';
import { useCustomQuery, UseCustomQueryOptions } from './base';
import { QueryFunctionContext } from 'react-query';

export const useGetFoodPreferenceData = (
  subscriptionId: string | undefined,
  options?: UseCustomQueryOptions<any, Error, any>
) => {
  return useCustomQuery(
    [`food-preferences/${subscriptionId}`],
    (context: QueryFunctionContext) => getList(context) as Promise<any>,
    {
      enabled: !!subscriptionId,
      ...options
    }
  );
};
