import { getListWithParams, getListWithParamsPost } from 'actions/index';
import { useCustomQuery, UseCustomQueryOptions } from './base';
import { QueryFunctionContext } from 'react-query';
import { FoodComponent } from 'lib/interfaces';

export const useGetFoodComponents = (
  params: Record<string, string | number | undefined | Record<string, any>>,
  options?: UseCustomQueryOptions<any, Error, { data: FoodComponent[] }>
) => {
  return useCustomQuery(
    ['food-components/list', params],
    (context: QueryFunctionContext) => getListWithParamsPost(context) as Promise<any>,
    { ...options }
  );
};

export const useGetCustomFoodComponents = (
  params: Record<string, string | number | undefined | Record<string, any>>,
  options?: UseCustomQueryOptions<any, Error, any>
) => {
  return useCustomQuery(
    ['food-components/custom-food', params],
    (context: QueryFunctionContext) => getListWithParams(context) as Promise<any>,
    { ...options }
  );
};
