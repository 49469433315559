import { useEffect, useRef, useState } from 'react';

import { startCase } from 'lodash-es';
import { toast } from 'react-toastify';

import { Subscription } from '@calo/dashboard-types';
import { AddressService } from '@calo/services';
import { AddressType, DeliveryAddress, Kitchen, NewDeliveryAddress } from '@calo/types';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { caloTheme } from 'assets/images/theme/calo';
import { AddressPickerMUI, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { getAccessibleCountries, getCenteredCountry, getKitchenOptions } from 'lib';
import { AddressViewMode, DeliveryTime } from 'lib/enums';
import { isValidEmail } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { CreateAccountSubscriptionReq } from 'lib/interfaces';
import useAccountForm from './useAccountForm';

interface AccountFormProps {
  subscription: Subscription;
  onSubmit: (value: CreateAccountSubscriptionReq) => any;
}

const AccountForm = ({ onSubmit }: AccountFormProps) => {
  const { values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues } = useAccountForm(onSubmit);
  const addressDialogRef = useRef<ModalRef>();
  const notesPopUpRef = useRef<ModalRef>();

  const [selectedAddress, setSelectedAddress] = useState<Partial<DeliveryAddress> | null>();
  const [deliveryAddressChange, setDeliveryAddressChange] = useState<boolean>(false);

  const userKitchens = useUserKitchens();

  useEffect(() => {
    if (selectedAddress) {
      addressDialogRef.current?.open();
    } else {
      addressDialogRef.current?.close();
    }
  }, [selectedAddress]);

  const handleNewAddress = (ndeliveryAddress: NewDeliveryAddress) => {
    setValues({
      ...values,
      deliveryAddress: ndeliveryAddress
    });
    setSelectedAddress(null);
  };

  const handlePhoneInputChange = (data: any) => {
    const input = data.target.value;
    data.target.value = input.slice(0, 2) === '00' ? `+${input.slice(2)}` : input;
    handleChange(data);
  };

  useEffect(() => {
    if (values.deliveryAddress.region && values.deliveryAddress.country !== values.country) {
      setDeliveryAddressChange(true);
      toast(`the delivery address that was added is not in ${values.country}`, { type: 'error', autoClose: 2000 });
    } else {
      setDeliveryAddressChange(false);
    }
  }, [values.country, values.deliveryAddress]);

  useEffect(() => {
    setFieldValue('deliveryAddress', {
      country: values.country,
      lat: getCenteredCountry(values.country).lat,
      lng: getCenteredCountry(values.country).lng,
      building: '',
      street: '',
      type: AddressType.home,
      apartment: '',
      city: '',
      region: '',
      district: ''
    });
  }, [values.country]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    }
  }));

  return (
    <>
      <Box
        component="form"
        sx={{
          width: 'full',
          ml: 3,
          '& .MuiTextField-root': { m: 2, width: '30%', justifyContent: 'space-between' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row',
            width: '96%',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '96%' }
          }
        }}
      >
        <div className="w-full">
          <TextField
            required
            id="new-subscription-name"
            label="Name"
            placeholder="Enter Name"
            value={values.fullName}
            name="fullName"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            data-test="accountNameTextFieldInput"
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            id="new-subscription-email"
            label="Email"
            placeholder="Enter Email"
            value={values.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            data-test="accountEmailTextFieldInput"
            error={!isValidEmail(values.email) && values.email.length >= 1}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            id="create-subscription-phoneNumber"
            label="Phone Number"
            placeholder="Enter Phone Number"
            value={values.phoneNumber}
            name="phoneNumber"
            onChange={handlePhoneInputChange}
            onBlur={handleBlur}
            type="text"
            data-test="accountPhoneNumberTextFieldInput"
            InputProps={{ inputProps: { style: { borderRadius: 8 }, pattern: '[0-9]*' }, style: { borderRadius: 8 } }}
          />
          <TextField
            required
            select
            id="new-subscription-country"
            label="Country"
            value={values.country}
            name="Country"
            data-test="accountCountrySelect"
            onChange={(data: any) => {
              setFieldValue('country', data.target.value);
              setFieldValue(
                'kitchen',
                Object.values(Kitchen).find((r) => r.includes(data.target.value) && !r.includes('000'))
              );
            }}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {getAccessibleCountries(userKitchens).map((country) => (
              <MenuItem value={country} key={country} data-test={`${country}MenuItem`}>
                {startCase(country)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            select
            id="new-subscription-kitchen"
            label="Kitchen"
            value={values.kitchen}
            name="name"
            onChange={(data: any) => setFieldValue('kitchen', data.target.value)}
            onBlur={handleBlur}
            data-test="accountKitchenSelect"
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {getKitchenOptions(userKitchens, values.country).map((kitchen) => (
              <MenuItem value={kitchen.value} key={kitchen.value} data-test={`${kitchen.value}MenuItem`}>
                {kitchen.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            select
            id="new-subscription-deliveryTime"
            label="Delivery Time"
            value={values.deliveryTime}
            name="deliveryTime"
            onChange={(data: any) => setFieldValue('deliveryTime', data.target.value)}
            onBlur={handleBlur}
            data-test="accountDeliveryTimeSelect"
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime}>{startCase(deliveryTime)}</MenuItem>
            ))}
          </TextField>
        </div>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: 'full'
          }
        }}
      >
        <Card
          variant="elevation"
          sx={{
            width: '100%',
            borderRadius: '16px',
            my: 2,
            mx: 4,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'full'
            }
          }}
        >
          <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: '8px' }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '19px',
                lineHeight: '23px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: '600',
                marginX: '6px',
                my: 'auto'
              }}
            >
              Delivery Addresses
            </Typography>
            <Button
              variant="outlined"
              aria-label="addAddressButton"
              sx={{
                height: '40px',
                margin: '8px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                borderColor: caloTheme.palette.primary500,
                color: caloTheme.palette.primary500,
                '&:hover': {
                  borderColor: caloTheme.palette.primary600
                }
              }}
              startIcon={'+'}
              onClick={() => setSelectedAddress({})}
            >
              Add Address
            </Button>
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <TableContainer>
              <Table sx={{ width: '98%', marginX: 'auto', marginY: '4px' }}>
                <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, color: 'black' }}>
                  <TableRow>
                    <StyledTableCell sx={{ width: '80%' }}>Address</StyledTableCell>
                    <StyledTableCell align="right">Notes</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: 0 }}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {AddressService.display(values.deliveryAddress)}
                    </TableCell>
                    <TableCell align="right">
                      {values.deliveryAddress.notes ? (
                        <Icon name={'notes'} size={6} className="cursor-pointer" onClick={() => notesPopUpRef.current?.open()} />
                      ) : (
                        '---'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Icon
                        name={'edit'}
                        size={5}
                        className="cursor-pointer"
                        onClick={() => setSelectedAddress(values.deliveryAddress)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Card>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: '96%',
            mr: 2
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ my: 2 }} width={'100%'}>
          <Button
            data-test="accountCreateButton"
            variant="contained"
            aria-label="new-subscription-create"
            disabled={!dirty || !isValid || isSubmitting || !isValidEmail(values.email) || deliveryAddressChange}
            onClick={() => onSubmit(values)}
            sx={{
              width: '262px',
              height: '51px',
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              backgroundColor: caloTheme.palette.primary500,
              borderColor: caloTheme.palette.primary500,
              color: 'white',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary600
              }
            }}
          >
            Create
          </Button>
        </Stack>
      </Box>

      <Popup fullWidth ref={addressDialogRef} onClose={() => setSelectedAddress(null)}>
        <AddressPickerMUI
          time={values.deliveryTime}
          country={values.country}
          onPick={handleNewAddress}
          label="Create New Address"
          viewMode={AddressViewMode.map}
          onCancel={() => setSelectedAddress(null)}
        />
      </Popup>
      <Popup title="Delivery Note" ref={notesPopUpRef} onClose={() => notesPopUpRef.current?.close()}>
        <Stack display={'flex'} flexDirection={'row'}>
          <Typography
            display={'flex'}
            flexDirection={'row'}
            sx={{
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              color: caloTheme.palette.neutral900
            }}
          >
            {values.deliveryAddress.notes}
          </Typography>
        </Stack>
      </Popup>
    </>
  );
};
export default AccountForm;
