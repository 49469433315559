import { CodeChannelCategory, WalletFilters } from '@calo/dashboard-types';
import { Currency, Kitchen, PaymentMethod, UserAction, WalletReason } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { toggleUISettings } from 'actions';
import { Input, Select, Select2, SideDrawer } from 'components';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { getCountryFromCurrency, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface WalletFiltersWithKitchen extends WalletFilters {
  kitchen: Kitchen;
}
interface SettingsProps {
  onFilter: (values: WalletFiltersWithKitchen) => void;
  filters: WalletFiltersWithKitchen;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();

  const dispatch = useDispatch();
  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Input label="Id" value={filters.userId} onChange={(e) => onFilter({ ...filters, userId: e.target.value })} debounce />
        <Select2
          label="Currency"
          value={filters.currency}
          onChange={(e: any) => {
            const newCurrency = e.target.value as Currency;
            onFilter({
              ...filters,
              currency: newCurrency,
              kitchen: '' as Kitchen
            });
          }}
          options={Object.values(Currency).map((c) => ({
            value: c,
            label: Currency[c]
          }))}
        />
        <Select2
          label="Payment Method"
          value={filters.paymentMethod}
          onChange={(e) => onFilter({ ...filters, paymentMethod: e.target.value as PaymentMethod })}
          options={[
            {
              value: '',
              label: 'All'
            },
            ...Object.values(PaymentMethod).map((type) => ({
              value: type,
              label: type
            }))
          ]}
        />
        <Select2
          label="Transaction Type"
          value={filters.type}
          onChange={(e) => onFilter({ ...filters, type: e.target.value as any })}
          options={[
            {
              value: '',
              label: 'All'
            },
            ...[...Object.values(UserAction), 'ADDED_BY_CALO'].map((type) => ({
              value: type,
              label: type
            }))
          ]}
        />
        <Select
          label="Reason"
          value={filters.reason}
          options={[
            { label: 'All', value: undefined },
            ...[Object.values(WalletReason), Object.values(CodeChannelCategory)].flat().map((reason) => ({
              label: reason,
              value: reason
            }))
          ]}
          onChange={(data: any) => onFilter({ ...filters, reason: data.value as WalletReason })}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={[
            ...(filters.currency === Currency.SAR ? [] : [{ label: 'Any', value: '' }]),
            ...getKitchenOptions(userKitchens, getCountryFromCurrency(filters.currency))
          ]}
        />
        <div className="field">
          <label className="label">Date</label>
          <div className="control is-clearfix">
            <DateRangePicker
              onChange={(dates: any) => {
                onFilter({
                  ...filters,
                  date: dates
                    ? {
                        gte: dates && dates[0] && format('yyyy-MM-dd')(dates[0]),
                        lte: dates && dates[1] && format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                });
              }}
              value={[
                filters.date?.gte ? parseISO(filters.date.gte) : undefined,
                filters.date?.lte ? parseISO(filters.date.lte) : undefined
              ]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
