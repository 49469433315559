import { Permission, UpdateUserReq, User, UserGroup } from '@calo/dashboard-types';
import { deleteUser, getList, getRecord } from 'actions';
import { updateUser } from 'actions/users';
import { Button, ConfirmationModal, ModalRef } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import UserForm from './UserForm';

const ExactUser = () => {
  const { id } = useParams<{ id: string }>();
  const roles = useUserRoles();
  const confirmModalRef = useRef<ModalRef>();
  const { data } = useQuery(['users', id], getRecord, {
    suspense: true
  });
  const user = data as User;

  const { data: per } = useQuery<any, Error, { data: UserGroup[] }>('user-permissions', getList, {
    suspense: true
  });
  const permissionsAndRoles = per?.data as UserGroup[];

  const { mutateAsync: updateMutation } = useMutation(updateUser);
  const { mutateAsync: deleteMutation } = useMutation(deleteUser);

  const handleUpdateUser = async (values: UpdateUserReq) => {
    const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
    await updateMutation({ id, ...values, phoneNumber: phoneNumber?.number as string });
  };

  const handleDelete = async (userId: string) => {
    await deleteMutation(userId, {
      onSuccess: () => {
        queryClient.removeQueries(['users', id]);
        history.push(Routes.usersList);
      }
    });
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.usersList}>Users</Link>
                </li>
                <li>{user!.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="card">
        <header className="card-header bg-black">
          <p className="card-header-title text-white">
            User Info
            {roles.includes(Permission.DELETE_USER) && (
              <Button icon="fas fa-trash" onClick={() => confirmModalRef.current?.open()} className="ml-4 w-4 h-auto" danger />
            )}
          </p>
        </header>
        <div className="card-content overflow-visible">
          <UserForm user={user!} permissionsAndRoles={permissionsAndRoles} onSubmit={handleUpdateUser} />
        </div>
      </div>
      <ConfirmationModal ref={confirmModalRef} values={user} action={(user) => handleDelete(user.id)}>
        <div className="mt-4 mb-4 ml-4">
          <span className="flex">
            <label className="label"> Deleting user: </label>
            <p className="ml-2">{user.name}</p>
          </span>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default ExactUser;
