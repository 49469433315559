import { Brand, RetailBranch } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { simpleMutation } from 'actions/mutation';
import { updateRetailOrderStatus } from 'actions/retail';
import { format } from 'date-fns';
import { Routes } from 'lib/enums';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import notificationSound from '../../../../assets/audio/notification-sound.mp3';
import { PickUpStatus, RetailFilter } from '../../types';

const audio = new Audio(notificationSound);
audio.loop = true;

interface UseRetailOrderProps {
  history: {
    push: (path: string | { pathname: string; search: string }) => void;
  };
  location: any;
}

export const useRetailOrder = ({ history, location }: UseRetailOrderProps) => {
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState<RetailFilter>({
    brand: Brand.CALO,
    branch: RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || '{}')
  });

  const [showNotificationCard, setShowNotificationCard] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [soundTest, setSoundTest] = useState(false);
  const [needReEnableAudio, setNeedReEnableAudio] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loadingItemIds, setLoadingItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const path = generatePath(Routes.retailOrderList, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }, [filters.brand, filters.branch, history, location.pathname]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
    setSoundTest(true);
    setNeedReEnableAudio(false);

    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error('Error playing sound:', error);
      });
  };

  const startPlayingSound = () => {
    if (!isPlaying) {
      audio
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing sound:', error);
          setShowNotificationCard(true);
        });
    }
  };

  const stopPlayingSound = () => {
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleServiceWorkerMessage = (event: any) => {
    if (event.data.action === 'new-retail-order') {
      startPlayingSound();
      setShowNotificationCard(true);
      refetch();
    }
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script1.defer = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalInitialized = window.OneSignalInitialized || false;
      OneSignalDeferred.push(async function(OneSignal) {
        const filters = ${JSON.stringify(filters)};
        if (!window.OneSignalInitialized) {
          await OneSignal.init({
            appId: '${process.env.REACT_APP_ONE_SIGNAL_APP_ID}'
          });
          window.OneSignalInitialized = true;
        }
        OneSignal.User.addTags({ ['retail-staff-' + filters.branch]: 'true' });
      });
    `;
    document.body.appendChild(script2);

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, [filters.branch, handleServiceWorkerMessage]);

  const [paginationToken, setPaginationToken] = useState<string | null>(null);

  const { data, isLoading, refetch, isFetching } = useQuery<any, Error, { pickUps: any[]; token?: string }>(
    ['/retail/orders', { branch: filters.branch, token: paginationToken }],
    getListWithParams,
    {
      suspense: false,
      initialData: { pickUps: [], token: null },
      onSuccess: (newData) => {
        setOrderList((prevOrderList) => [...prevOrderList, ...newData.pickUps]);
        // setPaginationToken(newData.token ?? null);

        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (data?.token && data?.token !== paginationToken) {
      setPaginationToken(data?.token);
    }
  }, [data?.token]);

  const [orderList, setOrderList] = useState<any[]>([]);

  const handleChangeStatus = async (pickUpId: string, status: PickUpStatus) => {
    if (orderList && orderList.length > 0 && !loadingItemIds.includes(pickUpId)) {
      const oldList = orderList?.filter((order) => order.pickUp.id !== pickUpId) ?? [];
      const currentItem = orderList?.find((order) => order.pickUp.id === pickUpId);
      try {
        setLoadingItemIds((prev) => [...prev, pickUpId]);
        const newList = [...oldList, { ...currentItem, pickUp: { ...currentItem.pickUp, status } }].sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(newList);
        await updateRetailOrderStatus(pickUpId, status);
      } catch (error) {
        console.log(error);
        const newList = [...oldList, currentItem].sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(newList);
      } finally {
        setLoadingItemIds((prev) => prev.filter((id) => id !== pickUpId));
      }
    }
  };

  const handleSoundTestConfirmation = () => {
    stopPlayingSound();
    setSoundTest(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const searchOrder = useMemo(
    () => (order: any, query: string) => {
      if (!query) return true;

      const searchTerm = query.toLowerCase();

      // Search in order ID
      if (order.orderId.toLowerCase().includes(searchTerm)) return true;

      // Search in customer details
      if (order.user.name.toLowerCase().includes(searchTerm)) return true;
      if (order.user.phoneNumber.includes(searchTerm)) return true;

      // Search in items
      if (
        order.items.some(
          (item: any) =>
            item.name.en.toLowerCase().includes(searchTerm) || (item.notes && item.notes.toLowerCase().includes(searchTerm))
        )
      )
        return true;

      // Search in order notes
      if (order.notes && order.notes.toLowerCase().includes(searchTerm)) return true;

      return false;
    },
    []
  );

  const handleSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  const filterOrdersByStatus = useCallback(
    (status: PickUpStatus) => {
      return (
        orderList?.filter(
          (order) =>
            order.pickUp.status === status &&
            (!selectedDate || format(new Date(order.createdAt), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')) &&
            searchOrder(order, searchQuery)
        ) || []
      );
    },
    [orderList, selectedDate, searchOrder, searchQuery]
  );

  const handleClearFilters = useCallback(() => {
    const defaultFilters: RetailFilter = {
      country: filters.country,
      brand: filters.brand,
      branch: RetailBranch.SEEF
    };
    setFilters(defaultFilters);
    setSelectedDate(null);
  }, [filters.country, filters.brand]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return {
    filters,
    setFilters,
    showNotificationCard,
    setShowNotificationCard,
    isUserInteracted,
    soundTest,
    needReEnableAudio,
    selectedTab,
    selectedDate,
    setSelectedDate,
    orderList,
    isLoading,
    isFetching,
    loadingItemIds,
    handleUserInteraction,
    stopPlayingSound,
    handleChangeStatus,
    handleSoundTestConfirmation,
    handleTabChange,
    filterOrdersByStatus,
    handleClearFilters,
    searchQuery,
    handleSearchChange
  };
};
