import { useState } from 'react';

import { format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';

import { alpha, Box, styled, TableCell, tableCellClasses, TableRow, Tooltip, Typography } from '@mui/material';

import { AccessTime, CheckCircle, HorizontalRule, Visibility, VisibilityOff } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';

import {
  PaymentMethod,
  PaymentProviderType,
  PaymentTransaction,
  TransactionHistorySource,
  TransactionHistoryStatus,
  TransactionHistoryType
} from '@calo/types';

import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { Routes } from 'lib/enums';
import PricingService from 'services/PricingService';

interface TransactionCardRowProps {
  row: PaymentTransaction;
}

const TransactionHistorySourceMap = {
  [TransactionHistorySource.PAYMENT]: 'Payment',
  [TransactionHistorySource.REFUND]: 'Refund',
  [TransactionHistorySource.MANUAL_ADDITION]: 'Addition',
  [TransactionHistorySource.MANUAL_DEDUCTION]: 'Deduction'
};

const PaymentMethodIcons = {
  [PaymentMethod.applePay]: 'https://cdn.calo.app/paymentMethods/applePay/applePay.svg',
  [PaymentMethod.cc]: 'https://cdn.calo.app/paymentMethods/cc/credit-card.svg',
  [PaymentMethod.mada]: 'https://cdn.calo.app/paymentMethods/mada/mada.svg',
  [PaymentMethod.benefit]: 'https://cdn.calo.app/paymentMethods/benefit/benefit.svg',
  [PaymentMethod.knet]: 'https://cdn.calo.app/paymentMethods/knet/knet.svg',
  [PaymentMethod.naps]: 'https://cdn.calo.app/paymentMethods/naps/naps.png',
  [PaymentMethod.bnpl]: 'https://cdn.calo.app/paymentMethods/cc/credit-card.svg'
};

const PaymentProviderIcons = {
  [PaymentProviderType.CHECKOUT]: 'https://cdn.calo.app/paymentProviders/checkout/icon.png',
  [PaymentProviderType.TAP]: 'https://cdn.calo.app/paymentProviders/tap/icon.png',
  [PaymentProviderType.TABBY]: 'https://cdn.calo.app/paymentProviders/tabby/icon.svg',
  [PaymentProviderType.TAMARA]: 'https://cdn.calo.app/paymentProviders/tamara/icon.png',
  ['calo']: 'https://cdn.calo.app/paymentProviders/calo/icon.png'
};

const TransactionCardRow = ({ row }: TransactionCardRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  const amount = row.vat ? PricingService.roundAmount(row.amount - row.vat, row.currency) : row.amount;
  const oldBalance = PricingService.roundAmount(
    row.type === TransactionHistoryType.DEBIT ? row.balance + amount : row.balance - amount,
    row.currency
  );
  const updatePaymentBalance = row.updatePaymentBalance === undefined ? !row.skipSyncBalance : row.updatePaymentBalance;

  return (
    <>
      <TableRow key={row.id}>
        <StyledTableCell>{format('PPpp')(parseISO(row.createdAt))}</StyledTableCell>
        <StyledTableCell>{TransactionHistorySourceMap[row.source]}</StyledTableCell>
        <StyledTableCell
          sx={{
            textTransform: 'capitalize'
          }}
        >
          {startCase(row.action)}
        </StyledTableCell>
        <StyledTableCell>
          {row.notes ? (
            <Typography
              onClick={() => setIsOpen(!isOpen)}
              sx={{
                ml: 0,
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              {isOpen ? <Visibility /> : <VisibilityOff />}
            </Typography>
          ) : (
            <HorizontalRule />
          )}
        </StyledTableCell>

        <StyledTableCell>
          <img
            alt={row.paymentProvider}
            className="object-cover rounded-s-md"
            width="100%"
            title={row.paymentMethod}
            src={PaymentProviderIcons[row.paymentProvider || 'calo']}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            sx={{
              ml: 0,
              fontWeight: 600,
              cursor: 'pointer'
            }}
            title={row.status}
          >
            {row.status === TransactionHistoryStatus.COMPLETED ? <CheckCircle color="success" /> : <AccessTime color="warning" />}
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          style={{
            backgroundColor: updatePaymentBalance
              ? row.type === TransactionHistoryType.DEBIT
                ? alpha(caloTheme.palette.secondaryRed100, 0.2)
                : alpha(caloTheme.palette.primary100, 0.7)
              : alpha(caloTheme.palette.black, 0.1)
          }}
        >
          {`${row.type === TransactionHistoryType.DEBIT ? `- ${amount}` : `+ ${amount}`}`}
        </StyledTableCell>
        <StyledTableCell>{row.vat || 0}</StyledTableCell>

        <StyledTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'inline-block', mr: 1 }}>{row.balance}</Box>
            <Tooltip
              placement="top-end"
              title={`${updatePaymentBalance ? `(Previous Balance ${row.type === TransactionHistoryType.DEBIT ? '-' : '+'} Transaction Amount) = ${oldBalance} ${row.type === TransactionHistoryType.DEBIT ? '-' : '+'} ${amount}` : "This transaction doesn't affect the user's balance"}`}
            >
              <InfoIcon color={updatePaymentBalance ? 'info' : 'warning'} fontSize="small" />
            </Tooltip>
          </Box>
        </StyledTableCell>

        <StyledTableCell>{row.actor}</StyledTableCell>

        <StyledTableCell>
          {row.source === TransactionHistorySource.PAYMENT ? (
            <img
              alt={row.paymentMethod}
              className="object-cover rounded-s-md"
              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
              width="80%"
              title={row.paymentMethod}
              src={PaymentMethodIcons[row.paymentMethod]}
            />
          ) : (
            <HorizontalRule />
          )}
        </StyledTableCell>

        {row.metadata?.deliveryId ? (
          <StyledTableCell>
            <Typography
              onClick={() => window.open(Routes.delivery.replace(':id', row.metadata?.deliveryId as string), '_blank')}
              sx={{
                ml: 0,
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              <Icon name={'greenTruck'} size={8} color="green" />
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell>
            <HorizontalRule />
          </StyledTableCell>
        )}
      </TableRow>
      {isOpen && (
        <TableRow>
          <StyledTableCell
            colSpan={10}
            sx={{
              familyFont: caloTheme.typography.fontFamily,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              whiteSpace: 'pre-line',
              color: caloTheme.palette.neutral900,
              backgroundColor: caloTheme.palette.secondaryBlue50
            }}
          >
            {row.notes}
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};
export default TransactionCardRow;
