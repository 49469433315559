import { Map } from '@calo/dashboard-types';
import { LatLng } from '@calo/driver-types';
import { Country, Kitchen } from '@calo/types';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DrawingManager, InfoWindow } from '@react-google-maps/api';
import { createMap, getRecord, updateMap } from 'actions';
import { DeliveryTime } from 'lib/enums';
import { useUserKitchens } from 'lib/hooks';
import { uniq } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import AreaForm from '../AreaForm';
import PolygonContext from '../PolygonContext';

interface PolygonManagerProps {
  day: string;
  time: DeliveryTime;
  polygonState: PolygonState;
  isEditing: boolean;
  isAreaView: boolean;
  setIsEditing: (e: boolean) => void;
  closePopUp: () => void;
  country: Country;
  deliveryPlannerMap?: boolean;
  kitchenZones?: boolean;
  kitchen: Kitchen;
}

const polygonOptions: google.maps.PolygonOptions = {
  clickable: false,
  editable: false,
  fillOpacity: 1,
  strokeWeight: 0.3
};

const polygonOptionsEdit: google.maps.PolygonOptions = {
  fillOpacity: 1,
  editable: false,
  clickable: true,
  strokeWeight: 1
};

const getPolygonCenter = (polygon: google.maps.Polygon) => {
  const bounds = new google.maps.LatLngBounds();
  for (const path of polygon.getPath().getArray()) {
    bounds.extend(path);
  }
  const centerLng = bounds.getCenter().lng();
  const lat = bounds.getNorthEast().lat();
  return { lat, lng: centerLng };
};

const PolygonManager = ({
  kitchenZones,
  deliveryPlannerMap,
  day,
  time,
  polygonState,
  isEditing,
  setIsEditing,
  closePopUp,
  country,
  isAreaView,
  kitchen
}: PolygonManagerProps) => {
  const userKitchens = useUserKitchens();
  const dispatch = useContext(PolygonContext);
  const { mutateAsync: updateMutation } = useMutation(updateMap);
  const { mutateAsync: createMutation } = useMutation(createMap);
  const [mapData, setMapData] = useState<Map | undefined>();

  const { data, isLoading, refetch } = useQuery([`${country}/map/${day}/kitchen/${kitchen}`], getRecord, {
    suspense: false,
    retry: false,
    onError: console.error
  });
  const mapsData = data as Map[];

  useEffect(() => {
    if (!mapsData || (mapsData && mapsData.length === 0)) {
      setMapData({
        country,
        kitchen,
        id: country
      });
    }
    mapsData && setMapData(mapsData[0] as Map);
  }, [data]);
  const [uuIDCreate, setUuIDCreate] = useState<string>('');
  const { polygons, selectedPolygon } = polygonState;
  const deliveryAreas = useMemo(() => (mapData && mapData[time]) || [], [mapData, time, country, kitchen]);
  const deliveryAreasWaitingListMorning = useMemo(
    () => (mapData && mapData[DeliveryTime.morning]) || [],
    [mapData, country, kitchen]
  );
  const deliveryAreasWaitingListEvening = useMemo(
    () => (mapData && mapData[DeliveryTime.evening]) || [],
    [mapData, country, kitchen]
  );
  const deliveryAreasWaitingList = uniq([...deliveryAreasWaitingListMorning, ...deliveryAreasWaitingListEvening]);

  useEffect(() => {
    for (const row of polygons) {
      row.polygon.setEditable(isEditing);
      const options: google.maps.PolygonOptions = {
        ...(isEditing ? polygonOptionsEdit : polygonOptions),
        fillColor: kitchenZones ? 'black' : row.color || 'rgba(0, 0, 0, 0.5)',
        fillOpacity: kitchenZones ? 0.1 : isEditing || isAreaView ? 1 : 0,
        strokeColor: kitchenZones
          ? 'black'
          : isEditing || isAreaView
            ? (row.color || 'rgba(0, 0, 0, 0.5)').replace('0.5', '1')
            : 'black'
      };
      row.polygon.setOptions(options);
    }
  }, [isEditing, polygons, country, isAreaView, kitchen]);

  useEffect(() => {
    if (selectedPolygon == null) {
      for (const p of polygons) {
        if (p.polygon.getEditable()) {
          p.polygon.setOptions({ editable: false });
        }
      }
    } else {
      if (selectedPolygon.polygon.getEditable()) {
        selectedPolygon.polygon.setOptions({ editable: false });
        closePopUp();
      } else {
        for (const p of polygons) {
          if (p.polygon.getEditable()) {
            p.polygon.setOptions({ editable: false });
          }
        }
        selectedPolygon.polygon.setOptions({ editable: true });
      }
    }
  }, [selectedPolygon]);

  const onPolygonDeleted = (row: PolygonRowState) => {
    dispatch!({
      type: 'delete',
      payload: row
    });
  };

  const onDriverChange = (row: PolygonRowState) => {
    dispatch!({
      type: 'update',
      payload: row
    });
    closePopUp();
  };

  const saveDefaultMap = async () => {
    const deliveryAreas = {
      [time]: polygons
        .map((row) => ({
          bounds: row.polygon
            .getPath()
            .getArray()
            .map((row) => row.toJSON()) as LatLng[],
          drivers: row.drivers,
          color: row.color,
          isSoftLaunchEnabled: row.isSoftLaunchEnabled
        }))
        .filter((p) => p.bounds.length > 3)
    };

    if (mapData) {
      await updateMutation({
        id: `default`,
        kitchen,
        country,
        ...deliveryAreas
      });
    } else {
      await createMutation({
        day: 'default',
        country,
        kitchen,
        ...deliveryAreas
      });
    }

    dispatch!({
      type: 'select',
      payload: null
    });
    refetch();
    setIsEditing(false);
  };

  const handlePolygonClick = (polygon: google.maps.Polygon) => {
    dispatch!({
      type: 'select',
      payload: { polygon }
    });
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    if (polygon.getPath().getArray().length < 4) {
      toast(`Polygon needs to have at least 4 points, please edit this one or it will not be included on save`, {
        type: 'error',
        autoClose: 5000
      });
    }
    dispatch!({
      type: 'select',
      payload: null
    });
    const newPoly = new google.maps.Polygon({
      paths: polygon.getPaths(),
      ...polygonOptionsEdit
    });
    newPoly.setMap(polygon.getMap());
    newPoly.addListener('click', () => handlePolygonClick(newPoly));
    dispatch!({
      type: 'add',
      payload: { polygon: newPoly, isSoftLaunchEnabled: true, drivers: [] }
    });
    handlePolygonClick(newPoly);
    polygon.setMap(null);
  };

  const onLoaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    if (deliveryPlannerMap) {
      dispatch!({
        type: 'set',
        payload: deliveryAreasWaitingList.map((area) => {
          const polygon = new google.maps.Polygon({
            paths: area.bounds,
            ...polygonOptions
          });
          polygon.setMap(dm.getMap());
          polygon.addListener('click', () =>
            dispatch!({
              type: 'select',
              payload: { polygon, drivers: area.drivers, color: area.color, isSoftLaunchEnabled: area.isSoftLaunchEnabled }
            })
          );
          return { polygon, color: area.color, isSoftLaunchEnabled: area.isSoftLaunchEnabled };
        })
      });
    } else {
      dispatch!({
        type: 'set',
        payload: deliveryAreas?.map((area) => {
          const polygon = new google.maps.Polygon({
            paths: area.bounds,
            ...polygonOptions
          });
          polygon.setMap(dm.getMap());
          polygon.addListener('click', () =>
            dispatch!({
              type: 'select',
              payload: { polygon, drivers: area.drivers, color: area.color, isSoftLaunchEnabled: area.isSoftLaunchEnabled }
            })
          );
          return { polygon, drivers: area.drivers, color: area.color, isSoftLaunchEnabled: area.isSoftLaunchEnabled };
        })
      });
    }
  };
  const onUnloaded = (dm: google.maps.drawing.DrawingManager) => {
    dm.setDrawingMode(null);
    dispatch!({
      type: 'reset'
    });
  };

  const handleEditPress = () => {
    if (isLoading) {
      toast(`Please wait for map to load`, { type: 'error', autoClose: 2000 });
    } else if (userKitchens && !userKitchens.includes(kitchen)) {
      toast(`You don't have permission to edit map for this kitchen`, { type: 'error', autoClose: 2000 });
    } else {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    setUuIDCreate(uuid());
  }, [kitchen, country]);

  return (
    <>
      <Box
        key={`${uuIDCreate}-${mapData?.id}`}
        sx={{ position: 'absolute', top: 60, right: 8, display: 'flex', backgroundColor: 'white', p: 1 }}
      >
        {kitchenZones ? (
          <></>
        ) : isEditing && !deliveryPlannerMap ? (
          <Stack flexDirection={'row'} style={{ maxHeight: '21px' }}>
            <Tooltip title="Save as default" placement="left" style={{ padding: 2 }}>
              <IconButton onClick={saveDefaultMap}>
                <Icon icon="ic:baseline-save" width="21px" height="21px" />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : isEditing && deliveryPlannerMap ? (
          <Tooltip title="Exit edit mode" placement="left">
            <IconButton onClick={() => setIsEditing(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Edit" style={{ height: '21px', width: '32px' }}>
            <IconButton onClick={handleEditPress}>
              <Icon icon="fluent:edit-32-regular" width="16px" height="24px" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <DrawingManager
        key={`${uuIDCreate}-${mapData?.id}-${kitchen}-${country}`}
        onLoad={onLoaded}
        onUnmount={onUnloaded}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: isEditing,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: polygonOptionsEdit
        }}
      />
      {selectedPolygon && !deliveryPlannerMap && (
        <InfoWindow
          key={`${getPolygonCenter(selectedPolygon.polygon).lat}-${getPolygonCenter(selectedPolygon.polygon).lng}`}
          position={getPolygonCenter(selectedPolygon.polygon)}
          onCloseClick={closePopUp}
          zIndex={0}
        >
          <div style={{ width: '450px' }}>
            <AreaForm
              closePopUp={closePopUp}
              onChange={onDriverChange}
              onDelete={onPolygonDeleted}
              polygonRow={selectedPolygon}
            />
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default PolygonManager;
